import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import classes from "./CountdownTimer.module.css"

const CountdownTimer = ({ targetDate }) => {
    const calculateTimeLeft = () => {
        const difference = +new Date(targetDate) - +new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                // days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24) + (Math.floor(difference / (1000 * 60 * 60 * 24)) * 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
    const [timeIsUp, setTimeIsUp] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            const newTimeLeft = calculateTimeLeft();
            setTimeLeft(newTimeLeft);
            if (Object.values(newTimeLeft).every((value) => value === 0)) {
                // Время закончилось
                setTimeIsUp(true);
            } else {
                setTimeIsUp(false);
            }
        }, 1000);

        return () => clearTimeout(timer);
    }, [timeLeft]);

    // console.log(('3'.lenght))
    // console.log((timeLeft.minutes.toString()?.lenght))

    return (
        <div >
            {timeIsUp ? (
                <Typography variant="h5" className={classes.style__for__big__text}>Руки на стол, программисты,<br /> время истекло!</Typography>
            ) : (
                <>
                    {/* {timeLeft.days > 0 && (
                        <Typography variant="h5">{timeLeft.days} дней</Typography>
                    )} */}

                    {timeLeft.hours < 1 ? <div className={classes.main__block}>
                        <div className={classes.mini__data__style} style={{ top: "4vw", position: "relative" }}>
                            {timeLeft.hours < 10 ? <Typography variant="h5" className={classes.text__for__mini}>{"0" + timeLeft.hours.toString()}</Typography> : <Typography variant="h5" className={classes.text__for__mini}>{timeLeft.hours}</Typography>}
                            <Typography variant="h5" className={classes.text__for__mini}>:</Typography>
                            {timeLeft.minutes < 10 ? <Typography variant="h5" className={classes.text__for__mini}>{"0" + timeLeft.minutes.toString()}</Typography> : <Typography variant="h5" className={classes.text__for__mini}>{timeLeft.minutes}</Typography>}
                        </div>
                        {timeLeft.seconds < 10 ? <Typography variant="h5" className={classes.style__for__big__data}>{"0" + timeLeft.seconds.toString()}</Typography> : <Typography className={classes.style__for__big__data} variant="h5">{timeLeft.seconds}</Typography>}
                    </div>

                        :

                        <div className={classes.main__block}>
                            {timeLeft.hours < 10 ? <Typography variant="h5" className={classes.style__for__big__data}>{"0" + timeLeft.hours.toString()}</Typography> : <Typography variant="h5" className={classes.style__for__big__data}>{timeLeft.hours}</Typography>}
                            <div className={classes.mini__data__style} style={{ bottom: "4vw", position: "relative" }}>
                                {timeLeft.minutes < 10 ? <Typography variant="h5" className={classes.text__for__mini}>{"0" + timeLeft.minutes.toString()}</Typography> : <Typography variant="h5" className={classes.text__for__mini}>{timeLeft.minutes}</Typography>}
                                <Typography variant="h5" className={classes.text__for__mini}>:</Typography>
                                {timeLeft.seconds < 10 ? <Typography variant="h5" className={classes.text__for__mini}>{"0" + timeLeft.seconds.toString()}</Typography> : <Typography className={classes.text__for__mini} variant="h5">{timeLeft.seconds}</Typography>}
                            </div>
                        </div>
                    }




                </>
            )
            }
        </div >
    );
};

export default CountdownTimer;
