import { useFetching } from "components/hooks/useFetching";
import CreateAdmin from "components/UI/create/createAdmin/CreateAdmin";
import { useEffect, useState } from "react";
import OccasionService from "services/OccasionService";
import 'styles/css/admin.css'
import Loader from "components/UI/loader/Loader";
import Strings from "values/Strings";
import { NotificationManager } from "react-notifications";
import Notifications from "components/UI/popupNotifications/Notifications";
import PartService from "services/PartService";
import ParticipantService from "services/ParticipantService";
import NewsService from "services/NewsService";
import QRCodeService from "services/QRCodeService";


function AdministrationPage() {
    const [parts, setParts] = useState([])
    const [partList, setPartList] = useState([])
    const [participant, setParticipant] = useState([])
    const [participantList, setParticipantList] = useState([])
    const [occasions, setOccasions] = useState([])
    const [occasionList, setOccasionList] = useState([])
    const [occasionTaskList, setOccasionTaskList] = useState([])
    const [news, setNews] = useState([])
    const [newList, setNewList] = useState([])

    const notificationError = (error) => {
        if (error.length > 0) {
            NotificationManager.error(JSON.stringify(error), Strings.notificationsErrorTitle, 5000);
        }
    }

    const notificationWarning = (error) => {
        if (error.length > 0) {
            NotificationManager.warning(error, Strings.notificationsErrorTitle, 5000);
        }
    }

    const [fetchingOccasion, loadingFetchingOccasion, errorFetchingOccasion] = useFetching(async () => {
        try {
            const res = await OccasionService.getOccasions()
            setOccasions(res)
            setOccasionTaskList(res.map(item => OccasionService.getTextInputOccasionTask(item)))
            setOccasionList(res.map(item => OccasionService.getTextInputOccasion(item)))
        } catch (error) {
            notificationError(error)
        }
    })

    const [fetchingParticipant, loadingFetchingParticipant, errorFetchingParticipant] = useFetching(async () => {
        try {
            const res = await ParticipantService.getParticipants()
            setParticipant(res)
            setParticipantList(res.map(item => ParticipantService.getTextInputParticipant(item)))
        } catch (error) {
            notificationError(error)
        }
    })

    const [fetchingPart, loadingFetchingPart, errorFetchingPart] = useFetching(async () => {
        try {
            const res = await PartService.getParts()
            setParts(res)
            setPartList(res.map(item => item.name))
        } catch (error) {
            notificationError(error)
        }
    })

    const [fetchingNews, loadingFetchingNews, errorFetchingNews] = useFetching(async () => {
        try {
            const res = await NewsService.getNews()
            setNews(res)
            setNewList(res.map(item => NewsService.getTextInputNews(item)))
        } catch (error) {
            notificationError(error)
        }
    })

    const [fetchingQRcode, loadingFetchingQRcode, errorFetchingQRcode] = useFetching(async (data) => {
        try {
            const res = await QRCodeService.generateQRCode(data)
            const urlGen = await OccasionService.updateOccasionQrCode(data.id, res?.url)
            if (urlGen) {
                navigator.clipboard.writeText(res?.url)
                await NotificationManager.success(Strings.notificationsDataUpdate, Strings.notificationsSuccessTitle, 2500);
            }
        } catch (error) {
            notificationError(error)
        }
    })

    const changeItemInOccasionById = (data) => {
        let newOccasion = occasions
        const id = newOccasion.findIndex(item => data.id === item.id)
        newOccasion[id] = data
        setOccasions(newOccasion)
        return newOccasion
    }

    const [updateOccasion, loadingUpdateOccasion, errorUpdateOccasion] = useFetching(async (occasionId, statusId) => {
        try {
            const res = await OccasionService.updateOccasion({ occasionId, statusId })
            const newOccasion = await changeItemInOccasionById(res)
            setOccasionList(newOccasion.map(item => OccasionService.getTextInputOccasion(item)))
            await NotificationManager.success(Strings.notificationsDataUpdate, Strings.notificationsSuccessTitle, 2500);
        } catch (error) {
            notificationError(error)
        }
    })
    const [updateOccasionTaskStatus, loadingUpdateOccasionTask, errorUpdateOccasionTask] = useFetching(async (data, index) => {
        try {
            occasions[index] = data
            setOccasionTaskList(occasions.map(item => OccasionService.getTextInputOccasionTask(item)))
            await OccasionService.updateOccasionTaskStatus(occasions[index])
            await NotificationManager.success(Strings.notificationsDataUpdate, Strings.notificationsSuccessTitle, 2500);
        } catch (error) {
            notificationError(error)
        }
    })
    const [updateParticipant, loadingUpdateParticipant, errorUpdateParticipant] = useFetching(async (data, index, partId) => {
        try {
            participant[index] = data
            setParticipantList(participant.map(item => ParticipantService.getTextInputParticipant(item)))
            await ParticipantService.updateParticipantPart(participant[index].id, partId)
            await NotificationManager.success(Strings.notificationsDataUpdate, Strings.notificationsSuccessTitle, 2500);
        } catch (error) {
            notificationError(error)
        }
    })
    const [updateNews, loadingUpdateNews, errorUpdateNews] = useFetching(async (data, index) => {
        try {
            news[index] = data
            setNewList(news.map(item => NewsService.getTextInputNews(item)))
            NewsService.updateNews(news[index]).then(() => NotificationManager.success(Strings.notificationsDataUpdate, Strings.notificationsSuccessTitle, 2500))
        } catch (error) {
            notificationError(error)
        }
    })


    useEffect(() => {
        fetchingOccasion()
        fetchingPart()
        fetchingParticipant()
        fetchingNews()
    }, [])

    return (
        <div className="admin-upload-page-container" >
            <CreateAdmin
                occasions={occasions} occasionList={occasionList}
                parts={parts} partList={partList} updateOccasionStatus={updateOccasion}
                participant={participant} participantList={participantList} updateParticipant={updateParticipant}
                updateOccasionTaskStatus={updateOccasionTaskStatus} occasionTaskList={occasionTaskList}
                news={news} newList={newList} updateNews={updateNews} fetchingQRcode={fetchingQRcode}
            />
            <Notifications />
        </div >
    );
}

export default AdministrationPage;