import { Navigate, Routes, Route } from "react-router-dom";
import { infoRoutes } from "router";
import OAuthService from "services/OAuthService";

function AppRouterInfo() {
    const auth = true
    const userInfo = OAuthService.decryptedSync('userData')


    if (!auth) {
        return <Navigate to='/main' state={{ auth: true }} />
    }


    return (<Routes>
        {
            ((userInfo?.user) || userInfo?.superUser === true) &&
            infoRoutes.map(rout => <Route path={rout.path} element={<rout.component />} key={rout.path} />)
        }
        <Route path="*" element={<Navigate to="/nofound" />} />
    </Routes>
    )
}

export default AppRouterInfo;