import { useDeviceType } from "components/hooks/useDeviceType";
import { useEffect, useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";
import './Navbar.css'

function NavbarItem({ name, title, Icon, setItemRef }) {
    const device = useDeviceType()
    const location = useLocation()
    const ref = useRef(null)


    useEffect(() => {
        if (location.pathname.slice(1) === name) {
            ref.current.className = 'nav-item active'
            setItemRef(ref)
        }
        else { ref.current.className = 'nav-item' }
    }, [location])


    return (
        <li className="nav-item" ref={ref}>
            <NavLink className="nav-link" to={`/${name}`} exact="true">
                {device === 'desktop' ? title : <Icon className={'admin-navbar-icon'} />}
            </NavLink>
        </li>
    );
}

export default NavbarItem;