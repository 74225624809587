const Gender = {
    male: {
        id: 1,
        description: 'мужской',
    },
    female: {
        id: 2,
        description: 'женский',
    },
    not_defined: {
        id: 3,
        description: 'не определ',
    }
}
export default Gender

