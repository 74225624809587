import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { changeLoaderStatus } from 'redux/slice/loaderSlice'


export const useFetching = (callback, loaderOn = true) => {
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')
    const dispatch = useDispatch()

    const fetching = async (...props) => {
        try {
            loaderOn && dispatch(changeLoaderStatus(true))
            setIsLoading(true)
            await callback(...props)
        } catch (error) {
            setError(error.message)
        } finally {
            setIsLoading(false)
            loaderOn && dispatch(changeLoaderStatus(false))
        }
    }

    return [fetching, isLoading, error]
}