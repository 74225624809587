import './notifications.css'
import React from 'react';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Strings from 'values/Strings';

const createNotification = ({
    type = 'info',
    warningTime = 2500, infoTime = 2500, successTime = 2500, errorTime = 2500,
    warningText = null, infoText = null, successText = null, errorText = null,
    warningCallback = () => { }, infoCallback = () => { }, successCallback = () => { }, errorCallback = () => { },
    warningPriority = false, infoPriority = false, successPriority = false, errorPriority = false,
}) => {
    return () => {
        switch (type) {
            case 'info':
                NotificationManager.info('infoText', Strings.notificationsInfoTitle, 2500, () => { }, false);
                break;
            case 'success':
                NotificationManager.success('successText', Strings.notificationsSuccessTitle, 2500, () => { }, false);
                break;
            case 'warning':
                NotificationManager.warning('warningText', Strings.notificationsWarningTitle, 2500, () => { }, false);
                break;
            case 'error':
                NotificationManager.error('errorText', Strings.notificationsErrorTitle, 2500, () => { }, false);
                break;
        }
    };
};


function Notifications() {
    return (
        <div className="notific__style">
            {/* <button className='btn btn-info'
                onClick={createNotification('info')}>Info
            </button>
            <hr />
            <button className='btn btn-success'
                onClick={createNotification('success')}>Success
            </button>
            <hr />
            <button className='btn btn-warning'
                onClick={createNotification('warning')}>Warning
            </button>
            <hr />
            <button className='btn btn-danger'
                onClick={createNotification('error')}>Error
            </button> */}
            <NotificationContainer />
        </div>
    )
}
export default Notifications;