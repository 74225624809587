import axios from "axios";
import env from '../../package.json'
// import $api from "http";


export default class FontService {

    // static updatePart = async (data) => {
    //     const response = await axios.put(`${env.nodeHost}/api/part`, data)
    //     return response.data
    // }

    // static changePartForAccount = async (data) => {
    //     const response = await $api.put(`${env.nodeHost}/api/part/add`, data)
    //     return response.data
    // }

    // static createPart = async (data) => {
    //     const response = await axios.post(`${env.nodeHost}/api/part`, data)
    //     return response.data
    // }

    static getFonts = async () => {
        const response = await axios.get(`${env.nodeHost}/api/fonts`)
        return response.data
    }

}