import axios from "axios";
import env from '../../package.json'
import $api from "http";
import { publicDecrypt } from "crypto-browserify";
import CommandService from "./CommandService";
const { Buffer } = require('buffer');

export default class OAuthService {
    static async refreshTokens() {
        try {
            const res = await OAuthService.refreshAuth()
            const userData = res?.userData
            localStorage.setItem('accessToken', res.accessToken)
            userData ? localStorage.setItem('userData', userData) : localStorage.removeItem("userData");
            const commandRes = await CommandService.checkCommand()
            commandRes ? localStorage.setItem('command', commandRes) : localStorage.removeItem("command");
            return true
        } catch (error) {
            return error.message
        }
    }

    static async yandexGetToken(code) {
        const response = await axios.post(`${env.nodeHost}/api/oauth/yandex`, { code }, { ...env.axiosConfig.json, withCredentials: true })
        return response.data
    }

    static async refreshAuth() {
        const response = await $api.post(`${env.nodeHost}/api/oauth/refresh`, {}, { withCredentials: true })
        return response.data
    }

    static async checkAuth() {
        const response = await $api.post(`${env.nodeHost}/api/oauth/check`, {}, { withCredentials: true })
        return response.data
    }

    static async logout() {
        const response = await axios.post(`${env.nodeHost}/api/oauth/logout`, {}, { withCredentials: true })
        return response.data
    }

    static async decrypted(key) {
        try {
            const decrypted = await publicDecrypt(env.secretKeys.publicKey, Buffer.from(localStorage.getItem(key), 'base64'))
            return JSON.parse(decrypted.toString('utf-8'))
        } catch (error) {
            localStorage.removeItem(key)
            return false
        }
    }
    static decryptedSync(key) {
        try {
            const decrypted = publicDecrypt(env.secretKeys.publicKey, Buffer.from(localStorage.getItem(key), 'base64'))
            return JSON.parse(decrypted.toString('utf-8'))
        } catch (error) {
            localStorage.removeItem(key)
            return false
        }
    }

}