import env from '../../package.json'
import $api from "http";


export default class MentorService {
    static getProfileMentor = async (id) => {
        const response = await $api.get(`${env.nodeHost}/api/mentor/profile-mentor/${id}`)
        return response.data
    }

    static updateMentor = async (data) => {
        const response = await $api.put(`${env.nodeHost}/api/mentor`, data)
        return response.data
    }
}