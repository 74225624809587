import classes from "./ContactCard.module.css"
import "@fontsource/roboto";
import { LiaTelegram } from "react-icons/lia";
import { SlSocialVkontakte } from "react-icons/sl";
import Colors from "values/Colors.js";
const ContactCard = ({ ...props }) => {
    return (
        <div className={classes.contactcard}>
            <img src={props.img} alt="" className={classes.contactcard__img} />
            <div className={classes.contactcard__main__title}>
                <span className={classes.contactcard__fullname}>{props.fullname}</span>
                <span className={classes.contactcard__post}>{props.post}</span>
                <div className={classes.contactcard__about}>{props.about}</div>
                <div className={classes.contactcard__socity}>
                    <a href={props.tg} target="blank"><LiaTelegram style={{ color: Colors.blue2 }} className={classes.contactcard__icon} /></a>
                    <a href={props.vk} target="blank"><SlSocialVkontakte style={{ color: Colors.blue2 }} className={classes.contactcard__icon} /></a>
                </div>
            </div>
        </div >
    )
}

export default ContactCard