import { createSlice } from "@reduxjs/toolkit";

export const popUpWindowSlice = createSlice({
    name: 'popUpWindow',
    initialState: {
        status: false,
    },
    reducers: {
        changePopUpWindowStatus: (state, action) => { state.status = action.payload }
    }
})

export const { changePopUpWindowStatus } = popUpWindowSlice.actions
export default popUpWindowSlice.reducer