import InfoPage from "pages/public/InfoPage";
import CasePage from "pages/public/CasePage";
import SupportPage from "pages/public/SupportPage";
import OccasionPage from "pages/public/OccasionPage";
import PersonalDataPage from "pages/private/PersonalDataPage";
import NofoundPage from 'pages/public/NofoundPage'
import CreateOccasionPage from "pages/admin/CreateOccasionPage";
import CreateNewsPage from "pages/admin/createNewsPage/CreateNewsPage";
import GradePage from "pages/private/GradePage";
import CreateAndUpdateCasePage from "pages/private/CreateAndUpdateCasePage";
import TeamRoomPage from "pages/private/TeamRoomPage";
import AdministrationPage from "pages/admin/AdministrationPage";
import WorkWithCasePage from "pages/admin/WorkWithCasePage";
import TeamGradePage from "pages/admin/TeamGradePage";
import TicketParticipationPage from "pages/private/TicketParticipationPage";
import PortfolioPage from "pages/private/PortfolioPage";
import MentorCase from "pages/private/MentorCase";
import TeamGradeFinalMark from "pages/private/TeamGradeFinalMark";
import TeamGradeCheckPoint from "pages/private/TeamGradeCheckPoint";
import CertificateGenerationPage from './../pages/admin/CertificateGenerationPage';
import AboutOccasionPage from "pages/public/AboutOccasionPage";
import InfoAboutCommandPage from "pages/private/infoAboutCommand/infoAboutCommandPage";
import PartChangePage from "pages/admin/partChangePage/PartChangePage";
import NewsPage from "pages/public/NewsPage";
import InfoTvStatPage from "pages/admin/InfoTvStatPage";
import TeamGradeMentorPage from "pages/private/TeamGradeMentorPage";
import CertificateValidatePage from "pages/public/CertificateValidatePage";
import LeaderBoardePage from "pages/public/LeaderBoardePage";
// import Rost from "pages/public/Rost";

export const navBareRoute = {
    profile: 'profile',
    tgp: 'team-grade-page',
    main: 'main',
    admin: 'admin',
}

export const adminRoutNames = {
    createOccasion: 'create-event',
    reportUpload: 'report-upload',
    createNews: 'create-news',
    teamGrade: 'team-grade',
    administration: 'administration',
    workWithCase: 'work-with-case',
    certificates: 'certificates',
    partChange: 'part-change',
}

export const infoRoutNames = {
    infoTvStat: 'tv-stat',
}

export const publickRoutNames = {
    nofound: 'nofound',
    occasion: 'occasion',
    support: 'support',
    case: 'case',
    mainInfo: `${navBareRoute.main}/info`,
    mainNews: `${navBareRoute.main}/news`,
    enter: 'enter',
    // rost: 'rost',
    aboutEvent: 'about-event',
    certificate: 'certificate-validate',
    leaderBoarde: 'leader-board',
}


export const privateRoutNames = {
    TeamGradeMentorPage: `${navBareRoute.tgp}/profile-mentor-page`,
    personalData: `${navBareRoute.profile}/personal-data`,
    portfolio: `${navBareRoute.profile}/portfolio`,
    mentorCase: `${navBareRoute.profile}/mentor-case`,
    grade: 'grade',
    createCase: 'create-case',
    teamRoom: 'team-room',
    ticketParticipation: 'ticket-participation',
    teamGradePageCheckPoint: `${navBareRoute.tgp}/check-point`,
    teamGradePageFinalMark: `${navBareRoute.tgp}/final-mark`,
    logout: 'logout',
    infoAboutCommand: `${navBareRoute.tgp}/info-about-command`,
}

export const publickRoutes = [
    { path: `/${publickRoutNames.nofound}`, component: NofoundPage, exact: true },
    { path: `/${publickRoutNames.occasion}`, component: OccasionPage, exact: true },
    { path: `/${publickRoutNames.support}`, component: SupportPage, exact: true },
    { path: `/${publickRoutNames.case}`, component: CasePage, exact: true },
    { path: `/${publickRoutNames.mainInfo}`, component: InfoPage, exact: true },
    { path: `/${publickRoutNames.mainNews}`, component: NewsPage, exact: true },
    // { path: `/${publickRoutNames.rost}`, component: Rost, exact: true },
    { path: `/${publickRoutNames.aboutEvent}`, component: AboutOccasionPage, exact: true },
    // TODO--------------------------------------------
    { path: `/${publickRoutNames.certificate}/:id`, component: CertificateValidatePage, exact: true },
    { path: `/${publickRoutNames.leaderBoarde}`, component: LeaderBoardePage, exact: true },
]

export const privateRoutes = [
    { path: `/${privateRoutNames.TeamGradeMentorPage}`, component: TeamGradeMentorPage, exact: true },
    { path: `/${privateRoutNames.personalData}`, component: PersonalDataPage, exact: true },
    { path: `/${privateRoutNames.portfolio}`, component: PortfolioPage, exact: true },
    { path: `/${privateRoutNames.mentorCase}`, component: MentorCase, exact: true },
    { path: `/${privateRoutNames.grade}`, component: GradePage, exact: true },
    { path: `/${privateRoutNames.createCase}`, component: CreateAndUpdateCasePage, exact: true },
    { path: `/${privateRoutNames.createCase}/:id`, component: CreateAndUpdateCasePage, exact: true },
    { path: `/${privateRoutNames.teamRoom}`, component: TeamRoomPage, exact: true },
    { path: `/${privateRoutNames.ticketParticipation}`, component: TicketParticipationPage, exact: true },
    { path: `/${privateRoutNames.teamGradePageCheckPoint}`, component: TeamGradeCheckPoint, exact: true },
    { path: `/${privateRoutNames.teamGradePageFinalMark}`, component: TeamGradeFinalMark, exact: true },
    { path: `/${privateRoutNames.infoAboutCommand}`, component: InfoAboutCommandPage, exact: true },
]

export const adminRoutes = [
    { path: `/${adminRoutNames.createOccasion}`, component: CreateOccasionPage, exact: true },
    { path: `/${adminRoutNames.createNews}`, component: CreateNewsPage, exact: true },
    { path: `/${adminRoutNames.teamGrade}`, component: TeamGradePage, exact: true },
    { path: `/${adminRoutNames.administration}`, component: AdministrationPage, exact: true },
    { path: `/${adminRoutNames.workWithCase}`, component: WorkWithCasePage, exact: true },
    { path: `/${adminRoutNames.certificates}`, component: CertificateGenerationPage, exact: true },
    { path: `/${adminRoutNames.partChange}`, component: PartChangePage, exact: true },
]

export const infoRoutes = [
    { path: `/${infoRoutNames.infoTvStat}`, component: InfoTvStatPage, exact: true },
]