export const dateValidator = (date) => {
    const splitDate = date.split('.')
    return `${splitDate[1]}.${splitDate[0]}.${splitDate[2]}`
}

export const timeValidator = (time) => {
    if (!time) {
        return time
    }
    return `${time.getHours()}:${time.getMinutes()}`
}