import classes from './TeamChoiceButton.module.css'
import { useRef, useEffect } from 'react'
function TeamChoiceButton({ onClick, currentCommand }) {
    const refLabel = useRef(null)
    useEffect(() => {
        if (refLabel.current && refLabel.current.className && currentCommand.name) {
            if (currentCommand.name) {
                refLabel.current.className = classes.inputBlock__lable__focus
            }
        }
        // refLabel && refLabel?.current?.className && changeBlureInput()
    }, [refLabel, currentCommand.name])

    function changeFocusInput() {
        refLabel.current.className = classes.inputBlock__lable__focus
    }

    function changeBlureInput() {
        if (!currentCommand.name) {
            refLabel.current.className = classes.inputBlock__lable
        }
    }



    return (
        <div className={classes.choice__wrapp}>
            <label ref={refLabel} className={classes.inputBlock__lable}>Команда</label>
            <div className={classes.wrapper} onClick={onClick} onFocus={changeFocusInput} onBlur={changeBlureInput}>

                <span>{currentCommand && `${currentCommand.name} – стол №${currentCommand.table_number}`}</span>
                <div className={classes.style__input__block__image}>
                    <img src={require("assets/images/arrow.jpg")} />
                </div>
            </div>
        </div >
    );
}

export default TeamChoiceButton;