import { createSlice } from "@reduxjs/toolkit";

export const sidebarSlice = createSlice({
    name: 'sidebar',
    initialState: {
        isActive: false,
    },
    reducers: {
        setSidebarStatus: (state, action) => { state.isActive = action.payload },
    }
})

export const { setSidebarStatus } = sidebarSlice.actions
export default sidebarSlice.reducer