import Profile from "components/UI/profile/Profile";
import ProfileImage from "components/UI/profile/profileImage/ProfileImage";
import "styles/css/profile.css"



function PersonalDataPage() {
    return (
        <div className="profile-container">
            <Profile />
        </div>
    );
}

export default PersonalDataPage;