import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'styles/css/main.css'
import 'styles/css/pop-up-window.css'
import { newName } from 'redux/slice/titleSlice';
import { useLocation, useNavigate } from "react-router-dom";
import NotificationManager from 'react-notifications/lib/NotificationManager';
import Notifications from 'components/UI/popupNotifications/Notifications';
import OAuthService from 'services/OAuthService';
import Strings from 'values/Strings';
import { useFetching } from 'components/hooks/useFetching';
import Loader from 'components/UI/loader/Loader';
import { privateRoutNames, publickRoutNames } from 'router';
import ParticipantService from 'services/ParticipantService';
import { changePopUpWindowStatus } from 'redux/slice/popUpWindowSlice';
import CommandService from 'services/CommandService';
import FirstBlockMain from 'components/UI/mainPage/firstBlock/FirstBlockMain';
import SecondBlockMain from 'components/UI/mainPage/secondBlock/SecondBlockMain';
import ThirdBlockMain from 'components/UI/mainPage/thirdBlock/ThirdBlockMain';
import CardBlock from 'components/UI/cardWithCompany/CardBlock';
import { useDeviceType } from 'components/hooks/useDeviceType';
import OAuth from 'components/oauth/OAuth';
import СonfirmationParticipation from 'components/UI/confirmationParticipation/СonfirmationParticipation'
import TicketParticipation from 'components/UI/ticketParticipation/TicketParticipation';
import OccasionService from 'services/OccasionService';
import classes from "components/UI/mainPage/secondBlock/SecondBlockMain.module.css"
import RoleService from 'services/RoleService';
import SearchCertificate from 'components/UI/certificateValid/searchCertificate/SearchCertificate';
import { setSidebarItem } from 'redux/slice/sidebarItemSlice';




function InfoPage() {

    const location = useLocation()
    const popUpWindowStatus = useSelector(state => state.popUpWindow.status)
    const [onAuth, setOnAuth] = useState(location?.state?.auth || false)
    const cameraData = useSelector(state => state.camera.data)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [userInfo, setUserInfo] = useState({
        id: '', birthday: '', educational_institution: '',
        gender: '', group_class: '', learning_status: '',
        name: '', patronymic: '', phone: '', status: '',
        surname: '', email: '', to_char: '', createAccount: false
    });
    const [commandData, setCommandData] = useState({ name: '', count_participant: '' })
    const [tokenInfo, setTokenInfo] = useState({ tokenType: '', token: '' })
    const [role, setRole] = useState([])
    const device = useDeviceType()


    const notificationWarning = (error) => {
        if (error.length > 0) {
            NotificationManager.warning(error, Strings.notificationsWarningTitle, 5000);
        }
    }

    const notificationError = (error) => {
        if (error.length > 0) {
            NotificationManager.error(JSON.stringify(error), Strings.notificationsErrorTitle, 5000);
        }
    }


    const [fethcingRole, loadingRole, errorRole] = useFetching(async () => {
        try {
            const res = await RoleService.getRoles()
            setRole(res)
        } catch (error) {
            notificationError(error)
        }
    })

    const goToCommandRoom = (data) => {
        localStorage.setItem('command', data)
        // dispatch(setCommand(data))
        dispatch(newName(Strings.teamRoom))
        dispatch(changePopUpWindowStatus(false))
        navigate(`/private/${privateRoutNames.teamRoom}`)
    }

    const [logout, loadingLogout, errorLogout] = useFetching(async (code) => {
        try {
            OAuthService.logout()
            localStorage.clear()
            NotificationManager.success(Strings.notificationsLogout, Strings.notificationsSuccessTitle, 2500);
        } catch (error) {
            notificationError(error)
        }
    })

    const [createCommand, loadingCommand, errorCommand] = useFetching(async (commandData, currentRole) => {
        try {
            if (currentRole === undefined || currentRole === null) {
                notificationWarning(`Выберите роль!`)
            } else {
                if (userInfo.name.length === 0 || userInfo.surname.length === 0) {
                    notificationWarning(`Поля ФИО не должны быть пустыми \n Перейдите в Ваш "Профиль" для заполнения`)
                }
                else if (commandData.name.length === 0) {
                    notificationWarning(`Название команды не может быть пустым`)
                }
                else if (parseInt(commandData.count_participant) > 6 || commandData.count_participant.length === 0) {
                    notificationWarning(`Команда должна состоять от 1 до 5 человек`)
                }
                else {
                    const accessToken = localStorage.getItem('accessToken')
                    if (!accessToken) {
                        navigate(`/${publickRoutNames.mainInfo}`, { state: { auth: true } })
                    }
                    // токен, кол участников, имя команды
                    if (!isNaN(commandData.count_participant)) {
                        const res = await CommandService.createCommand({
                            occasionToken: tokenInfo.token,
                            role_id: currentRole.id, ...commandData
                        })
                        res ? localStorage.setItem('command', res) : localStorage.removeItem("command");
                        if (res.status === 404) {
                            notificationWarning(res.message)
                        } else {
                            goToCommandRoom(res)
                        }
                    } else {
                        notificationWarning(`В поле кол-во участников должно быть число!`)
                    }
                }
            }
        } catch (error) {
            notificationError(error)
        }
    })

    const [confirmParticipation, loadingConfirmParticipation, errorConfirmParticipation] = useFetching(async ({ tokenType, token }) => {
        try {
            // Получаем токен и тип и записываем в состояние
            setTokenInfo({ tokenType, token })
            // --------------------------------------------------
            const res = await ParticipantService.checkParticipation(token)
            setUserInfo(res)
            dispatch(changePopUpWindowStatus(true))
        } catch (error) {
            notificationError(error)
        }
    })

    const [joinTheCommand, loadingjoinTheCommand, errorjoinTheCommand] = useFetching(async (currentRole) => {
        try {
            if (currentRole === undefined || currentRole === null) {
                notificationWarning(`Выберите роль!`)
            }
            else if (userInfo.name.length === 0 || userInfo.surname.length === 0) {
                notificationWarning(`Поля ФИО не должны быть пустыми \n Перейдите в Ваш "Профиль" для заполнения`)
            }
            else {
                const accessToken = localStorage.getItem('accessToken')
                if (!accessToken) {
                    navigate(`/${publickRoutNames.mainInfo}`, { state: { auth: true } })
                }
                const res = await CommandService.joinTheCommand({
                    commandToken: tokenInfo.token,
                    role_id: currentRole.id
                })
                if (res.status === 404) {
                    notificationWarning(res.message)
                } else {
                    goToCommandRoom(res)
                }
            }

        } catch (error) {
            notificationError(error)
        }
    })



    useEffect(() => {
        fethcingRole()
        if (location?.state?.logout) {
            dispatch(setSidebarItem(Strings.sidebarMain))
            logout()
        }
    }, [])

    const handleAuthChange = useCallback(() => {
        if (onAuth || location?.state?.auth) {
            dispatch(changePopUpWindowStatus(true));
        }
        setOnAuth(location?.state?.auth ? location?.state?.auth : false);
    }, [dispatch, location?.state?.auth, onAuth, setOnAuth]);

    useEffect(() => {
        handleAuthChange();
    }, [handleAuthChange]);


    const checkClassForAnimationSecondBlock = (secondBlockAnimationRef, innerHeight) => {
        const secondBlockClassList = secondBlockAnimationRef?.current?.classList
        const lengthSecondBlockClassList = secondBlockClassList?.length
        const secondBlockComponentInfo = secondBlockAnimationRef?.current?.getBoundingClientRect()
        const secondBlockTop = secondBlockComponentInfo?.top ?? null

        if (secondBlockTop + 100 < innerHeight) {
            if (lengthSecondBlockClassList === 2) {
                secondBlockClassList.remove(classes.animation_ux)
            }
        } else {
            if (lengthSecondBlockClassList === 1) {
                secondBlockClassList.add(classes.animation_ux)
            }
        }
    }

    const checkAuthAndReg = (data) => {
        const accessToken = localStorage.getItem('accessToken')

        const $userInfo = OAuthService.decryptedSync('userData')
        const profileIsEmpty = $userInfo ? Object.values($userInfo?.user).filter(item => (item === '' || item === null)) : null
        if (!accessToken) {
            // Сохранение токена, чтобы после перезагрузки его взять
            localStorage.setItem('urlReg', JSON.stringify(data))
            navigate(`/${publickRoutNames.mainInfo}`, { state: { auth: true } })
        } else if (profileIsEmpty.length > 0) {
            localStorage.setItem('urlReg', JSON.stringify(data))
            navigate(`/private/${privateRoutNames.personalData}`)
            notificationWarning(Strings.notificationsWarningDataEmptyProfile)
        } else {
            confirmParticipation(data)
            dispatch(changePopUpWindowStatus(true))
        }
    }

    const [fetchingOAuth, loadingOAuth, errorOAuth] = useFetching(async (code) => {
        try {
            let res = ''
            const [access, source] = code.split('/')
            res = await OAuthService.yandexGetToken(code)
            localStorage.setItem('accessToken', res.accessToken)
            res?.userData ? localStorage.setItem('userData', res.userData) : localStorage.removeItem("userData");
            if (!res.createAccount) {
                dispatch(newName(Strings.sidebarOccasion))
                const commandRes = await CommandService.checkCommand()
                commandRes ? localStorage.setItem('command', commandRes) : localStorage.removeItem("command");
                const urlReg = localStorage.getItem('urlReg')
                if (urlReg) {
                    checkAuthAndReg(JSON.parse(urlReg))
                } else {
                    const occasionId = await localStorage.getItem('urlCreateTask')
                    if (occasionId) {
                        dispatch(setSidebarItem(Strings.createCase))
                        navigate(`/private/${privateRoutNames.createCase}`)
                    } else {
                        dispatch(setSidebarItem(Strings.sidebarOccasion))
                        navigate(`/${publickRoutNames.occasion}`)
                    }
                }
                NotificationManager.success(Strings.notificationsAuth, Strings.notificationsSuccessTitle, 2500);
            } else {
                dispatch(setSidebarItem(Strings.sidebarProfile))
                notificationWarning(Strings.notificationsWarningDataEmptyProfile)
                navigate(`/private/${privateRoutNames.personalData}`)
            }
        } catch (error) {
            notificationError(error)
        }
    })

    useEffect(() => {
        if (location?.search?.includes("code")) {
            const code = location.search.slice(6)
            code.length > 0 && fetchingOAuth(code)
        }
    }, [location])


    const checkAuthForCreateTask = (occasion) => {
        if (occasion) {
            const accessToken = localStorage.getItem('accessToken')
            localStorage.setItem('urlCreateTask', occasion.toString())
            if (!accessToken) {
                // Сохранение токена, чтобы после перезагрузки его взять
                navigate(`/${publickRoutNames.mainInfo}`, { state: { auth: true } })
            } else {
                navigate(`/private/${privateRoutNames.createCase}`)
            }
        }
    }

    let statisticAnimationStatus = true
    const [value, setValue] = useState({ "participant_count": 0, "occasion_count": 0, "total_prize_fund": 0 });

    const [fetchingOccasionTotalInfo, loadingOccasionTotalInfo, errorOccasionTotalInfo] = useFetching(async () => {
        try {
            statisticAnimationStatus = false
            const res = await OccasionService.getOccasionTotalInfo()
            setValue(res)
        } catch (error) {
            notificationError(error)
        }
    }, false)

    const statisticAnimationRef = useRef(null)
    const secondBlockAnimationRefTop = useRef(null)
    const secondBlockAnimationRefBottom = useRef(null)
    const secondBlockAnimationRefMiddle = useRef(null)
    const scrollHandler = async () => {
        const statisticComponentInfo = statisticAnimationRef?.current?.getBoundingClientRect()
        const statisticTop = statisticComponentInfo?.top ?? null
        const innerHeight = window.innerHeight
        if (statisticTop + 100 < innerHeight) {
            statisticAnimationStatus && fetchingOccasionTotalInfo()
        } else {
            if (!statisticAnimationStatus) {
                statisticAnimationStatus = true
                setValue({ "participant_count": 0, "occasion_count": 0, "total_prize_fund": 0 })
            }
        }

        secondBlockAnimationRefTop && checkClassForAnimationSecondBlock(secondBlockAnimationRefTop, innerHeight)
        secondBlockAnimationRefMiddle && checkClassForAnimationSecondBlock(secondBlockAnimationRefMiddle, innerHeight)
        secondBlockAnimationRefBottom && checkClassForAnimationSecondBlock(secondBlockAnimationRefBottom, innerHeight)
    }

    useEffect(() => {
        document.addEventListener('scroll', scrollHandler)
        return () => document.removeEventListener('scroll', scrollHandler)
    }, [])


    const urlParams = new URLSearchParams(window.location.search)
    useEffect(() => {
        const occasion = urlParams.get('occasion')
        const occasionToken = urlParams.get('occasionToken')
        const commandToken = urlParams.get('commandToken')
        if (occasionToken) {
            const data = { tokenType: 'occasionToken', token: occasionToken }
            checkAuthAndReg(data)
        }
        if (commandToken) {
            const data = { tokenType: 'commandToken', token: commandToken }
            checkAuthAndReg(data)
        }
        if (occasion) {
            checkAuthForCreateTask(occasion)
        }
    }, [])



    const closePopUpWindows = () => {
        dispatch(newName(Strings.sidebarMain))
        dispatch(changePopUpWindowStatus(false))
        setOnAuth(false)
        setUserInfo({
            id: '', birthday: '', educational_institution: '',
            gender: '', group_class: '', learning_status: '',
            name: '', patronymic: '', phone: '', status: '',
            surname: '', email: '', to_char: ''
        })
    }


    return (
        <Loader>
            {device === "phone"
                ? <div>
                    <div className="main-container" onClick={() => onAuth && closePopUpWindows()}>
                        {onAuth &&
                            <div className="pop-up oauth-top">
                                <div className="pop-up__container">
                                    <OAuth setTokenInfo={setTokenInfo} setUserInfo={setUserInfo} />
                                </div>
                            </div>
                        }
                        {(popUpWindowStatus && tokenInfo.tokenType === 'occasionToken' && (userInfo?.login?.length > 0)) &&
                            <div className="pop-up">
                                <div className="pop-up__container">
                                    <СonfirmationParticipation role={role} close={closePopUpWindows}
                                        createCommand={createCommand} userInfo={userInfo}
                                        commandData={commandData} setCommandData={setCommandData}
                                    />
                                </div>
                            </div>
                        }
                        {(popUpWindowStatus && tokenInfo.tokenType === 'commandToken' && (userInfo?.login?.length > 0)) &&
                            <div className="pop-up">
                                <div className="pop-up__container">
                                    <TicketParticipation role={role} close={closePopUpWindows}
                                        userInfo={userInfo} preRegistration={joinTheCommand} commandName={userInfo?.commandname?.name}
                                    />
                                </div>
                            </div>
                        }
                        <div className={`news ${(onAuth || popUpWindowStatus) && 'pop-up-blure'}`}>
                            <FirstBlockMain />
                            <SearchCertificate />
                            <SecondBlockMain
                                secondBlockAnimationRefTop={secondBlockAnimationRefTop}
                                secondBlockAnimationRefMiddle={secondBlockAnimationRefMiddle}
                                secondBlockAnimationRefBottom={secondBlockAnimationRefBottom}
                            />
                            <ThirdBlockMain
                                value={value}
                                statisticAnimationRef={statisticAnimationRef}
                            />
                            <CardBlock />
                        </div>
                    </div>
                </div>
                : <div>
                    <div className="main-container" onClick={() => onAuth && closePopUpWindows()}>
                        {(onAuth) &&
                            <div className="pop-up">
                                <div className="pop-up__container">
                                    <OAuth setTokenInfo={setTokenInfo} setUserInfo={setUserInfo} />
                                </div>
                            </div>
                        }
                        {(popUpWindowStatus && tokenInfo.tokenType === 'occasionToken' && (userInfo?.login?.length > 0)) &&
                            <div className="pop-up">
                                <div className="pop-up__container">
                                    <СonfirmationParticipation role={role} close={closePopUpWindows}
                                        createCommand={createCommand} userInfo={userInfo}
                                        commandData={commandData} setCommandData={setCommandData}
                                    />
                                </div>
                            </div>
                        }
                        {(popUpWindowStatus && tokenInfo.tokenType === 'commandToken' && (userInfo?.login?.length > 0)) &&
                            <div className="pop-up">
                                <div className="pop-up__container">
                                    <TicketParticipation role={role} close={closePopUpWindows} commandName={userInfo?.commandname?.name}
                                        preRegistration={joinTheCommand}
                                    />
                                </div>
                            </div>
                        }
                        <div className={`news ${(onAuth || popUpWindowStatus) && 'pop-up-blure'}`}>

                            <FirstBlockMain />
                            <SearchCertificate />
                            <SecondBlockMain
                                secondBlockAnimationRefTop={secondBlockAnimationRefTop}
                                secondBlockAnimationRefMiddle={secondBlockAnimationRefMiddle}
                                secondBlockAnimationRefBottom={secondBlockAnimationRefBottom}
                            />
                            <ThirdBlockMain
                                value={value}
                                statisticAnimationRef={statisticAnimationRef}
                            />
                            <CardBlock />
                        </div>
                    </div>
                </div>
            }
            <Notifications />
        </Loader>
    );
}

export default InfoPage;



