import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { newName } from "redux/slice/titleSlice";
import Board from "components/UI/board/Board";
import { useFetching } from "components/hooks/useFetching";
import CertificatService from "services/CertificatService";
import Loader from "components/UI/loader/Loader";
function LeaderBoardePage() {

    const dispatch = useDispatch()
    const [data, setData] = useState([])
    const [fetchingLeaderBoard, loadingLeaderBoard, errorLeaderBoard] = useFetching(async () => {
        try {
            const res = await CertificatService.getInfoLeaderBoard()
            setData(res)
        } catch (error) {
            console.log(error)
        }
    })

    useEffect(() => {
        dispatch(newName('Таблица лидеров'))
        fetchingLeaderBoard()
    }, [])


    return (
        <Loader localStatus={loadingLeaderBoard}>
             <Board data={data} />
        </Loader>
    );
}

export default LeaderBoardePage;