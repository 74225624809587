import classes from "./TextLocation.module.css"
import { useState } from "react"

const TextLocation = ({ title, setLocation, ...props }) => {

    const [selectItem, setSelectItem] = useState(null)

    function onClickButton(position) {
        setLocation(position)
        setSelectItem(position)
    }
    return (
        <fieldset className={classes.container}>
            <legend className={classes.textLocation__legendStyle}>{title}</legend>
            <div><button style={{ border: selectItem === 'left' && "solid 0.2vw var(--main-color-border)" }} className={classes.textLocation__buttonStyle} onClick={() => onClickButton('left')} >Слева</button></div>
            <div><button style={{ border: selectItem === 'center' && "solid 0.2vw var(--main-color-border)" }} className={classes.textLocation__buttonStyle} onClick={() => onClickButton('center')}>По центру</button></div>
            <div><button style={{ border: selectItem === 'right' && "solid 0.2vw var(--main-color-border)" }} className={classes.textLocation__buttonStyle} onClick={() => onClickButton('right')}>Справа</button></div>
        </fieldset >
    )
}

export default TextLocation