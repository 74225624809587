import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import Colors from "values/Colors";
import classes from "./TeamGrade.module.css"
import { useDeviceType } from "components/hooks/useDeviceType";
export const Card = ({ id, text, index, moveCard, children }) => {
    const ref = useRef(null);
    const [{ handlerId }, drop] = useDrop({
        accept: 'card',
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId()
            };
        },
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }

            const hoverBoundingRect = ref.current?.getBoundingClientRect();

            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            const clientOffset = monitor.getClientOffset();

            const hoverClientY = clientOffset.y - hoverBoundingRect.top;

            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }

            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            moveCard(dragIndex, hoverIndex);

            item.index = hoverIndex;
        }
    });
    const [{ isDragging }, drag] = useDrag({
        type: "card",
        item: () => {
            return { id, index };
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        })
    });
    const opacity = isDragging ? 0 : 1;
    drag(drop(ref));
    const device = useDeviceType()
    const getShadow = (color) => device === 'phone' ? `0px 0px 2vw 0vw ${color}` : `0px 0px 0.4vw 0px ${color}`

    return (
        <div ref={ref} className={classes.list__dragan__style} style={{
            opacity,
            boxShadow: index === 0 ? getShadow(Colors.markGreen) :
                index === 1 ? getShadow(Colors.markYellow) :
                    index === 2 ? getShadow(Colors.markRed) : getShadow(Colors.blue2)
        }} data-handler-id={handlerId}>
            {index + 1} - {text} {children}
        </div>
    );
};
