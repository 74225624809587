import { createSlice } from "@reduxjs/toolkit";


export const navbarSlice = createSlice({
    name: 'navbar',
    initialState: {
        nameNavbar: null,
    },
    reducers: {
        setNavBar: (state, action) => { state.nameNavbar = action.payload },
    }
})

export const { setNavBar } = navbarSlice.actions
export default navbarSlice.reducer