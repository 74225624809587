import Loader from "components/UI/loader/Loader";
import { useState, useEffect, useRef } from "react";
import AcordionBlock from 'components/UI/acordionBlock/AcordionBlock';
import Tooltip from "rc-tooltip";
import IconWithText from "components/UI/iconWithText/IconWithText";
import InputBlock from "components/UI/inputBlock/InputBlock";
import NotificationManager from 'react-notifications/lib/NotificationManager';
import Strings from "values/Strings";
import MentorService from "services/MentorService";
import { useFetching } from "components/hooks/useFetching";
import OccasionService from "services/OccasionService";
import { useDispatch } from "react-redux";
import { changeLoaderStatus } from "redux/slice/loaderSlice";
import { newName } from "redux/slice/titleSlice";
import 'styles/css/team-grade-mentor.css'
import TitleMain from "components/UI/titleMain/TitleMain";
import CaseContainer from "components/UI/case/caseContainer/CaseContainer";
import CircularChart from "components/UI/circleChart/CircleChart";
import { useDeviceType } from "components/hooks/useDeviceType";
function TeamGradeMentorPage() {


    const notificationWarning = (error) => {
        if (error.response && error.response.data && error.response.data.message) {
            NotificationManager.warning(`${error.response.data.message}`, Strings.notificationsWarningTitle, 5000);
        } else {
            NotificationManager.warning(`${error.message}`, Strings.notificationsWarningTitle, 5000);
        }
    }
    const notificationError = (error) => {
        if (error.response && error.response.data && error.response.data.message) {
            NotificationManager.error(`${error.response.data.message}`, Strings.notificationsErrorTitle, 5000);
        } else {
            NotificationManager.error(`${error.message}`, Strings.notificationsErrorTitle, 5000);
        }
    }

    const [studen, setStuden] = useState(0);
    const [school, setSchool] = useState(0);
    const [command, setCommand] = useState(0);
    const [allPart, setAllPart] = useState(0);

    const [accordionBlock, setAccordionBlock] = useState(null)

    const [fetchingProfileMentor, loadingProfileMentor, errorProfileMentor] = useFetching(async (data) => {
        try {
            const mass = []
            const elementFormap = []

            if (data) {
                let newStuden = 0;
                let newSchool = 0;
                let newAllPart = 0;

                const res = await MentorService.getProfileMentor(data)

                res.forEach(element => {
                    mass.push(element["id_command"])
                });
                const uniqueMass = [...new Set(mass)];

                uniqueMass.forEach(element => {
                    elementFormap.push(res.filter(obj => obj.id_command === element))
                });

                const uniqueCommands = [...new Set(elementFormap[0].map(item => item.id_command))];

                elementFormap[0].forEach(element => {
                    if (element.status === "студент") {
                        newStuden += 1
                    }
                    if (element.status === "школьник") {
                        newSchool += 1
                    }
                    newAllPart += 1
                });

                setStuden(newStuden);
                setSchool(newSchool);
                setCommand(uniqueCommands.length);
                setAllPart(newAllPart);

                setAccordionBlock(
                    elementFormap.map((value, key) => <AcordionBlock table={value} presentation={value[0]["presentation"]} key={`${value[0]?.id_command
                        }_${key}`} addational_material={value[0]["additional_material"]} teamName={value[0]["command_name"]} tableNumber={value[0]["table_number"]} caseName={value[0]["title"]} />)

                )
            } else {
                notificationWarning(Strings.profilieMentorUndefinedOccasionId)
            }
        } catch (error) {
            notificationWarning(error)
        }
    })

    const device = useDeviceType()
    const ref = useRef()
    const dispatch = useDispatch()
    const [currentOccasion, setCurrentOccasion] = useState(null)

    const [getCurrentOccasions, loadingCurrentOccasions, errorCurrentOccasions] = useFetching(async () => {
        try {
            const res = await OccasionService.getOccasionsForMentor()
            setCurrentOccasion(res)
            fetchingProfileMentor(res.id)
            dispatch(changeLoaderStatus(false))
        } catch (error) {
            notificationError(error)
        }
    })

    useEffect(() => {
        getCurrentOccasions()
        dispatch(newName(Strings.profilieMentorTitle))
    }, [])

    return (
        <Loader localStatus={loadingProfileMentor}>
            <TitleMain text={"Информация о командах"} jsc="center" fns="1.5vw" elpSize="0.9vw" />
            {command > 0 &&
                <div style={{ display: "flex", flexDirection: device === "phone" ? "column" : "row", marginBottom: device === "phone" ? "10vw" : "3vw" }}>
                    <div style={{ display: "flex", justifyConten: "space-betwen", width: "100%" }}>
                        <CircularChart
                            titleUnderChart="Кол-во команд"
                            percentText={command}
                            percentChart={100}
                            percentUnderText="шт."
                        />
                        <CircularChart
                            titleUnderChart="Кол-во участников"
                            percentText={allPart}
                            percentChart={100}
                            percentUnderText="шт."
                        />
                    </div>
                    <div style={{ display: "flex", width: "100%" }}>
                        <CircularChart
                            titleUnderChart="Процент студентов"
                            percentText={`${(studen / allPart * 100).toFixed(0)}%`}
                            percentChart={(studen / allPart * 100).toFixed(0)}
                        />
                        <CircularChart
                            titleUnderChart="Процент школьников"
                            percentText={`${(school / allPart * 100).toFixed(0)}%`}
                            percentChart={(school / allPart * 100).toFixed(0)}
                        />
                    </div>

                </div>
            }
            {/* <h3 className='h3__style'>Информация о командах</h3> */}
            {accordionBlock?.length > 0 && accordionBlock ? accordionBlock : (currentOccasion !== null && accordionBlock?.length === 0) ? <CaseContainer text={Strings.mentorProfileNothingCase} /> : <CaseContainer text={Strings.mentorProfileDontStart} />}
        </Loader>
    );
}

export default TeamGradeMentorPage;