import { GithubPlacement } from '@uiw/react-color-github';
import Chrome from '@uiw/react-color-chrome';


const ColorPicker = ({ settingsKey, setSetting, setting }) => <Chrome
    color={setting[settingsKey]}
    style={{ float: 'left', marginBottom: '1vw' }}
    placement={GithubPlacement.Top}
    onChange={(color) => {
        setSetting(old => ({ ...old, [settingsKey]: color.hexa }))
    }}
/>

export default ColorPicker