import classes from "./Description.module.css"
import { useState, useRef, useEffect } from "react";
import "@fontsource/roboto"

const Description = ({ children, minH = "11vw", width = "", value, setValue, placeholder, obBlure, defaultCharactesLeft = 512, charactesLeftStatus = true, readOnly = false, ...props }) => {
    const [charactesLeft, setCharactesLeft] = useState(defaultCharactesLeft)
    const refLabel = useRef(null)
    const calculateCharactesLeft = e => {
        e = e.target.value
        setValue(e)
        setCharactesLeft(defaultCharactesLeft - e.length)
    }

    useEffect(() => {
        if (refLabel.current && refLabel.current.className && value) {
            if (value) {
                refLabel.current.className = classes.inputBlock__lable__focus
            }
        }
        // refLabel && refLabel?.current?.className && changeBlureInput()
    }, [refLabel, value])

    function changeFocusInput() {

        refLabel.current.className = classes.inputBlock__lable__focus
    }

    function changeBlureInput() {
        if (!value) {
            refLabel.current.className = classes.inputBlock__lable
        }
    }

    return (
        <div onBlur={changeBlureInput} onFocus={changeFocusInput} className={classes.style__input__block} style={{ width: width }}>
            <label ref={refLabel} className={classes.inputBlock__lable}>{placeholder}</label>
            <textarea readOnly={readOnly} maxLength={defaultCharactesLeft} className={classes.style__input} style={{ height: minH }} value={value ? value : ''} onChange={e => calculateCharactesLeft(e)} >
            </textarea>
            <div className={classes.style__input____block__image}>
                {charactesLeftStatus &&
                    <h3>{charactesLeft}</h3>
                }
            </div>
        </div>


    )
}

export default Description