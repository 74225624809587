import { createSlice } from "@reduxjs/toolkit";

export const cameraSlice = createSlice({
    name: 'cameraStatus',
    initialState: {
        isCamera: false,
        data: '',
    },
    reducers: {
        setCameraStatus: (state, action) => { state.isCamera = action.payload },
        setCameraData: (state, action) => { state.data = action.payload },
    }
})

export const { setCameraStatus, setCameraData } = cameraSlice.actions
export default cameraSlice.reducer