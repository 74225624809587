import FinalMark from "components/UI/grade/finalMark/FinalMark";
function TeamGradeFinalMark() {

    return (
        <div>
            <FinalMark />
        </div>
    );
}

export default TeamGradeFinalMark;