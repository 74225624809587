import env from '../../package.json'
import $api from "http";


export default class RatingJuryService {

    static juryGradeSum = async (obj) => {
        const values = Object.values(obj)
        return values.reduce((sum, item) => sum + item, 0)
    }

    static createOrUpdateRatingJuryCommand = async (data) => {
        const response = await $api.post(`${env.nodeHost}/api/rating-jury`, data)
        return response.data
    }

    static getGradesCurrentCommands = async (data) => {
        const response = await $api.get(`/api/rating-jury/current-grade?${data}`)
        return response.data
    }

    static getFinalMark = async () => {
        const response = await $api.get(`/api/rating-jury/final-mark`)
        return response.data
    }

    static getFinnalRatings = async (occasion) => {
        const response = await $api.get(`/api/rating-jury/final-grade/${occasion}`)
        return response.data
    }

    static updateRatingJuryPosition = async (data) => {
        const response = await $api.put(`/api/rating-jury/final-mark-position`, data)
        return response.data
    }

    static getCurrentCheckPoint = async (checkPointNumber) => {
        const response = await $api.get(`/api/check-point/grade/${checkPointNumber}`)
        return response.data
    }


}