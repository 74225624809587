import classes from "./MainContact.module.css"
import "@fontsource/roboto";
import ContactCard from "../contactCard/ContactCard";
import TitleMain from "components/UI/titleMain/TitleMain";
const MainContact = () => {
    return (
        <div className={classes.contact__style}>

            <div className={classes.wrapper__style}>
                <TitleMain text={"Мы на связи"} jsc="center" />
                <span>Мы принимаем <b>коммерческие</b> предложения на почту <a href="mailto:support@edro.su"><b>commerce@edro.su</b></a><br /><br />
                    Если у Вас <b>некоммерческое</b> предложение и Вы хотите связаться с нашей командой, Вы можете это сделать написав нам на почту <a href="mailto:sazikov_rostislaw@mail.ru"><b>support@edro.su</b></a><br /><br /></span>
                <div className={classes.adress__block__style}>
                    <div className={classes.first__block__style}>
                        <h3>Офис в СурГУ ПИ</h3>
                        <span>Россия, г.Сургут,<br /> Энаргетиков 22,<br /> кабинет У800<br />
                            <a href="tel:+79995711031">Тел: +7 (999) 571 10-31</a></span>
                    </div>
                    <div className={classes.second__block__style}>
                        <h3>Мы в соц. сетях</h3>
                        <div className={classes.vk__style} onClick={() => window.open("https://vk.com/edro_technologies", '_blank', 'noopener,noreferrer')}>
                            <img src={require("assets/icon/vk.jpg")} />
                            <span className={classes.social__media}>Мы в ВКонтакте</span>
                        </div>
                        <div className={classes.tg__style} onClick={() => window.open("https://web.telegram.org/z/#603662889", '_blank', 'noopener,noreferrer')}>
                            <img src={require("assets/icon/tg.jpg")} />
                            <span className={classes.social__media}>Мы в Телеграм</span>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default MainContact