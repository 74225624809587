import Colors from 'values/Colors';
import QRCodeStyling from "qr-code-styling";
import Logo from "assets/icon/logo_blue.png"
export const qrCodeDefaultValue = {
    margin: 0,
    image: Logo,
    backgroundOptions: {
        color: "#ffffff00",
        gradient: null
    },
    backgroundOptionsHelper: {
        colorType: {
            single: true,
            gradient: false
        },
        gradient: {
            linear: true,
            radial: false,
            color1: "#ffffff",
            color2: "#ffffff",
            rotation: "0"
        }
    },
    dotsOptions: {
        type: "rounded",
        gradient: {
            type: "linear",
            rotation: 150,
            colorStops: [
                {
                    offset: 0.3,
                    color: Colors.blue2
                },
                {
                    offset: 1,
                    color: Colors.blue
                }
            ]
        }
    },
    imageOptions: {
        crossOrigin: "extra rounded",
        margin: 20,
    },
    dotsOptionsHelper: {
        colorType: {
            single: true,
            gradient: false
        },
    },
    cornersSquareOptions: {
        type: "extra-rounded",
        gradient: {
            type: "linear",
            rotation: 180,
            colorStops: [
                {
                    offset: 0.15,
                    color: Colors.blue2
                },
                {
                    offset: 1,
                    color: Colors.blue
                }
            ]
        }
    },
    imageOptions: {
        hideBackgroundDots: true,
        imageSize: 0.25,
        margin: 1
    }
}
const QrCode = new QRCodeStyling(qrCodeDefaultValue);

export default QrCode

