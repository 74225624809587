import axios from "axios";
import env from '../../package.json'
import $api from "http";


export default class AccountService {

    static getAccounts = async () => {
        const response = await axios.get(`${env.nodeHost}/api/account`)
        return response.data
    }

    static getAccount = async (id) => {
        const response = await $api.get(`${env.nodeHost}/api/account/${id}`)
        return response.data
    }

    static searchUserByEmail = async (data) => {
        const response = await $api.get(`${env.nodeHost}/api/account/search/${data}`, { withCredentials: true })
        return response.data
    }

    static updateAccount = async (account) => {
        const formData = new FormData()
        for (let key in account) {
            formData.append(key, account[key])
        }
        const response = await $api.put(`${env.nodeHost}/api/account`, formData, { ...env.axiosConfig.data, withCredentials: true })
        return response.data
    }
}