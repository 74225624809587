import classes from "./TextCoordinates.module.css"


const TextCoordinates = ({ onF, focus, title, onChange, currentCoordinates, objName, activate, ...props }) => {
    const onChangeAndActivate = (event, y = false) => {
        activate(true)
        onChange(event, y)
    }

    return (
        <fieldset className={classes.container}>
            <legend className={classes.textLocation__legendStyle}>{title}</legend>
            <div className={classes.coordinates}>
                <input onFocus={onF} style={{ border: focus === 1 && "solid 0.15vw var(--main-color-border)" }} type="number" placeholder="x" value={currentCoordinates[`x_${objName}`]} onChange={onChangeAndActivate} onClick={activate} />
            </div>
            <span>:</span>
            <div className={classes.coordinates}>
                <input onFocus={onF} style={{ border: focus === 1 && "solid 0.15vw var(--main-color-border)" }} type="number" placeholder="y" value={currentCoordinates[`y_${objName}`]} onChange={e => onChangeAndActivate(e, true)} onClick={activate} />
            </div>
        </fieldset>
    )
}

export default TextCoordinates