import classes from "./MentorCreateCase.module.css"
import "@fontsource/roboto"
import InputBlock from "components/UI/inputBlock/InputBlock"
import { useState, useRef, useEffect } from 'react'
import Description from "components/UI/description/Description"
import Button from "components/UI/buttons/button/Button"
import Tooltip from "rc-tooltip"
import 'rc-tooltip/assets/bootstrap_white.css';
import { useDeviceType } from "components/hooks/useDeviceType"
import { useDispatch } from "react-redux"
import TaskService from "services/TaskService"
import { useFetching } from "components/hooks/useFetching"
import { changeLoaderStatus } from "redux/slice/loaderSlice"
import NotificationManager from "react-notifications/lib/NotificationManager"
import Notifications from "../popupNotifications/Notifications"
import Strings from "values/Strings"
import Loader from "../loader/Loader"
import DragAndDrop from "../dragAndDrop/DragAndDrop"
import DragAndDropPlaceholder from "../dragAndDrop/DragAndDropPlaceholder"
import { AiOutlineClose } from "react-icons/ai"
import OccasionService from "services/OccasionService"
import CategoryParticipantService from "services/CategoryParticipantService"
import { newName } from "redux/slice/titleSlice"
import OAuthService from "services/OAuthService"


const MentorCreateCase = () => {

    const notificationError = (error) => {
        if (error.length > 0) {
            NotificationManager.error(JSON.stringify(error), Strings.notificationsErrorTitle, 5000);
        }
    }

    const notificationWarning = (error) => {
        if (error.length > 0) {
            NotificationManager.warning(error, Strings.notificationsWarningTitle, 5000);
        }
    }


    const [createCase, setCreateCase] = useState({
        additional_material: "",
        category: "",
        contact_tg: "",
        expected_result: "",
        occasion: {},
        presentation: null,
        prize: "",
        specification: "",
        title: "",
    });
    const [dragEndDropText, setDragEndDropText] = useState("")
    const dragZoneRef = useRef()
    // -------------------------------------DragAndDrop------------------------------------
    function imagePreview(imageFile) {
        setCreateCase(old => ({ ...old, presentation: imageFile }))
        NotificationManager.success(Strings.notificationsAddFile, Strings.notificationsSuccessTitle, 2500);
    }

    function removeImage() {
        setCreateCase(old => ({ ...old, presentation: null }))
        // NotificationManager.success(Strings.notificationsRemoveFile, Strings.notificationsSuccessTitle, 2500);
    }
    // ------------------------------------------------------------------------------

    const ref = useRef()
    const device = useDeviceType()
    const dispatch = useDispatch()

    const verificationCompleteness = (data) => {
        if (data.occasion === undefined || data.occasion === null || data.occasion === '') {
            notificationWarning('Мероприятие не выбрано!')
            return false
        }
        if (data.title === undefined || data.title === null || data.title === '') {
            notificationWarning('Поле "Название" не заполнено!')
            return false
        }
        if (data.category === undefined || data.category === null || data.category === '') {
            notificationWarning('Поле "Категория" не заполнено!')
            return false
        }
        if (data.prize === undefined || data.prize === null || data.prize === '') {
            notificationWarning('Поле "Приз" не заполнено!')
            return false
        }
        if (data.presentation === undefined || data.presentation === null || data.presentation === '') {
            notificationWarning('Поле "Презентация" не заполнено!')
            return false
        }
        if (data.contact_tg === undefined || data.contact_tg === null || data.contact_tg === '') {
            notificationWarning('Поле "Контакты" не заполнено!')
            return false
        }
        if (data.specification === undefined || data.specification === null || data.specification === '') {
            notificationWarning('Поле "Описание" не заполнено!')
            return false
        }
        if (data.expected_result === undefined || data.expected_result === null || data.expected_result === '') {
            notificationWarning('Поле "Ожидаемый результат" не заполнено!')
            return false
        }
        return true
    }


    const [dataAboutCategory, setDataAboutCategory] = useState([])
    const [getCategoryParticipant, loadingCategoryParticipant, errorCategoryParticipant] = useFetching(async (id) => {
        try {
            const res = await CategoryParticipantService.getOne(id)
            setDataAboutCategory(res)
            dispatch(changeLoaderStatus(false))
        } catch (error) {
            notificationError(error)
        }
    }, false)

    const [createTask, loadingTask, errorTask] = useFetching(async (createCase) => {
        try {
            const result = verificationCompleteness(createCase)
            if (result) {
                const res = await TaskService.createTask(createCase)
                if (res) {
                    await NotificationManager.success(Strings.notificationsCaseCreated, Strings.notificationsSuccessTitle, 2500);
                    setCreateCase({
                        additional_material: "",
                        category: "",
                        contact_tg: "",
                        expected_result: "",
                        occasion: {},
                        presentation: null,
                        prize: "",
                        specification: "",
                        title: "",
                    })
                }
                removeImage()
                OAuthService.refreshTokens()
                dispatch(changeLoaderStatus(false))
            }
        } catch (error) {
            notificationError(error)
        }
    })

    const choiceOccasion = (data, index) => {
        setCreateCase(old => ({ ...old, occasion: occasions[index] }))
        getCategoryParticipant(occasions[index].id)
    }

    const [occasions, setOccasions] = useState([])
    const [getCurrentOccasions, loadingCurrentOccasions, errorCurrentOccasions] = useFetching(async () => {
        try {
            const res = await OccasionService.getCurrentOccasions()
            setOccasions(res)
            dispatch(changeLoaderStatus(false))
            const occasionId = await localStorage.getItem('urlCreateTask')
            if (occasionId) {
                const currentOccasion = res.find(({ id }) => id === parseInt(occasionId))
                setCreateCase(old => ({ ...old, occasion: currentOccasion }))
                getCategoryParticipant(currentOccasion?.id)
                localStorage.removeItem('urlCreateTask')
            }
        } catch (error) {
            notificationError(error)
        }
    })


    useEffect(() => {
        dispatch(newName(Strings.createCase))
        getCurrentOccasions()
    }, [])



    return (
        <Loader>
            <div ref={ref} className={classes.events__create__style}>
                <ul>
                    {device === "phone"
                        ? <li>
                            <InputBlock readOnly={true} localRef={true} indexStatus={true} placeholder={"Мероприятие"} onListStatus={true} list={occasions.map(item => item.name)} setValue={choiceOccasion} value={createCase?.occasion?.name ? createCase?.occasion?.name : ''} refMainContainer={ref}><img src={require("assets/images/arrow.jpg")} /></InputBlock>
                        </li>
                        : <li>
                            <InputBlock placeholder={"Мероприятие"} readOnly={true} localRef={true} indexStatus={true} onListStatus={true} list={occasions.map(item => item.name)} setValue={choiceOccasion} value={createCase?.occasion?.name ? createCase?.occasion?.name : ''} refMainContainer={ref}><img src={require("assets/images/arrow.jpg")} /></InputBlock>
                        </li>
                    }

                    {Object.keys(createCase?.occasion).length > 0 && <>
                        {device === "phone"
                            ? <li>
                                <InputBlock setValue={char => setCreateCase(old => ({ ...old, title: char }))} value={createCase?.title} onListStatus={false} placeholder={"Название кейса"}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                            </li>
                            : <li>
                                <InputBlock placeholder={"Название кейса"} setValue={char => setCreateCase(old => ({ ...old, title: char }))} value={createCase?.title} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                            </li>
                        }

                        {dataAboutCategory.length > 0 && (device === "phone"
                            ? <li>
                                <div className={classes.input__block}>
                                    {dataAboutCategory.map((item, key) => <div key={key} className={classes.form_radio_btn}>
                                        <input id={`caseB-${key}`} type="radio" name="caseB" checked={item.id === createCase.category} value={item.id} onChange={char => setCreateCase(old => ({ ...old, category: parseInt(char.target.value) }))} />
                                        <label htmlFor={`caseB-${key}`}>{item.name}</label>
                                    </div>)}
                                </div>
                            </li>
                            : <li>
                                <div className={classes.text__style}>
                                    <span className={classes.style__title}>*Категория:</span>
                                </div>
                                <div className={classes.input__block}>

                                    {dataAboutCategory.map((item, key) => <Tooltip key={key} placement="top" trigger={'hover'} overlay={<span style={{ fontSize: "0.7vw", fontFamily: "Roboto" }} className={classes.tooltip__style}>{item.description}</span>}>
                                        <div className={classes.form_radio_btn}>
                                            <input id={`caseB-${key}`} type="radio" name="caseB" checked={item.id === createCase.category} value={item.id} onChange={char => setCreateCase(old => ({ ...old, category: parseInt(char.target.value) }))} />
                                            <label htmlFor={`caseB-${key}`}>{item.name}</label>
                                        </div></Tooltip>)}
                                </div>
                            </li>
                        )}

                        {(dataAboutCategory && device === "phone")
                            ? <li>
                                <InputBlock setValue={char => setCreateCase(old => ({ ...old, prize: char }))} value={createCase?.prize} onListStatus={false} placeholder={"Приз"}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                            </li>
                            : <li>
                                <InputBlock placeholder={"Приз"} setValue={char => setCreateCase(old => ({ ...old, prize: char }))} value={createCase?.prize} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                            </li>
                        }

                        {device === "phone"
                            ? <li className={classes.li__style}>
                                {createCase.presentation
                                    ? <div className={classes.down__block__style} onClick={removeImage}>
                                        <div className={classes.drop__down__style}>
                                            <span>Удалить файл</span>
                                            <AiOutlineClose color="rgb(210, 12, 12)" />
                                        </div>
                                    </div>
                                    : <DragAndDrop justifyContent="left" imagePreview={imagePreview} borderStyle={'none'} dragZoneRef={dragZoneRef}
                                        setDragZoneText={setDragEndDropText} textDragZone="Добавить презентацию">
                                        <DragAndDropPlaceholder height={'11vw'} imagePreview={imagePreview} dragZoneRef={dragZoneRef}>{dragEndDropText}</DragAndDropPlaceholder>
                                    </DragAndDrop>
                                }
                            </li>
                            : <li className={classes.li__style}>
                                {createCase.presentation
                                    ? <div className={classes.presentation__container}>
                                        <span>Презентация загружена</span> <AiOutlineClose onClick={removeImage} color="rgb(210, 12, 12)" />
                                    </div>
                                    : <DragAndDrop justifyContent="left" imagePreview={imagePreview} borderStyle={'none'} dragZoneRef={dragZoneRef}
                                        setDragZoneText={setDragEndDropText} textDragZone="Вставить презентацию">
                                        <DragAndDropPlaceholder height={'15vw'} width={'60vw'} right={'0vw'} imagePreview={imagePreview} dragZoneRef={dragZoneRef}>{dragEndDropText}</DragAndDropPlaceholder>
                                    </DragAndDrop>
                                }
                            </li>
                        }

                        {device === "phone"
                            ? <li>
                                <InputBlock setValue={char => setCreateCase(old => ({ ...old, additional_material: char }))} value={createCase?.additional_material} onListStatus={false} placeholder={"Доп. материал (ссылка)"}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                            </li>
                            : <li>
                                <InputBlock placeholder={"Ссылка на доп. материал"} setValue={char => setCreateCase(old => ({ ...old, additional_material: char }))} value={createCase?.additional_material} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                            </li>
                        }
                        {device === "phone"
                            ? <li>
                                <InputBlock setValue={char => setCreateCase(old => ({ ...old, contact_tg: char }))} value={createCase?.contact_tg} onListStatus={false} placeholder={"Ссылка на соц. сети"}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                            </li>
                            : <li>
                                <InputBlock placeholder={"Ссылка на соц. сети для связи"} setValue={char => setCreateCase(old => ({ ...old, contact_tg: char }))} value={createCase?.contact_tg} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                            </li>
                        }

                        {device === "phone"
                            ? <li className={classes.li__style}>
                                <Description setValue={char => setCreateCase(old => ({ ...old, specification: char }))} value={createCase?.specification} placeholder={"Описание"} />
                            </li>
                            : <li className={classes.li__style}>
                                <Description placeholder={"Описание кейса"} setValue={char => setCreateCase(old => ({ ...old, specification: char }))} value={createCase?.specification} />
                            </li>
                        }

                        {device === "phone"
                            ? <li className={classes.li__style}>
                                <Description setValue={char => setCreateCase(old => ({ ...old, expected_result: char }))} value={createCase?.expected_result} placeholder={"Ожидаемый результат"} />
                            </li>
                            : <li className={classes.li__style}>
                                <Description placeholder={"Ожидаемый результат"} setValue={char => setCreateCase(old => ({ ...old, expected_result: char }))} value={createCase?.expected_result} />
                            </li>
                        }
                    </>}
                </ul>
                {Object.keys(createCase?.occasion).length > 0 &&
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "end" }}>
                        <Button onClick={() => createTask(createCase)}>Отправить</Button>
                    </div>
                }
                <Notifications />
            </div >
        </Loader>
    )
}

export default MentorCreateCase