import React, { useState } from 'react';
import classes from "./SearchCertificate.module.css";
import TitleMain from 'components/UI/titleMain/TitleMain';
import { useDeviceType } from 'components/hooks/useDeviceType';
import { useNavigate } from 'react-router-dom';
import { publickRoutNames } from 'router';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import Strings from 'values/Strings';

const SearchCertificate = (props) => {
    const [value, setValue] = useState('');
    const device = useDeviceType()
    const navigate = useNavigate()
    const handleInputChange = (e) => {
        let input = e.target.value;
        input = input.replace(/[^a-zA-Z0-9]/g, '');

        if (input.length > 4) {
            input = `${input.slice(0, 4)}-${input.slice(4, 8)}`;
        }

        setValue(input);
    };

    function searchCert() {
        const hash = `${value.slice(0, 4)}${value.slice(5)}`
        if (hash.length === 8){
            navigate(`/${publickRoutNames.certificate}/${hash}`)
        }
        else{
            NotificationManager.warning("Введите полный хэш номер сертификата", Strings.notificationsWarningTitle, 5000);
        }
    }

    return (
        <div className={classes.wrapper}>
            {device === "phone" ?
                <div className={classes.block__title__style}>
                    <div className={classes.ellipse__style}>
                    </div>
                    <h1 className={classes.title__style}>
                        Проверка сертификата
                    </h1>
                </div>
                :
                <TitleMain text={"Проверка сертификата"} fns={"2vw"} elpSize={"1vw"} />
            }

            <div style={{ display: "flex", justifyContent: "center" }}>
                <div className={classes.searche}>
                    <input
                        placeholder='0000-0000'
                        type="text"
                        value={value}
                        onChange={handleInputChange}
                        maxLength="9"
                    />
                    <img onMouseDown={searchCert} src={require("assets/icon/search.png")} alt="" />
                </div>
            </div>

        </div>
    );
};

export default SearchCertificate;
