import classes from './DropDownList.module.css'


function DropDownItem({ children, width, index, indexStatus = false, ...props }) {

    return (
        <div className={classes.style__input__block} style={{ width: `${width}` }} onClick={() => indexStatus ? props.onClick(children, index) : props.onClick(children)}>
            <div className={classes.style__input__block__text}>
                <h4>{children}</h4>
            </div>
        </div>
    )
}

export default DropDownItem;