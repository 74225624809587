import { useDeviceType } from 'components/hooks/useDeviceType';
import { useCallback } from 'react';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import classes from './DragAndDrop.module.css'

function DragAndDrop({
    dragZoneRef,
    borderStyle,
    borderWidth,
    borderColor,
    blur,
    children,
    borderRadius,
    setDragZoneText,
    textDragZone = "Выбрать файл",
    textOnDragStart = "Давай уже, кидай свой файл...",
    justifyContent = 'center',
    imagePreview = () => ({}),
}) {
    const [oldStyles, setOldStyles] = useState(null);
    const device = useDeviceType()
    const blureContainerRef = useRef(null)

    function onDradStartHandler(event) {
        event.preventDefault()
        if (dragZoneRef?.current) {
            setDragZoneText(textOnDragStart)
            setOldStyles(old => ({ dragZone: dragZoneRef.current, ...old }))
            dragZoneRef.current.style.borderStyle = borderStyle ? borderStyle : "dashed"
            dragZoneRef.current.style.borderWidth = (device === 'desktop' ? 0.053 : 6) * (borderWidth ? borderWidth : 0.106) + 'vw'
            dragZoneRef.current.style.borderColor = borderColor ? borderColor : "gray"
            dragZoneRef.current.style.borderRadius = borderRadius ? borderRadius : "0"
        }
        if (blureContainerRef?.current) {
            setOldStyles(old => ({ mainContainer: blureContainerRef.current.style, ...old }))
            blureContainerRef.current.style.backdropFilter = `blur(${((blur ? blur : 4) * (device === 'desktop' ? 0.053 : 0.2))}vw)`
        }
    }

    function restoringStyles() {
        if (dragZoneRef && dragZoneRef?.current && dragZoneRef?.current?.style) {
            dragZoneRef.current = oldStyles?.dragZone
        }
        if (blureContainerRef && blureContainerRef?.current && blureContainerRef?.current?.style) {
            blureContainerRef.current.style = oldStyles?.mainContainer
        }

    }

    function onDragLeaveHandler(event) {
        event.preventDefault()
        setDragZoneText(textDragZone)
        restoringStyles()
    }


    function onDropHandelr(event) {
        event.preventDefault()
        const file = [...event.dataTransfer.files][0]
        imagePreview(file)
        restoringStyles()
    }

    useEffect(() => {
        dragZoneRef.current.addEventListener('drop', onDropHandelr)
        setDragZoneText(textDragZone)
    }, [])


    const sidebarStatus = useSelector(state => state.sidebar.isActive)

    return (
        <form encType="multipart/form-data" className={classes.wrapper} style={{ justifyContent: justifyContent }}>
            <div className={classes.wrapper__more} style={{ justifyContent: justifyContent, zIndex: sidebarStatus ? 0 : 2 }}
                onDragOver={e => onDradStartHandler(e)}
                onDragStart={e => onDradStartHandler(e)}
                onDragLeave={e => onDragLeaveHandler(e)}
                onDrop={e => onDragLeaveHandler(e)}>
                {children}
            </div>
            <div ref={blureContainerRef} className={classes.blureMainContainer}></div>
        </form>
    );
}

export default DragAndDrop;