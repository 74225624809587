import { useDispatch } from "react-redux";
import InputBlock from "../inputBlock/InputBlock";
import { newName } from "redux/slice/titleSlice";
import classes from "./CertificateValid.module.css"
import { useDeviceType } from "components/hooks/useDeviceType";
import { useEffect, useState } from "react";
import Strings from "values/Strings";
import ProfileImage from "../profile/profileImage/ProfileImage";
import ImageLoader from "../mainPage/imageLoader/ImageLoader";
import Description from "../description/Description";
const CertificateValid = ({ data }, props) => {

    const [image, setImage] = useState(null)
    const device = useDeviceType()
    const [url, setUrl] = useState(null)
    const [photo, setPhoto] = useState(null)
    const [name, setName] = useState("")

    function getPhoto(src, set) {
        fetch(`https://cloud-api.yandex.net/v1/disk/public/resources?public_key=${src}`).then(responseApi => {
            return responseApi.json()
        })
            // убераем из url файла данные об его размере
            .then(data => data.preview?.split("size")[0])
            //  добавляем в url файла свои данные об размере
            .then(responseUrl => `${responseUrl}size=1024x1024`)
            // делаем запрос для получение изображения и ждем пока все пакеты приду
            .then(newUrl => fetch(newUrl)).then(response => response.blob())
            // формируем локалный url для фотографии и добавляем его в хук для дальнейшего отображения
            .then(image => set(URL.createObjectURL(image)))
    }


    useEffect(() => {
        getPhoto(data[0]?.json_build_object?.certificate, setUrl)
        if (data[0]?.json_build_object.status === 1) {
            getPhoto(data[0]?.json_build_object?.photo, setPhoto)
        }
        else {
            let name = ""
            data.forEach(element => {
                name = name + element.json_build_object.fio + " - " + element.json_build_object.mail + "\n"
            });
            setName(name)
        }
    }, [])






    return (
        <div className={classes.certificate}>
            {data[0]?.json_build_object?.status === 1 ?
                <div className={classes.certificate__contentFirst}>

                    <ImageLoader containerClass={classes.temp__img} size="256x256" src={data[0]?.json_build_object?.photo} className={classes.temp__img__style} />

                    {device === "phone"
                        ? <div className={classes.certificate__contentFirst__input}>
                            <InputBlock placeholder={"ФИО"} value={data[0]?.json_build_object.fio} readOnly={true} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                            <InputBlock placeholder={"Пол"} value={data[0]?.json_build_object.gender === 1 ? "мужской" : data[0]?.json_build_object.gender === 2 ? "женский" : "не определен"} readOnly={true} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                            <InputBlock placeholder={"Почта"} value={data[0]?.json_build_object.mail} readOnly={true} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                            <InputBlock placeholder={"Название ивента"} value={data[0]?.json_build_object.occasion} readOnly={true} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                            <InputBlock placeholder={"Место"} value={"Участник"} readOnly={true} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                            <InputBlock placeholder={"Команда"} value={data[0]?.json_build_object.command} readOnly={true} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                            <InputBlock placeholder={"Хэш сертификата"} value={data[0]?.json_build_object.hash} readOnly={true} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                        </div>
                        :
                        <div className={classes.certificate__contentFirst__input}>
                            <InputBlock placeholder={"ФИО"} width={"30"} value={data[0]?.json_build_object.fio} readOnly={true} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                            <InputBlock placeholder={"Пол"} width={"30"} value={data[0]?.json_build_object.gender === 1 ? "мужской" : data[0]?.json_build_object.gender === 2 ? "женский" : "не определен"} readOnly={true} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                            <InputBlock placeholder={"Почта"} width={"30"} value={data[0]?.json_build_object.mail} readOnly={true} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                            <InputBlock placeholder={"Название ивента"} width={"30"} value={data[0]?.json_build_object.occasion} readOnly={true} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                            <InputBlock placeholder={"Место"} width={"30"} value={"Участник"} readOnly={true} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                            <InputBlock placeholder={"Команда"} width={"30"} value={data[0]?.json_build_object.command} readOnly={true} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                            <InputBlock placeholder={"Хэш сертификата"} width={"30"} value={data[0]?.json_build_object.hash} readOnly={true} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                        </div>
                    }
                </div>
                :
                <div className={classes.diploma__secondBlock}>
                    <div className={classes.diploma__secondBlock__img}>
                        {data.map((item, index) => 
                    
                            <ImageLoader key={index} containerClass={classes.temp__img} size="256x256" src={item.json_build_object?.photo} className={classes.temp__img__style} />
                            )}
                    </div>
                    <Description charactesLeftStatus={false}  placeholder={"ФИО"} value={name} readOnly={true} setValue={setName} />
                    <InputBlock placeholder={"Название ивента"} fullWidth={true} value={data[0]?.json_build_object.occasion} readOnly={true} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                    <InputBlock placeholder={"Место"} fullWidth={true} value={data[0]?.json_build_object.result === 2 ? "1 место" : data[0]?.json_build_object.result === 3 ? "2 место" : data[0]?.json_build_object.result === 4 ? "3 место" : "специальный приз"} readOnly={true} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                    <InputBlock placeholder={"Команда"} fullWidth={true} value={data[0]?.json_build_object.command} readOnly={true} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                    <InputBlock placeholder={"Хэш сертификата"} fullWidth={true} value={data[0]?.json_build_object.hash} readOnly={true} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                </div>
            }
            <div className={classes.certificate__contentSecond}>
                <div className={classes.cert__wrapper}>
                    <ImageLoader containerClass={classes.container__style} size="768x768" src={data[0]?.json_build_object?.certificate} className={classes.certificate__contentSecond__img} />
                    {/* <img src={url} className={classes.certificate__contentSecond__img} alt="" /> */}
                </div>
                <a className={classes.certificate__contentSecond__open} target="_blank" href={data[0]?.json_build_object?.certificate}>Открыть сертификат <img src={require("assets/icon/link.png")} alt="open" /></a>
            </div>
        </div>
    );
};

export default CertificateValid;
