import classes from "./StatScreen.module.css"
import { useState, useEffect } from "react";
import "@fontsource/roboto";
import { useFetching } from 'components/hooks/useFetching';
import Strings from 'values/Strings';
import CharForStat from "../charForStat/CharForStat";
import CountdownTimer from "../countdownTimer/CountdownTimer";
import Marquee from "react-fast-marquee";
import AnimatedNumber from "animated-number-react";
import IconWithText from "../iconWithText/IconWithText";
import Tooltip from "rc-tooltip";
import QrGenerator from "components/UI/qrCode/qrGenerator/QrGenerator";
import Edro from "assets/images/companyOriginal/edroL.svg"
import GazI from "assets/images/companyOriginal/gazp.png"
import Art from "assets/images/companyOriginal/artl.png"
import Khmao from "assets/images/companyOriginal/khmao.png"
import GazTr from "assets/images/companyOriginal/energosbitMin.png"
import Neft from "assets/images/companyOriginal/neft.png"
import Prior from "assets/images/companyOriginal/priorit.png"
import Sber from "assets/images/companyOriginal/sberS.png"
import Surgu from "assets/images/companyOriginal/surguL.png"
import Kuchin from "assets/images/companyOriginal/kuchinMin.png"
import Week from "assets/images/companyOriginal/weekMin.png"
import InputBlock from "../inputBlock/InputBlock";
import Command from "assets/images/main/command.png"
import PrizePool from "assets/images/main/prizepool.png"
import Rub from "assets/images/main/rub.png"

import OccasionService from "services/OccasionService";
import { NotificationManager } from "react-notifications";
//? import socketIOClient from 'socket.io-client'
import env from '../../../../package.json'
import { useDispatch } from "react-redux";
import { newName } from "redux/slice/titleSlice";
//? const socket = socketIOClient(`${env.nodeHost}`)


const StatScreen = () => {

    const logo = [Edro, GazI, Art, Khmao, GazTr, Neft, Prior, Sber, Surgu, Kuchin, Week]

    const [occasionList, setOccasionList] = useState([])
    const [currentOccasion, setCurrentOccasion] = useState({})

    const [dataSets, setDataSets] = useState([])


    const [occasions, setOccasions] = useState([])

    const [value, setValue] = useState({ "participant_count_current": 120, "command_count_current": 18, "task_count_current": 10, "prize_fund_current": 150, "finish_time": '2023-10-09T23:02:10' });

    //? const getBySocketIO = (id) => {
    //?     socket.emit('occasionInit', id)
    //?     socket.on('occasionTotalInfoById', (data) => {
    //?         const participantsType = [
    //?             { name: 'Школьники', value: data?.school_current },
    //?             { name: 'Студенты', value: data?.study_current },
    //?         ]
    //?         setDataSets([
    //?             participantsType,
    //?             participantsType,
    //?         ])
    //?         setValue(data)
    //?     })
    //? }

    const [url, setUrl] = useState("")

    const [getCommandCreateUrl, loadingCommandCreateUrl, errorCommandCreateUrl] = useFetching(async (id) => {
        try {
            const res = await OccasionService.getOccasionById(id)
            res && setUrl(res?.qr_code)
        } catch (error) {
            notificationError(error)
        }
    })


    async function changeOcassion(data, index) {
        setCurrentOccasion(occasions[index])
        getCommandCreateUrl(occasions[index]?.id)

        //? getBySocketIO(occasions[index]?.id)

        // const res = await OccasionService.getOccasionTotalInfoById(occasions[index]?.id)
    }

    const notificationError = (error) => {
        if (error.length > 0) {
            NotificationManager.error(JSON.stringify(error), Strings.notificationsErrorTitle, 5000);
        }
    }

    const [fetchingOccasion, loadingFetchingOccasion, errorFetchingOccasion] = useFetching(async () => {
        try {
            const res = await OccasionService.getOccasions()
            setOccasions(res)
            setOccasionList(res.map(item => OccasionService.getTextInputOccasion(item, false)))
        } catch (error) {
            notificationError(error)
        }
    })

    const dispatch = useDispatch()
    let switcher = false

    useEffect(() => {
        dispatch(newName(Strings.infoTvStatTitle))
        //? getBySocketIO()
        fetchingOccasion()
        setInterval(() => {
            switcher = !switcher
            if (switcher) {
                setValue(old => ({
                    ...old,
                    "participant_count_current": old.participant_count_current + 0.000001,
                    "command_count_current": old.command_count_current + 0.000001,
                    "task_count_current": old.task_count_current + 0.000001, "prize_fund_current": old.prize_fund_current + 0.000001,
                }))
            } else {
                setValue(old => ({
                    ...old,
                    "participant_count_current": old.participant_count_current - 0.000001,
                    "command_count_current": old.command_count_current - 0.000001,
                    "task_count_current": old.task_count_current - 0.000001, "prize_fund_current": old.prize_fund_current + 0.000001,
                }))
            }
        }, 25000)
    }, [])

    const formatValue = value => value.toFixed(0);

    return (
        <div style={{ marginBottom: "10vh" }}>
            {!Object.keys(currentOccasion).length > 0 && <div className={classes.first__block__style}>
                <div style={{ display: "flex" }}>
                    <div style={{ marginRight: "1vw" }}>
                        <Tooltip placement="top" trigger={'hover'} overlay={<span style={{ fontSize: "0.7vw", fontFamily: "Roboto" }} className={classes.tooltip__style}>В данном поле,<br />Вы выбираете мероприятие,<br />статистика которого<br />будет отражена</span>}>
                            <IconWithText text={"Ивент"}><img src={require("assets/icon/briefcase.jpg")} /></IconWithText>
                        </Tooltip>
                    </div>
                    <InputBlock width={45} readOnly={true} indexStatus={true} onListStatus={true} list={occasionList} value={OccasionService.getTextInputOccasion(currentOccasion, false)} setValue={changeOcassion} ><img src={require("assets/images/arrow.jpg")} /></InputBlock>
                </div>
            </div>}



            {
                Object.values(currentOccasion)?.length > 0 && <div className={classes.main__style__for__block}>
                    <div className={classes.block__title__style}>
                        <div className={classes.ellipse__style}>
                        </div>
                        <h1 className={classes.title__style}>
                            Статистика мероприятия
                        </h1>
                    </div>

                    <div className={classes.block__info__style}>

                        <div className={classes.block__with__info}>
                            <div className={classes.stat__style}>
                                <h5 className={classes.h5__style}>
                                    Количество
                                </h5>
                                <div className={classes.stat__with__icon}>
                                    <div className={classes.anim__style__text}>
                                        <AnimatedNumber
                                            value={value.participant_count_current}
                                            formatValue={formatValue}
                                            className={classes.h2__style}
                                        />
                                    </div>
                                    <img src={PrizePool} className={classes.img__style} />
                                </div>
                                <h6 className={classes.h6__style}>
                                    участников
                                </h6>
                            </div>

                            <div className={classes.stat__style}>
                                <h5 className={classes.h5__style}>
                                    Количество
                                </h5>
                                <div className={classes.stat__with__icon}>
                                    <div className={classes.anim__style__text}>
                                        <AnimatedNumber
                                            value={value.command_count_current}
                                            formatValue={formatValue}
                                            className={classes.h2__style}
                                        />
                                    </div>
                                    <img src={Command} className={classes.img__style} />
                                </div>
                                <h6 className={classes.h6__style}>
                                    команд
                                </h6>
                            </div>

                            <div className={classes.stat__style}>
                                <h5 className={classes.h5__style}>
                                    Количество
                                </h5>
                                <div className={classes.stat__with__icon}>
                                    <div className={classes.anim__style__text}>
                                        <AnimatedNumber
                                            value={value.task_count_current}
                                            formatValue={formatValue}
                                            className={classes.h2__style}
                                        />
                                    </div>
                                    <img src={Rub} className={classes.img__style} />
                                </div>
                                <h6 className={classes.h6__style}>
                                    кейсов
                                </h6>
                            </div>

                            <div className={classes.stat__style}>
                                <h5 className={classes.h5__style}>
                                    Призовой
                                </h5>
                                <div className={classes.stat__with__icon}>
                                    <div className={classes.anim__style__text}>
                                        <AnimatedNumber
                                            value={value.prize_fund_current}
                                            formatValue={formatValue}
                                            className={classes.h2__style}
                                        />
                                    </div>
                                    <img src={Rub} className={classes.img__style} />
                                </div>
                                <h6 className={classes.h6__style}>
                                    тыс. рублей
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
            }

            {
                Object.values(currentOccasion)?.length > 0 && dataSets.length > 0 && <div className={classes.style__second__block}>
                    <CharForStat dataSets={dataSets} />

                    <div className={classes.scan__qr}>
                        <span className={classes.commandir__scan}>
                            Сканирует капитан команды
                        </span>
                        {url && <QrGenerator url={url} />}
                    </div>

                    <CountdownTimer targetDate={value?.finish_time} />

                </div>
            }



            {
                Object.values(currentOccasion)?.length > 0 && <Marquee speed={50} gradientWidth={"15vw"} gradientColor={"#FFF"} gradient={true}>
                    {logo.map((any, i) => <img key={i} src={any} alt="" style={{ width: "10vw" }} />)}
                </Marquee>
            }
        </div>
    )
}

export default StatScreen