import MarkWork from "components/UI/grade/markWork/MarkWork";
function TeamGradeCheckPoint() {

    return (
        <div>
            <MarkWork />
        </div>
    );
}

export default TeamGradeCheckPoint;

