import Colors from 'values/Colors';
import Strings from 'values/Strings';
import ContentContainer from 'components/UI/contentContainer/ContentContainer';
import classes from './CaseContainer.module.css'

function CaseContainer({ text = '' }) {
    return (
        <ContentContainer>
            <p className={classes.text_description}>
                <span className={classes.red_text} style={{ color: Colors.red }}>{Strings.caseRedText}</span>
                {text}
            </p>
        </ContentContainer>
    );
}

export default CaseContainer;