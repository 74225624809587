import classes from './QrGenerator.module.css'
// import CaseContainer from 'components/UI/caseContainer/CaseContainer';
import { useDispatch } from 'react-redux';
import { changeLoaderStatus } from "redux/slice/loaderSlice"
import React, { useEffect, useRef, useState } from "react";

import QrCode, { getPercent } from './QrCode'
import Notifications from "components/UI/popupNotifications/Notifications";
import { NotificationManager } from 'react-notifications';
import Strings from 'values/Strings';
import { useDeviceType } from 'components/hooks/useDeviceType';


const QrGenerator = ({ url, setUrl, onCopy = true }) => {


    const getPercent = (percent = 25) => {
        const pageScreenWidth = window.screen.width
        const pageScreenHeight = window.screen.height
        const width_percent = (pageScreenWidth * ((pageScreenWidth > 767) ? percent : (percent * 2)) / 100) + 100
        const height_percent = (pageScreenHeight * ((pageScreenHeight > 767) ? percent : (percent * 2)) / 100) + 100
        QrCode.update({
            width: width_percent,
            height: height_percent
        })

        return { width: width_percent, height: height_percent }
    }


    const dispatch = useDispatch()
    const ref = useRef(null);
    const device = useDeviceType()

    useEffect(() => {
        device === 'desktop' && window.addEventListener('resize', () => getPercent())
        dispatch(changeLoaderStatus(false))
        QrCode.append(ref.current);

        const { width, height } = getPercent()
        QrCode.update({
            width: width,
            height: height
        })
    }, []);


    useEffect(() => {
        QrCode.update({
            data: url
        });
    }, [url]);

    const refMainContainer = useRef(null)

    const copyDataInBuffer = () => {
        navigator.clipboard.writeText(url)
        NotificationManager.success(Strings.notificationsCopyData, Strings.notificationsSuccessTitle, 2500);
    }

    return (
        <div className={classes.qrCodeGenerator} ref={refMainContainer}>
            <div className={classes.qrCodeContainer}>
                {onCopy
                    ? <div className={classes.qrCode} ref={ref} onClick={copyDataInBuffer} />
                    : <div className={classes.qrCode} ref={ref} />
                }
            </div>
            <Notifications />
        </div>
    );
}

export default QrGenerator;