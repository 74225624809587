import env from '../../package.json'
import $api from "http";


export default class CategoryParticipantService {

    static getOne = async (id) => {
        const response = await $api.get(`${env.nodeHost}/api/categody-participant/${id}`)
        return response.data
    }
    static getOneByTaskId = async (id) => {
        const response = await $api.get(`${env.nodeHost}/api/categody-participant/task/${id}`)
        return response.data
    }
}