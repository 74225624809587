import classesImage from "./ImageLoader.module.css"
import classes from 'styles/css/DragEndDrop.module.css'
import { useRef, useState, useEffect } from "react";
import NotificationManager from "react-notifications/lib/NotificationManager"
import Strings from "values/Strings";
import { useDeviceType } from "components/hooks/useDeviceType";
import DragAndDropCircleImage from "components/UI/dragAndDropCircleImage/DragAndDropCircleImage";
import DragAndDropPlaceholderCircleImage from "components/UI/dragAndDropCircleImage/DragAndDropPlaceholderCircleImage";



function ImageLoader({ style,
    image, setImage, url,
    dragEndDropImage, setDragEndDropImage
}) {

    const device = useDeviceType()

    const dragZoneRef = useRef()
    const imagePreviewRef = useRef(null)


    // -------------------------------------------------------------------------
    const clearInputData = () => {
        setImage(null)
    }


    function imagePreview(imageFile) {
        const formData = new FormData()
        formData.append("file", imageFile)
        setImage(formData)
        const reader = new FileReader()
        reader.onload = () => {
            imagePreviewRef.current.src = reader.result
            imagePreviewRef.current.style.height = '100%'
        }
        reader.readAsDataURL(imageFile)
        NotificationManager.success(Strings.notificationsAddImage, Strings.notificationsSuccessTitle, 2500);
    }

    function removeImage() {
        setImage(null)
        NotificationManager.success(Strings.notificationsRemoveImage, Strings.notificationsSuccessTitle, 2500);
    }

    return (
        url ? device === "phone" ?
            <div className={classes.li__style}>
                {image
                    ? <div className={classes.center}>
                        <div className={classes.imageContainer} onClick={removeImage}>
                            <img className={classes.image} src={url} ref={imagePreviewRef} />
                        </div>
                    </div>
                    : <DragAndDropCircleImage justifyContent="left" imagePreview={imagePreview} borderStyle={'none'} dragZoneRef={dragZoneRef}
                        setDragZoneText={setDragEndDropImage} textOnDragStart="Изображение" textDragZone={<img src={url} />}>
                        <DragAndDropPlaceholderCircleImage DragEndDropImage={dragEndDropImage} imagePreview={imagePreview}
                            dragZoneRef={dragZoneRef} right={'0vw'} />
                    </DragAndDropCircleImage>
                }
            </div>
            : <div className={classes.li__style}>
                {image
                    ? <div className={classes.center}>
                        <div className={classes.imageContainer} onClick={removeImage}>
                            <img className={classes.image} src={url} ref={imagePreviewRef} />
                        </div>
                    </div>
                    :
                    <div className={classes.center} style={{
                    }}>
                        <DragAndDropCircleImage justifyContent="left" imagePreview={imagePreview} borderStyle={'none'} dragZoneRef={dragZoneRef}
                            setDragZoneText={setDragEndDropImage} textOnDragStart="Изображение" textDragZone={<img src={url} style={style} />}>
                            <DragAndDropPlaceholderCircleImage DragEndDropImage={dragEndDropImage}
                                height={'8vw'} width={'8vw'} top={'-1px'} right={'0vw'} imagePreview={imagePreview} dragZoneRef={dragZoneRef} />
                        </DragAndDropCircleImage>
                    </div>
                }
            </div>
            :
            <div className={classesImage.container}>
                <span className={classesImage.flare}></span>
            </div>
    );
}

export default ImageLoader;