import { createSlice } from "@reduxjs/toolkit";


export const onScrollBarSlice = createSlice({
    name: 'scrollBar',
    initialState: {
        status: false,
    },
    reducers: {
        setStatusScrollBar: (state, action) => { state.status = action.payload },
    }
})

export const { setStatusScrollBar } = onScrollBarSlice.actions
export default onScrollBarSlice.reducer