export default class Image {
    static toBase64 = file => new Promise(async (resolve, reject) => {
        try {
            const response = await fetch(file);
            const blob = await response.blob();
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        } catch (error) {
            reject(error);
        }
    });

    static extractFileNameFromBase64 = (base64Data) => {
        const match = base64Data.match(/^data:(.+);base64,(.+)$/);
        if (match) {
            const mimeType = match[1];
            const extension = mimeType.split('/')[1];
            const uniqueFileName = `file_${crypto.randomUUID()}.${extension}`;
            return uniqueFileName;
        }
        return null;
    };
}