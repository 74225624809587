import React, { useEffect, useRef, useState } from 'react'
import '../Navbar.css';
import navbarAdminRoutes from 'router/navbarAdmin';
import NavbarItem from '../NavbarItem';
import { useDispatch, useSelector } from 'react-redux';
import OAuthService from 'services/OAuthService';
import { useDeviceType } from 'components/hooks/useDeviceType';
import { setSidebarItem } from 'redux/slice/sidebarItemSlice';
import Strings from 'values/Strings';

const NavbarAdmin = () => {

    const [links, setLinks] = useState([])
    const listRef = useRef(null)
    const [itemRef, setItemRef] = useState(null)
    const selectorRef = useRef(null)
    const sidebarStatus = useSelector(state => state.sidebar.isActive)
    const device = useDeviceType()
    const dispatch = useDispatch()

    function animation() {
        if (itemRef?.current) {
            const activeItemNewAnim = itemRef?.current;
            const activeWidthNewAnimHeight = activeItemNewAnim.clientHeight;
            const activeWidthNewAnimWidth = activeItemNewAnim.clientWidth;
            const itemPosNewAnimTop = activeItemNewAnim.offsetTop;
            const itemPosNewAnimLeft = activeItemNewAnim.offsetLeft;
            selectorRef.current.style.top = `${itemPosNewAnimTop}px`
            selectorRef.current.style.left = `${itemPosNewAnimLeft}px`
            selectorRef.current.style.height = `${activeWidthNewAnimHeight}px`
            selectorRef.current.style.width = `${activeWidthNewAnimWidth}px`
        }
    }

    useEffect(() => {
        animation();
        window.addEventListener('resize', function () {
            setTimeout(function () { animation(); }, 500);
        });
    }, [itemRef])

    //убрали, чтобы на мобилке не было возврата на прежнюю позицию 

    useEffect(() => {
        {
            device == "desktop" &&
                setTimeout(function () { animation(); }, 510)
        }
    }, [sidebarStatus])

    useEffect(() => {
        dispatch(setSidebarItem(Strings.sidebarAdminPanel))
    }, [])


    const user = OAuthService.decryptedSync('userData')


    useEffect(() => {
        setLinks(navbarAdminRoutes.map((item, index) => {
            if (item.superUser === false)
                return <NavbarItem setItemRef={setItemRef} key={index} Icon={item.icon} title={item.title} name={item.name} />
            if (item.superUser === true && user?.superUser === true)
                return <NavbarItem setItemRef={setItemRef} key={index} Icon={item.icon} title={item.title} name={item.name} />
        }))
    }, []);



    return (
        <nav style={{ width: "100%" }} className="navbar navbar-expand-lg navbar-mainbg">
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav ml-auto" ref={listRef}>
                    <div className="hori-selector" ref={selectorRef}>
                        <div className="left"></div>
                        <div className="right"></div>
                    </div>
                    {links}
                </ul>
            </div>
        </nav>
    )
}
export default NavbarAdmin;