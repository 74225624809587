import classes from "./InputWithMaskPhone.module.css"
import { useEffect, useState, useRef } from "react";
import DropDownList from "components/UI/dropDownList/DropDownList";
import PhoneInput from 'react-phone-number-input/input'
import "@fontsource/roboto"

const InputWithMaskPhone = ({ children, value, placeholder, setValue, valuePhone, setValuePhone, width = 60, list = [], ...props }) => {
    const [dropDownStatus, setDropDownStatus] = useState(false)

    useEffect(() => {
        (list.length > 0) && (props.refMainContainer.current.onclick = () => setDropDownStatus(false))
    }, [])


    return (
        <div className={classes.select__container} style={{ width: `${width}vw` }}>
            <label className={classes.inputBlock__lable__focus}>{placeholder}</label>
            <div className={classes.style__input__block}>
                <PhoneInput className={classes.InputWithMaskPhone} country="RU" withCountryCallingCode={true} international={true} smartCaret={true} value={valuePhone} onChange={setValuePhone} />
                <div className={classes.style__input__block__image} onClick={() => setDropDownStatus(old => old ? false : true)}>
                    {children}
                </div>
            </div>
            {(list.length > 0) && <DropDownList setValue={setValue} filter={value} isActive={dropDownStatus} list={list} />}
        </div>
    )
}

export default InputWithMaskPhone