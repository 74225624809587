import classes from "./AuthImage.module.css"
import "@fontsource/roboto"
import ImageLoader from "./imageLoader/ImageLoader"


const AuthImage = ({ children, ...props }) => {
    return (
        <div className={classes.profile__image} style={{ width: props.widthContainer, height: props.heightContainer }}>
            <ImageLoader style={props.style} src={props.img} />
        </div >
    )
}

export default AuthImage