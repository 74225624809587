import Strings from "values/Strings";
import classes from "./Loader.module.css"
import "@fontsource/roboto"
import { useSelector } from "react-redux";
import env from '../../../../package.json'
import { useDeviceType } from "components/hooks/useDeviceType";

function Loader({ children, localStatus }) {
    const status = useSelector(state => state.loader.status)
    const sidebarStatus = useSelector(state => state.sidebar.isActive)
    const device = useDeviceType()


    return (
        (status || localStatus) ? (
            <div style={{
                left: `${(device === 'desktop' && sidebarStatus) ? (env.sidebar.width - 2) : 0}vw`,
                width: (device === 'desktop' && sidebarStatus) ? `calc(100% - ${(env.sidebar.width - 2)}vw)` : "100%"
            }}
                className={classes.container}>
                <div className={classes.loadingAnimation}>
                    <span>{Strings.loaderText}</span>
                </div>
            </div>
        ) : children
    );
}

export default Loader;