import env from '../../package.json'
import $api from "http";

export default class QRCodeService {

    static generateQRCode = async (data) => {
        const response = await $api.post(`${env.nodeHost}/api/qr-code/${data.id}`, { tokenType: 'occasionToken' })
        return response.data
    }

}