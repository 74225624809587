import classes from "./CreateNewsPage.module.css"
import "@fontsource/roboto"
import InputBlock from "components/UI/inputBlock/InputBlock"
import { useState, useRef } from 'react'
import Description from "components/UI/description/Description"
import Button from "components/UI/buttons/button/Button"
import Tooltip from "rc-tooltip"
import 'rc-tooltip/assets/bootstrap_white.css';
import { useDeviceType } from "components/hooks/useDeviceType"
import DragAndDrop from "components/UI/dragAndDrop/DragAndDrop"
import DragAndDropPlaceholder from "components/UI/dragAndDrop/DragAndDropPlaceholder"
import Strings from "values/Strings"
import NotificationManager from "react-notifications/lib/NotificationManager"
import Notifications from "components/UI/popupNotifications/Notifications"
import NewsService from "services/NewsService"
import { useFetching } from "components/hooks/useFetching"
import Loader from "components/UI/loader/Loader"
import TitleMain from "components/UI/titleMain/TitleMain";
import Image from "services/ImageService"

const CreateNewsPage = () => {
    const device = useDeviceType()
    const ref = useRef()
    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const [newsLink, setNewsLink] = useState("")

    const [image, setImage] = useState(null)
    const [dragEndDropText, setDragEndDropText] = useState("")
    const dragZoneRef = useRef()
    const imagePreviewRef = useRef(null)


    // -------------------------------------------------------------------------
    const clearInputData = () => {
        setName("")
        setDescription("")
        setNewsLink("")
        setImage(null)
    }

    function imagePreview(imageFile) {
        const reader = new FileReader()
        reader.onload = () => {
            imagePreviewRef.current.src = reader.result
            imagePreviewRef.current.style.height = '100%'
            setImage(reader.result)
        }
        reader.readAsDataURL(imageFile)

        if (!image) {
            setImage(imageFile)
        } else {
            reader.onloadend = () => {
                const base64 = reader.result;
                setImage(base64)
            }
        }
        NotificationManager.success(Strings.notificationsAddImage, Strings.notificationsSuccessTitle, 2500);
    }

    function removeImage() {
        setImage(null)
        NotificationManager.success(Strings.notificationsRemoveImage, Strings.notificationsSuccessTitle, 2500);
    }

    async function getClipboardContents() {
        try {
            const text = await navigator.clipboard.readText();
            setNewsLink(text)
        } catch (err) {
            NotificationManager.warning(`${Strings.notificationsWarningReadBuffer} ${JSON.stringify(err)}`, Strings.notificationsWarningTitle, 5000);
        }
    }
    // ------------------------------------------------------------------------------

    const [createNews, loadingNewsCreate, errorNewsCreate] = useFetching(async () => {
        try {
            if (name.length > 0 && description.length > 0 && newsLink.length > 0 && image !== null) {
                const data = {
                    name, description, newsLink, image, imageName: Image.extractFileNameFromBase64(image)
                }
                await NewsService.createNews(data)
                await NotificationManager.success(Strings.notificationsAddNews, Strings.notificationsSuccessTitle, 2500)
                await clearInputData()
            } else {
                NotificationManager.warning(Strings.notificationsWarningDataEmptyNews, Strings.notificationsSuccessTitle, 2500);
            }
        } catch (error) {
            if (error.length > 0) {
                NotificationManager.error(JSON.stringify(error), Strings.notificationsErrorTitle, 5000);
            }
        }
    })

    return (
        <div ref={ref} className={classes.events__create__style}>
            <Loader>
                <TitleMain text={"Создание новости"} jsc="center" fns="1.5vw" elpSize="0.9vw" />
                <ul>
                    {device === "phone"
                        ? <li>
                            <InputBlock placeholder={"Название"} setValue={setName} value={name} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                        </li>
                        : <li>
                            <InputBlock placeholder={"Название"} setValue={setName} value={name} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                        </li>
                    }
                    {device === "phone"
                        ? <li className={classes.li__style}>
                            <Description value={description} setValue={setDescription} placeholder={"Описание"} />
                        </li>
                        : <li className={classes.li__style}>
                            <Description defaultCharactesLeft={1000} placeholder={"Описание"} value={description} setValue={setDescription} />
                        </li>
                    }
                    {device === "phone"
                        ? <li className={classes.li__style}>
                            <InputBlock value={newsLink} setValue={setNewsLink} onClick={getClipboardContents} placeholder={"Ссылка"} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                        </li>
                        : <li className={classes.li__style}>
                            <InputBlock placeholder={"Ссылка"} value={newsLink} setValue={setNewsLink} onClick={getClipboardContents} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                        </li>
                    }
                    {device === "phone" ?
                        <li className={classes.li__style}>
                            {image
                                ? <div className={classes.center}>
                                    <div className={classes.imageContainer} onClick={removeImage}>
                                        <img className={classes.image} ref={imagePreviewRef} />
                                    </div>
                                </div>
                                : <DragAndDrop justifyContent="left" imagePreview={imagePreview} borderStyle={'none'} dragZoneRef={dragZoneRef}
                                    setDragZoneText={setDragEndDropText} textDragZone="Вставить фото">
                                    <DragAndDropPlaceholder imagePreview={imagePreview} dragZoneRef={dragZoneRef}>{dragEndDropText}</DragAndDropPlaceholder>
                                </DragAndDrop>
                            }
                        </li>
                        : <li className={classes.li__style}>
                            {image
                                ? <div className={classes.center}>
                                    <div className={classes.imageContainer} onClick={removeImage}>
                                        <img className={classes.image} ref={imagePreviewRef} />
                                    </div>
                                </div>
                                :
                                <div className={classes.center}>
                                    <DragAndDrop justifyContent="left" imagePreview={imagePreview} borderStyle={'none'} dragZoneRef={dragZoneRef}
                                        setDragZoneText={setDragEndDropText} textDragZone="Вставить фото">
                                        <DragAndDropPlaceholder height={'15vw'} width={'60vw'} right={'-0.1vw'} imagePreview={imagePreview} dragZoneRef={dragZoneRef}>{dragEndDropText}</DragAndDropPlaceholder>
                                    </DragAndDrop>
                                </div>
                            }
                        </li>}
                </ul>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "end" }}>
                    <Button onClick={createNews}>Создать</Button>
                </div>
            </Loader>
            <Notifications />
        </div >
    )
}

export default CreateNewsPage