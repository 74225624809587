import classes from "./InputBlock.module.css"
import { useContext, useEffect, useRef, useState } from "react";
import DropDownList from "components/UI/dropDownList/DropDownList";
import "@fontsource/roboto"
import { useDeviceType } from "components/hooks/useDeviceType";
import { useSelector } from "react-redux";
import { RefMainContainerContext } from "context";
import { DatePicker } from "@skbkontur/react-ui/cjs/components/DatePicker";
import "./RemoveDatePicker.css"
import TimeInput from "material-ui-time-picker/lib/TimeInput";

const InputBlock = ({
    children, value, setValue, placeholder, width = 60, fullWidth = false, fullWidthOnMobile = true,
    filter = false, list = [], localRef = false, indexStatus = false, readOnly = false, type = "drop-down", cursorPointer = false,
    onClick = () => ({}), backgroundColor = '', onFocus = () => { }, onBlur = () => { }, colorInvert = false, ...props
}) => {
    const [dropDownStatus, setDropDownStatus] = useState(false)
    const sidebarStatus = useSelector(state => state.sidebar.isActive)
    const [zIndex, setZindex] = useState("0")
    const device = useDeviceType()
    const localContainerRef = useRef(null)
    const refLabel = useRef(null)
    const { mainContainerRef } = useContext(RefMainContainerContext)

    useEffect(() => {
        (list.length > 0) && (localRef ? localContainerRef.current.onclick = () => setDropDownStatus(false) : mainContainerRef.current.onclick = () => setDropDownStatus(false))
    }, [])

    useEffect(() => {
        sidebarStatus ? setZindex("0") : setTimeout(() => setZindex("1"), 802)
    }, [sidebarStatus])

    useEffect(() => {
        if (refLabel.current && refLabel.current.className && value) {
            if (value) {
                refLabel.current.className = classes.inputBlock__lable__focus
            }
        }
        // refLabel && refLabel?.current?.className && changeBlureInput()
    }, [refLabel, value])

    function changeFocusInput() {
        onFocus()
        refLabel.current.className = classes.inputBlock__lable__focus
    }

    function changeBlureInput() {
        onBlur()
        if (!value) {
            refLabel.current.className = classes.inputBlock__lable
        }
    }

    function changeDropDownListStatus(event) {
        onClick(event)
        setDropDownStatus(old => !old)
    }

    // const setValueAndClose = (data) => {
    //     console.log('data ', data)
    //     changeDropDownListStatus(old => !old)
    //     setValue(data)
    // }

    const closeAndSetValue = (data, index) => {
        setDropDownStatus(false)
        setValue(data, index)
    }

    function determineType() {
        if (type === 'time') return (
            <div className={classes.select__container} style={{
                width: fullWidth ? '100%' : ((fullWidthOnMobile && device === 'phone') ? '100%' : `${width}vw`),
                zIndex: zIndex,
            }} ref={localContainerRef}>
                <label ref={refLabel} className={classes.inputBlock__lable}>{placeholder}</label>
                <div className={classes.style__input__block} style={{ height: props.heigBlock, zIndex: zIndex }}>
                    <div className={`${classes.style__input} ${classes.time}`}>
                        {device === "phone"
                            ? <TimeInput
                                mode='24h'
                                value={value}
                                onChange={setValue}
                                placeholder={"Время"}
                                className={classes.placeholder__style}
                            />
                            : <TimeInput
                                mode='24h'
                                value={value}
                                onChange={setValue}
                            />
                        }

                        <div className={`${classes.style__input__block__image} ${classes.timeIconCointainer}`} onClick={changeDropDownListStatus}>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        )
        else if (type === 'date') return (
            <div className={classes.select__container} style={{
                width: fullWidth ? '100%' : ((fullWidthOnMobile && device === 'phone') ? '100%' : `${width}vw`),
                zIndex: zIndex,
            }} ref={localContainerRef}>
                <label ref={refLabel} className={classes.inputBlock__lable}>{placeholder}</label>
                <div className={classes.style__input__block} style={{ height: props.heigBlock, zIndex: zIndex }}>
                    <div className={classes.style__inputDate}>
                        {device === "phone"
                            ? <DatePicker placeholder={'Please select a date'} menuAlign="right" value={value} onValueChange={setValue} width='100%' className={`${classes.date}`} size="medium" />

                            : <DatePicker menuAlign="right" value={value} onValueChange={setValue} width='100%' className={`${classes.date}`} size="medium" />
                        }
                    </div>
                </div>
            </div>
        )
        else return (
            <div className={classes.select__container} style={{
                width: fullWidth ? '100%' : ((fullWidthOnMobile && device === 'phone') ? '100%' : `${width}vw`),
                zIndex: zIndex, cursor: cursorPointer ? 'pointer' : "auto",
            }} ref={localContainerRef}>
                <label ref={refLabel} className={classes.inputBlock__lable}>{placeholder}</label>
                <div className={classes.style__input__block} style={{ height: props.heigBlock, zIndex: zIndex, backgroundColor, }}>
                    <input onClick={changeDropDownListStatus} readOnly={readOnly}
                        style={{
                            height: props.heigInt, cursor: cursorPointer ? 'pointer' : "auto", backgroundColor: 'transparent',
                            filter: colorInvert ? 'invert(100%)' : 'invert(0)', color: colorInvert ? backgroundColor : '',
                            fontWeight: colorInvert ? 'bold' : 'normal'
                        }}
                        onFocus={changeFocusInput} onBlur={changeBlureInput}
                        className={classes.style__input} value={value} onChange={e => setValue(e.target.value)} />

                    <div className={classes.style__input__block__image}>
                        {children}
                    </div>
                </div>
                {(list.length > 0) && <DropDownList fullWidth indexStatus={indexStatus} zIndex={zIndex} width={width} setValue={closeAndSetValue} filter={filter ? value : ""} isActive={dropDownStatus} list={list} />}
            </div>
        )
    }




    return (determineType())
}

export default InputBlock