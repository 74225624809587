import { useEffect, useRef, useState } from "react";
import DragAndDrop from "../dragAndDrop/DragAndDrop";
import DragAndDropPlaceholder from "../dragAndDrop/DragAndDropPlaceholder";
import classes from './ImageMouseCoordinates.module.css'

function ImageMouseCoordinates({ imagePreviewRef, setFile, setOriginalImageSize, image = false, setCurrentImage = () => { }, currentImage = false }) {
    const dragZoneRef = useRef()
    const [text, setText] = useState()

    function imagePreview(imageFile) {
        const img = new Image()
        const reader = new FileReader()
        reader.onload = () => {
            img.onload = () => {
                const width = img.width
                const height = img.height
                setOriginalImageSize({ width, height })
            };
            img.src = reader.result
            imagePreviewRef.current.src = reader.result
            imagePreviewRef.current.style.width = '35vw'
            imagePreviewRef.current.style.border = '0.1vw dashed gray'
            setCurrentImage(reader.result);
        }
        reader.readAsDataURL(imageFile)

        if (!image) {
            setFile(imageFile)
        } else {
            reader.onloadend = () => {
                const base64 = reader.result;
                setFile(base64)
            }
        }
    }

    useEffect(() => {
        if (currentImage && image) {
            imagePreviewRef.current.src = currentImage;
            imagePreviewRef.current.style.width = '35vw'
            imagePreviewRef.current.style.border = '0.1vw dashed gray'
        }
    }, [currentImage]);

    return (
        !currentImage ? <DragAndDrop borderStyle={'none'}
            dragZoneRef={dragZoneRef} imagePreview={imagePreview} setDragZoneText={setText} textDragZone="Вставить фото"
        >
            <div className={classes.wrapper}>
                <DragAndDropPlaceholder imagePreview={imagePreview} dragZoneRef={dragZoneRef}>{text}</DragAndDropPlaceholder>
            </div>
        </DragAndDrop> : <></>
    );
}

export default ImageMouseCoordinates;