import classes from "./FirstBlockMain.module.css"
import "@fontsource/roboto"
import { useRef } from 'react'
import Loader from "components/UI/loader/Loader"
import animation from 'assets/json/mainAnim.json'
import imagesWin from "assets/images/createAndWin.png"
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import { useDeviceType } from "components/hooks/useDeviceType"
import Animation from 'assets/json/mainAnim2.json';
import imagesWinMob from "assets/images/createAndWinMobile.png"
const FirstBlockMain = () => {


    const targetBlockRef = useRef(null)
    const device = useDeviceType()

    const scrollToTargetBlock = () => {
        targetBlockRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
        })
    }

    return (
        <Loader>
            {device === "phone" ?
                <div>
                    < div className={classes.style__first__block}>
                        <div className={classes.block__with__data}>
                            <img className={classes.style__images} src={imagesWinMob} alt="" />
                            <p className={classes.text__style}>Мероприятия - инновационная веб-платформа, специализирующаяся на организации и проведении хакатонов. Cервис предоставляет полный спектр инструментов для участников и организаторов, обеспечивая эффективное взаимодействие и креативное решение задач.</p>
                        </div>
                    </div>
                    <div className={classes.container__with__icon}>
                        <div style={{ width: '50vw', }} onClick={scrollToTargetBlock} ref={targetBlockRef}>
                            <Player
                                autoplay
                                loop
                                src={Animation}
                                className={classes.arrow__style}
                            >
                                <Controls visible={false} buttons={[]} />
                            </Player>
                        </div>
                    </div>
                </div>
                :
                <div>
                    < div className={classes.style__first__block}>
                        <div className={classes.block__with__data}>
                            <img className={classes.style__images} src={imagesWin} alt="" />
                            <p className={classes.text__style}>Мероприятия - инновационная веб-платформа, специализирующаяся на организации и проведении хакатонов. Cервис предоставляет полный спектр инструментов для участников и организаторов, обеспечивая эффективное взаимодействие и креативное решение задач.</p>
                        </div>
                        <div className={classes.block__with__data}>
                            <Player
                                autoplay
                                loop
                                src={animation}
                                style={{ height: '90%', width: '90%' }}
                            >
                                <Controls visible={false} buttons={[]} />
                            </Player>
                        </div>
                    </div>

                    <div className={classes.container__with__icon}>
                        <div style={{ width: '10vw' }} onClick={scrollToTargetBlock} ref={targetBlockRef}>
                            <Player
                                autoplay
                                loop
                                src={Animation}
                                style={{ height: '10vw', width: '10vw', marginTop: "1vw" }}
                            >
                                <Controls visible={false} buttons={[]} />
                            </Player>
                        </div>
                    </div>
                </div>
            }
        </Loader>
    )
}

export default FirstBlockMain