const OccasionStatus = {
    first: {
        id: 1,
        description: 'Запланировано',
    },
    second: {
        id: 2,
        description: 'В процессе',
    },
    third: {
        id: 3,
        description: 'Завершено',
    }
}
export const getOccasionStatusDescriptionList = () => Object.values(OccasionStatus).map(item => item.description)
export const getOccasionStatusDescriptionByStatusId = (id) => {
    const res = Object.values(OccasionStatus).find(item => item.id === id)
    if (res !== undefined) {
        return res
    } else return ''
}
export const getOccasionStatusByDescription = (description) => Object.values(OccasionStatus).find(item => item.description === description)
export default OccasionStatus

