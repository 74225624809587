// import axios from "axios";
import env from '../../package.json'
import $api from "http";


export default class TeamRoomService {


    static getTeamRoom = async (id) => {
        const response = await $api.get(`${env.nodeHost}/api/team-room/${id}`)
        return response.data
    }

    static updateTeamRoom = async (data) => {
        const formData = new FormData()
        formData.append("id", data.id)
        formData.append("file", data.presentation)
        formData.append("additional_material", data.additional_material)
        const response = await $api.put(`${env.nodeHost}/api/team-room/`, formData, { ...env.axiosConfig.data, withCredentials: true })
        return response.data
    }


    static updateTaskTeamRoom = async (data) => {
        const response = await $api.put(`${env.nodeHost}/api/team-room/task`, data)
        return response.data
    }

    static getLeaveCommand = async (id) => {
        const response = await $api.get(`${env.nodeHost}/api/team-room/leave/${id}`)
        return response.data
    }

    static getChoiceTask = async (id) => {
        const response = await $api.get(`${env.nodeHost}/api/team-room/task/${id}`)
        return response.data
    }

}