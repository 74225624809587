import classes from "./Case.module.css"
import "@fontsource/roboto"
import TitleMain from "components/UI/titleMain/TitleMain"
import NotificationManager from 'react-notifications/lib/NotificationManager';
import Strings from 'values/Strings';

const Case = ({ children, index, ...props }) => {

    const copyValueInBiffer = (text) => {
        navigator.clipboard.writeText(text)
        NotificationManager.success(Strings.notificationsCopyData, Strings.notificationsSuccessTitle, 2500)
    }

    return (

        <div className={classes.case__style}>
            <div className={classes.case__title}>
                <TitleMain text={`Кейс №${index + 1}`} />
            </div>

            {/* <h3>Кейс №{index + 1}</h3> */}
            <div className={classes.main__wrapps}>
                <div className={classes.first__block}>
                    <ul>
                        {props.title &&
                            <li>
                                <img src={require("assets/icon/rocket.jpg")} />
                                <span>Название: <span className={classes.new__style__props}>{props.title}</span></span>
                            </li>
                        }
                        {props.category &&
                            <li>
                                <img src={require("assets/icon/crown.jpg")} />
                                <span>Категория: <span className={classes.new__style__props}>{props.category}</span></span>
                            </li>
                        }
                        {props.prize &&
                            <li>
                                <img src={require("assets/icon/trophy.jpg")} />
                                <span>Приз: <span className={classes.new__style__props}>{props.prize}</span></span>
                            </li>
                        }
                        {props.presentation &&
                            <li><img src={require("assets/icon/desktop.jpg")} />
                                <span>Презентация: <a href={props.presentation} target="blank"><span className={classes.new__style__props}><b>Жми</b></span></a></span>
                            </li>
                        }
                        {props.additional_material &&
                            <li><img src={require("assets/icon/book-stack.jpg")} />
                                <span>Доп. материал: <a href={props.additional_material} target="blank"><span className={classes.new__style__props}><b>Жми</b></span></a></span>
                            </li>
                        }
                        {props.name &&
                            <li><img src={require("assets/icon/man.jpg")} />
                                <span>Ментор: <span className={classes.new__style__props}>{props.name}</span></span>
                            </li>
                        }

                        <li><img src={require("assets/icon/smartphone.jpg")} />
                            <span>Контакты: {props.contact_tg && <a href={`https://t.me/${props.contact_tg}`} target="blank"><span className={classes.new__style__props}><b>Телеграм</b></span></a>} {props.email && props.contact_tg && '/'}  {props.email && <a onClick={() => copyValueInBiffer(props.email)}><span className={classes.new__style__props} style={{ cursor: "pointer" }}><b>Почта</b></span></a>}</span>
                        </li>
                    </ul>
                </div>
                <div className={classes.line__block}><span></span></div>
                <div className={classes.second__block}>
                    <div className={classes.description__block__style}>
                        <div className={classes.title__block}>
                            <img src={require("assets/icon/parchment.jpg")} />
                            <span>Описание</span>
                        </div>
                        <span className={classes.text__span__style}>
                            {props.specification}
                        </span>
                    </div>

                    <div className={classes.expected_result}>
                        <div className={classes.title__block}>
                            <img src={require("assets/icon/results.jpg")} />
                            <span>Ожидаемый результат</span>
                        </div>
                        <span className={classes.text__span__style}>
                            {props.expected_result}
                        </span>
                    </div>
                </div>
            </div >
        </div >

    )
}

export default Case