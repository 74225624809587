import "styles/css/info-tv.css"
import { PieChart, Pie, ResponsiveContainer, Sector, Cell } from 'recharts';
import React, { useState, useEffect } from 'react';
import classes from "./CharForStat.module.css"

const COLORS = ['var(--main-color)', 'var(--hover-color)'];

const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + (window.innerWidth / 200 * 1.5)) * cos;
    const sy = cy + (outerRadius + (window.innerWidth / 200 * 1.5)) * sin;
    const mx = cx + (outerRadius + (window.innerWidth / 120 * 4.2)) * cos;
    const my = cy + (outerRadius + (window.innerWidth / 120 * 4.2)) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * (window.innerWidth / 150);
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';


    return (
        <g>
            <text x={cx} y={cy} dy={'2%'} textAnchor="middle" fill={fill} style={{ fontSize: "1.5vw" }}>
                {payload.name}
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + (window.innerWidth / 150)}
                outerRadius={outerRadius + (window.innerWidth / 200 * 1.9)}
                fill={fill}
            />
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
            <circle cx={ex} cy={ey} r={'1%'} fill={fill} stroke="none" />
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333" style={{ fontSize: "1vw", fontWeight: 900 }}>{`Кол-во ${value}`}</text>
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={'4%'} textAnchor={textAnchor} fill="#999" style={{ fontSize: "0.8vw" }}>
                {`(Соотношение ${(percent * 100).toFixed(2)}%)`}
            </text>
        </g>
    );
};

const CharForStat = ({ dataSets = [] }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [data, setData] = useState(dataSets[0]);

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveIndex((prevIndex) => (prevIndex + 1) % dataSets.length);
        }, 4000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    useEffect(() => {
        setData(dataSets[activeIndex]);
    }, [activeIndex]);

    return (
        <div className={classes.style__chart}>
            <ResponsiveContainer>
                <PieChart >
                    <Pie
                        activeIndex={activeIndex}
                        activeShape={renderActiveShape}
                        data={data}
                        cx="50%"
                        cy="50%"
                        innerRadius={"35%"}
                        outerRadius={"45%"}
                        fill="#8884d8"
                        dataKey="value"
                    >
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
        </div>
    );
};

export default CharForStat;
