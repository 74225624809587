import Colors from "values/Colors";


function Border({ top = false, right = false, bottom = false, left = false, borderWidth = 3, borderColor = Colors.blue2, borderStyle = 'solid', borderRadius = 8.5 }) {

    return (
        <div style={{
            borderStyle: borderStyle,
            borderTopWidth: `${top ? borderWidth : 0}vw`,
            borderRightWidth: `${right ? borderWidth : 0}vw`,
            borderLeftWidth: `${left ? borderWidth : 0}vw`,
            borderBottomWidth: `${bottom ? borderWidth : 0}vw`,
            borderColor: borderColor,
            borderTopLeftRadius: `${(top && left) ? borderRadius : 0}vw`,
            borderTopRightRadius: `${(top && right) ? borderRadius : 0}vw`,
            borderBottomLeftRadius: `${(bottom && left) ? borderRadius : 0}vw`,
            borderBottomRightRadius: `${(bottom && right) ? borderRadius : 0}vw`,
            flex: 0.3
        }}></div>
    )
}

export default Border;