import { useDispatch } from "react-redux";
import { setStatusScrollBar } from "redux/slice/onScrollBarSlice";
import CreateOccasions from 'components/UI/create/createOccasion/CreateOccasion'
import { useEffect, useState } from "react";
import 'styles/css/create-events.css'
import { useFetching } from "components/hooks/useFetching";
import OccasionService from "services/OccasionService";
import Loader from "components/UI/loader/Loader";
import Strings from "values/Strings";
import { NotificationManager } from "react-notifications";
import Notifications from "components/UI/popupNotifications/Notifications";

function CreateOccasionPage() {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setStatusScrollBar(window.scrollbars.visible))
    }, [])


    const [createOccasion, loadingOccasionCreate, errorOccasionCreate] = useFetching(async (data, clearInputData) => {
        try {

            let countFilledLines = Object.values(data).filter(item => item.length > 0).length;
            ((Object.values(data?.desk).filter(item => item.length > 0)).length >= 1) && (countFilledLines++)
            if (countFilledLines.length < 10) {
                NotificationManager.warning(Strings.notificationsWarningDataEmptyNews, Strings.notificationsSuccessTitle, 2500);
            } else {
                await OccasionService.createOccasion(data)
                clearInputData()
                NotificationManager.success(Strings.notificationsDataUpdate, Strings.notificationsSuccessTitle, 2500);
            }
        } catch (error) {
            if (error.length > 0) {
                NotificationManager.error(JSON.stringify(error), Strings.notificationsErrorTitle, 5000);
            }
        }
    })

    return (
        <Loader >
            <div className="create-event-page-container create-events-upload-page-container" >
                <CreateOccasions createOccasion={createOccasion} />
                <Notifications />
            </div>
        </Loader>
    );
}

export default CreateOccasionPage;