const Part = {
    'participant': {
        id: 1,
        description: 'участник',
    },
    'team_captain': {
        id: 2,
        description: 'капитан команды',
    },
    'mentor': {
        id: 3,
        description: 'ментор',
    },
    'jury': {
        id: 4,
        description: 'жюри',
    },
    'admin': {
        id: 5,
        description: 'администратор',
    }
}

export const getPartByDescription = (description) => Object.values(Part).find(item => item.description === description)
export default Part