import axios from 'axios';
import env from '../../package.json'
// import $api from "http";


export default class RoleService {
    static getRoles = async () => {
        const response = await axios.get(`${env.nodeHost}/api/role`)
        return response.data
    }

}