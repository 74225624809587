import AuthBlock from "components/UI/authBlock/AuthBlock";
import env from '../../../package.json'

function OAuth({ setTokenInfo, setUserInfo }) {
    const yandexOAuth = () => {
        const urlResponseOauth = `https://oauth.yandex.ru/authorize?response_type=code&client_id=${env.yandexAPI.clientID}&redirect_uri=${env.redirectUri}`
        window.open(urlResponseOauth, '_self')
    }


    return (
        <AuthBlock setTokenInfo={setTokenInfo} setUserInfo={setUserInfo}
            yandexOAuth={yandexOAuth} />
    );
}

export default OAuth;