const Colors = {
    white: "#FFFFFF",
    gray: "#808080",
    blue: "#81a8ec",
    red: "#FF3636",
    gray2: "#BCBCBC",
    blue2: "#4783ed",
    black: "#242424",
    violet: '#752c5b',
    markRed: "#bd362f",
    markOrange: "#f89406",
    markYellow: "#FCD100",
    markGreen: "#51a351",
}

export default Colors