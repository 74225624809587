import { createSlice } from "@reduxjs/toolkit";
import Strings from "values/Strings.js";

export const sidebarItemSlice = createSlice({
    name: 'sidebarItem',
    initialState: {
        item: Strings.sidebarMain,
    },
    reducers: {
        setSidebarItem: (state, action) => { state.item = action.payload },
    }
})

export const { setSidebarItem } = sidebarItemSlice.actions
export default sidebarItemSlice.reducer