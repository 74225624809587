import classes from "./CircleButtonMark.module.css"
import "@fontsource/roboto"


const CircleButtonMark = ({ children, onClick, ...props }) => {

    return (
        <div className={classes.mark__button__style} style={{ backgroundColor: props.color }} onClick={onClick}>
            <span>{props.text}</span>
        </div >
    )
}

export default CircleButtonMark