import { createSlice } from "@reduxjs/toolkit";

export const loaderSlice = createSlice({
    name: 'loader',
    initialState: {
        status: false,
    },
    reducers: {
        changeLoaderStatus: (state, action) => { state.status = action.payload }
    }
})

export const { changeLoaderStatus } = loaderSlice.actions
export default loaderSlice.reducer