import classes from "./CaseChecker.module.css"
import "@fontsource/roboto"
import Toggle from "react-toggle"
import { useEffect, useState } from "react"
import './styles.css'
import Description from "../description/Description"
import Button from "../buttons/button/Button"
import TitleMain from "../titleMain/TitleMain"

const CaseChecker = ({ title = '', category = '', prize = '', presentation = '', additional_material = '', surname = '', name = '', patronymic = '',
    contact_tg = '', email = '', description = '', expected_result = '', comment = '', status = 0, id = 0, statusList = [], updateCase = () => { }, children, ...props }) => {

    const [myComment, setMyComment] = useState(comment)
    const [selectItem, setSelectItem] = useState(`${status}-${id}`)

    const onSave = () => {
        updateCase(id, { status: selectItem.split('-')[0], comment: myComment })
    }

    const changeSelectItem = ($id) => {
        setSelectItem(`${$id}-${id}`)
        if ($id !== 4) {
            updateCase(id, { status: $id })
        }
    }
    return (
        <div className={classes.main__block}>
            <div className={classes.case__style} >
                <TitleMain text={`Кейс №${id}`} jsc="center" fns="1.5vw" elpSize="0.9vw" />
                <div className={classes.main__wrapps}>
                    <div className={classes.first__block}>
                        <ul>
                            <li>
                                <img src={require("assets/icon/rocket.jpg")} />
                                <span>Название: <span className={classes.new__style__props}>{title}</span></span>
                            </li>
                            <li><img src={require("assets/icon/crown.jpg")} />
                                <span>Категория: <span className={classes.new__style__props}>{category}</span></span></li>
                            <li><img src={require("assets/icon/trophy.jpg")} />
                                <span>Приз: <span className={classes.new__style__props}>{prize}</span></span></li>
                            <li><img src={require("assets/icon/desktop.jpg")} />
                                <span>Презентация: <a href={`${presentation}`}><span className={classes.new__style__props}> Жми</span></a></span></li>
                            <li><img src={require("assets/icon/book-stack.jpg")} />
                                <span>Доп. материал:<a href={`${additional_material}`}><span className={classes.new__style__props}> Жми</span></a></span></li>
                            <li><img src={require("assets/icon/man.jpg")} />
                                <span>Ментора: <span className={classes.new__style__props}>{surname} {name} {patronymic}</span></span></li>
                            <li><img src={require("assets/icon/smartphone.jpg")} />
                                <span>Контакты: <a href={`https://t.me/${contact_tg}`}><span className={classes.new__style__props}>{contact_tg}</span></a> / <a href={`mailto:${email}`}><span className={classes.new__style__props}>{email}</span></a></span></li>
                        </ul>
                    </div>
                    <div className={classes.line__block}><span></span></div>
                    <div className={classes.second__block}>
                        <div className={classes.description__block__style}>
                            <div className={classes.title__block}>
                                <img src={require("assets/icon/parchment.jpg")} />
                                <span>Описание</span>
                            </div>
                            <span className={classes.text__span__style}>
                                {description}
                            </span>
                        </div>

                        <div className={classes.expected_result}>
                            <div className={classes.title__block}>
                                <img src={require("assets/icon/results.jpg")} />
                                <span>Ожидаемый результат</span>
                            </div>
                            <span className={classes.text__span__style}>
                                {expected_result}
                            </span>
                        </div>
                    </div>
                </div>
            </div >
            <div className={classes.caseChecker__statusBtn}>

                <div className={classes.input__block}>
                    {statusList && statusList.map((item) => (
                        <div className={classes.form_radio_btn} key={item.id}>
                            <input
                                id={`radioB-${item.id}-${id}`}
                                type="radio"
                                name="radioB"
                                value={item.id}
                                onChange={() => changeSelectItem(item.id)}
                            />
                            <label htmlFor={`radioB-${item.id}-${id}`} style={{ borderColor: selectItem === `${item.id}-${id}` && item.color }}>{item.description}</label>
                        </div>
                    ))}
                </div>
                {selectItem === `${4}-${id}` &&
                    <div className={classes.caseChecker__comment}>
                        <Description value={myComment} setValue={setMyComment} placeholder={"Комментарий"} minH="8vw" />
                        <Button onClick={onSave}>Сохранить</Button>
                    </div>
                }
            </div>
        </div>
    )
}

export default CaseChecker