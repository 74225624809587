import axios from "axios";
import env from '../../package.json'
import $api from "http";


export default class ParticipantService {

    static updateParticipant = async (data) => {
        const response = await axios.put(`${env.nodeHost}/api/participant`, data)
        return response.data
    }

    static createParticipant = async (data) => {
        const response = await $api.post(`/api/participant`, data)
        return response.data
    }

    static preRegistration = async (data) => {
        const response = await $api.post(`/api/participant/pre-registration`, data)
        return response.data
    }

    static checkParticipation = async (token) => {
        const response = await $api.post(`/api/participant/check`, { token })
        return response.data
    }

    static getParticipantByLevel = async () => {
        const response = await $api.get(`${env.nodeHost}/api/participant/level`)
        return response.data
    }

    static getParticipants = async () => {
        const response = await $api.get(`${env.nodeHost}/api/participant`)
        return response.data
    }

    static getParticipationByCommand = async (command) => {
        const response = await $api.get(`${env.nodeHost}/api/participant/command/${command}`)
        return response.data
    }

    static updateParticipantPart = async (participantId, partId) => {
        const response = await axios.put(`${env.nodeHost}/api/participant/${participantId}/${partId}`)
        return response.data
    }

    static getParticipantCirtif = async (id) => {
        const response = await $api.get(`/api/participant/certificate/${id}`)
        return response.data
    }

    static getTextInputParticipant = (item) => Object.keys(item).length > 0 ? `${item.name} - ${item.part}` : ''

    // static getDataForParticipantCertification = (formData, eventName, x_fio, y_fio, fio_list, image) => {
    //     fio_list.map(fio => {
    //         formData.append("status", 1)
    //         formData.append("image", image)
    //         formData.append("eventName", `${eventName}_participant`)
    //         formData.append("x_fio", x_fio)
    //         formData.append("y_fio", y_fio)
    //         formData.append("fio", fio)
    //     })
    // }

    //    src = [{ "status": 1, "eventName": "Хакатон Digital 3", "x_fio": 600, "y_fio": 1400, "fio": "Тунян 4 Э.Г.", "image": "GramotiPRO.png" }, 
    // { "status": 1, "eventName": "Хакатон Digital 3", "x_fio": 600, "y_fio": 1400, "fio": "Сазиков 5 Р.С.", "image": "GramotiPRO.png" },             
    // { "status": 1, "eventName": "Хакатон Digital 3", "x_fio": 600, "y_fio": 1400, "fio": "Галустян 6 З.М.", "image": "GramotiPRO.png" }]
}