import { useEffect } from "react";
import { useState } from "react";
import classes from "./ImageLoader.module.css"

function ImageLoader({ src, size="1024x1024", className = "", containerClass = "" }) {

    const [url, setUrl] = useState("")


    useEffect(() => {
        // делаем запрос в яндекс и получеаем данные об файле
        fetch(`https://cloud-api.yandex.net/v1/disk/public/resources?public_key=${src}`).then(responseApi => {
            return responseApi.json()
        })
            // убераем из url файла данные об его размере
            .then(data => data.preview?.split("size")[0])
            //  добавляем в url файла свои данные об размере
            .then(responseUrl => `${responseUrl}size=${size}`)
            // делаем запрос для получение изображения и ждем пока все пакеты приду
            .then(newUrl => fetch(newUrl)).then(response => response.blob())
            // формируем локалный url для фотографии и добавляем его в хук для дальнейшего отображения
            .then(image => setUrl(URL.createObjectURL(image)))
    }, [])

    // useEffect(() => {
    //     // делаем запрос в яндекс и получеаем данные об файле
    //     fetch(`https://cloud-api.yandex.net/v1/disk/public/resources?public_key=${src}`).then(responseApi => responseApi.json())
    //         // убераем из url файла данные об его размере
    //         .then(data => data.preview?.split("size")[0])
    //         //  добавляем в url файла свои данные об размере
    //         .then(responseUrl => `${responseUrl}size=1024x1024`)
    //         // делаем запрос для получение изображения и ждем пока все пакеты приду
    //         .then(newUrl => fetch(newUrl)).then(response => response.blob())
    //         // формируем локалный url для фотографии и добавляем его в хук для дальнейшего отображения
    //         .then(image => {
    //             setUrl(URL.createObjectURL(image))
    //         })
    // }, [])

    return (
        url ?
            <img src={url} className={className}/>
            :
            <div className={`${classes.container} ${containerClass}`}>
                <span className={classes.flare}></span>
            </div>
    );
}

export default ImageLoader;