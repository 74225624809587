import classes from "./SelectCase.module.css"
import Button from "components/UI/buttons/button/Button"
import Loader from "components/UI/loader/Loader"
import { useNavigate } from "react-router-dom"
import { privateRoutNames } from "router"
import { NotificationManager } from "react-notifications"
import Strings from "values/Strings"

const SelectCase = ({ taskStatus = [], title = '', date = '', status = 'under_consideration', description = '', task = null, ...props }) => {

    const navigate = useNavigate()

    const notificationWarning = (error) => {
        if (error.length > 0) {
            NotificationManager.warning(error, Strings.notificationsWarningTitle, 5000);
        }
    }

    function clickChangeCase() {
        if (status === 'rejected') {
            notificationWarning("Ваша заявка отклонена, создайте новый запрос")
        } else {
            navigate(`/private/${privateRoutNames.createCase}/${task}`)
        }
    }


    return (
        <Loader>
            <div className={classes.selectCase}>
                <div className={classes.selectCase__nameCase}>
                    <span>{title}</span>
                </div>
                <span className={classes.selectCase__line}></span>
                <div className={classes.selectCase__dateCase}>
                    <span>{date}</span>
                </div>
                <span className={classes.selectCase__line}></span>
                <div className={classes.selectCase__statusCase}>
                    <span style={{ color: taskStatus.filter(item => item.name === status)[0].color }}>{description}</span>
                </div>
                <div className={classes.selectCase__buttonEdit}>
                    <Button onClick={clickChangeCase}>Изменить</Button>
                </div>
            </div >
        </Loader >
    )
}

export default SelectCase