import classes from "./IconWithText.module.css"
import Colors from "values/Colors"
import "@fontsource/roboto"

const IconWithText = ({ children, ...props }) => {
    return (
        <div className={classes.box__style} {...props}>
            {children}
            <span style={{ fontSize: props.size }}>{props.text}</span>
        </div >

    )
}

export default IconWithText