import axios from "axios"
import Strings from "values/Strings"
import env from '../../package.json'
import $api from "http"

class NewsService {

    static updateNews = async (data) => {
        const response = await axios.put(`${env.nodeHost}/api/news`, data)
        return response.data
    }

    static createNews = async (data) => {
        const response = await $api.post(`/api/news`, data)
        return response.data
    }

    static getNews = async () => {
        const response = await axios.get(`${env.nodeHost}/api/news`)
        return response.data
    }

    static getPublishNews = async () => {
        const response = await axios.get(`${env.nodeHost}/api/news/publish`)
        return response.data
    }

    static getStatusTextNews = (status) => `${status
        ? Strings.administrationPage.currentNews.active
        : Strings.administrationPage.currentNews.inActive
        }`
    static getStatusTextButtonNews = (status) => `${status
        ? Strings.administrationPage.currentNewsButton.inActive
        : Strings.administrationPage.currentNewsButton.active
        }`

    static getTextInputNews = (item) => Object.keys(item).length > 0 ? `${item.name} - ${this.getStatusTextNews(item.status)}` : ''

}

export default NewsService