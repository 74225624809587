import TeamGrade from "components/UI/grade/teamGrade/TeamGrade";
import { useFetching } from "components/hooks/useFetching";
import { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import RatingJuryService from "services/JuryService";
import OccasionService from "services/OccasionService";
import Strings from "values/Strings";

function TeamGradePage() {
    const [resultList, setResultList] = useState({});
    const [occasions, setOccasions] = useState([])
    const [occasionList, setOccasionList] = useState([])


    function replaceNullWithZero(obj) {
        for (let key in obj) {
            if (obj[key] === null) {
                obj[key] = 0;
            } else if (typeof obj[key] === 'object') {
                replaceNullWithZero(obj[key]);
            }
        }
    }

    const notificationError = (error) => {
        if (error.length > 0) {
            NotificationManager.error(JSON.stringify(error), Strings.notificationsErrorTitle, 5000);
        }
    }

    const [fetchingOccasion, loadingFetchingOccasion, errorFetchingOccasion] = useFetching(async () => {
        try {
            const res = await OccasionService.getOccasions()
            setOccasions(res)
            setOccasionList(res.map(item => item.name))
        } catch (error) {
            notificationError(error)
        }
    })

    const [fetchingRatings, loadingFetchingRatings, errorFetchingRatings] = useFetching(async (id) => {
        try {
            const res = await RatingJuryService.getFinnalRatings(id)
            replaceNullWithZero(res)
            setResultList(res)
        } catch (error) {
            notificationError(error)
        }
    })


    const [updateRatings, loadingUpdateRatings, errorUpdateRatings] = useFetching(async ({ data, category }) => {
        try {
            // ищем из массива тот элемент в котором есть нужная нам категория
            data = data.find(item => Object.keys(item)[0] === category)
            // получаем ту категорию, в которой были изменения
            const categoryArray = data[category]
            await RatingJuryService.updateRatingJuryPosition(categoryArray)
            NotificationManager.success(Strings.notificationsDataUpdate, Strings.notificationsSuccessTitle, 2500);
        } catch (error) {
            notificationError(error)
        }
    })

    useEffect(() => {
        fetchingOccasion()
    }, []);


    return (
        <TeamGrade
            fetchingRatings={fetchingRatings}
            occasions={occasions}
            occasionList={occasionList}
            resultList={resultList}
            updateRatings={updateRatings}
            notificationError={notificationError}
        />
    );
}

export default TeamGradePage;