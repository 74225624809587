import classesImage from "./ProfileImage.module.css"
import "@fontsource/roboto"
import ImageLoader from "./imageLoader/ImageLoader"

const ProfileImage = ({ children,
    image, setImage, url,
    dragEndDropImage, setDragEndDropImage,
    ...props }) => {

    return (
        <div className={classesImage.profile__image} style={{ width: props.widthContainer, height: props.heightContainer }}>
            <ImageLoader style={props.style}
                image={image} setImage={setImage} url={url}
                dragEndDropImage={dragEndDropImage} setDragEndDropImage={setDragEndDropImage}
            />
        </div >
    )
}

export default ProfileImage