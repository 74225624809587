import axios from "axios";
import Strings from "values/Strings";
import env from '../../package.json'
import { getOccasionStatusDescriptionByStatusId } from "model/occasionStatus";
import $api from "http";


export default class OccasionService {

    static updateOccasion = async (data) => {
        const response = await $api.put(`${env.nodeHost}/api/occasion/status`, data)
        return response.data
    }

    static updateOccasionQrCode = async (id, url) => {
        const response = await $api.put(`${env.nodeHost}/api/occasion/qr-code`, { id, url })
        return response.data
    }

    static updateOccasionTaskStatus = async (data) => {
        const response = await $api.put(`${env.nodeHost}/api/occasion/task`, data)
        return response.data
    }

    static createOccasion = async (data) => {
        const response = await $api.post(`${env.nodeHost}/api/occasion`, data)
        return response.data
    }

    static getOccasionsForMentor = async () => {
        const response = await $api.get(`${env.nodeHost}/api/occasion/for-mentor`)
        return response.data
    }

    static getOccasionById = async (occasion_id) => {
        const response = await axios.get(`${env.nodeHost}/api/occasion/${occasion_id}`)
        return response.data
    }

    static getOccasionTotalInfo = async () => {
        const response = await axios.get(`${env.nodeHost}/api/occasion/total`)
        return response.data
    }

    static getOccasionTotalInfoById = async (id) => {
        const response = await axios.get(`${env.nodeHost}/api/occasion/total/${id}`)
        return response.data
    }

    static getOccasions = async () => {
        const response = await axios.get(`${env.nodeHost}/api/occasion/`)
        return response.data
    }

    static getOccasionsScheduled = async () => {
        const response = await $api.get(`${env.nodeHost}/api/occasion/scheduled`)
        return response.data
    }

    static getAboutOccasions = async (id) => {
        const response = await axios.get(`${env.nodeHost}/api/occasion/about/${id}`)
        return response.data
    }

    static getCurrentOccasions = async () => {
        const response = await axios.get(`${env.nodeHost}/api/occasion/current`)
        return response.data
    }

    static getStatusTextOccasion = (status) => `${status
        ? Strings.administrationPage.currentOccasion.active
        : Strings.administrationPage.currentOccasion.inActive}`

    static getStatusTextTaskOccasion = (status) => `${status
        ? Strings.administrationPage.currentOccasionTask.active
        : Strings.administrationPage.currentOccasionTask.inActive}`


    static getStatusTextButtonOccasion = (status) => `${status
        ? Strings.administrationPage.currentOccasionButton.inActive
        : Strings.administrationPage.currentOccasionButton.active
        }`
    static getStatusTextButtonTaskOccasion = (status) => `${status
        ? Strings.administrationPage.currentOccasionTaskButton.inActive
        : Strings.administrationPage.currentOccasionTaskButton.active
        }`

    // static getTextInputOccasion = (item) => Object.keys(item).length > 0 ? `${item.name} - ${getOccasionStatusDescriptionByStatusId(item.occasion_status).description}` : ''
    static getTextInputOccasion = (item, status = true) => {
        const { description } = getOccasionStatusDescriptionByStatusId(item.occasion_status)
        if (status) {
            return Object.keys(item).length > 0 ? `${item.date} - ${item.name} - ${description}` : ''
        } else {
            return Object.keys(item).length > 0 ? `${item.date} - ${item.name}` : ''
        }
    }
    static getTextInputOccasionTask = (item) => Object.keys(item).length > 0 ? `${item.name} - ${this.getStatusTextTaskOccasion(item.on_task_publish)} ` : ''

    static getTextInputOccasionQRG = (item) => Object.keys(item).length > 0 ? `${item.date} - ${item.name} - ${this.getStatusTextTaskOccasion(item.on_task_publish)} ` : ''
}