import FinalMark from "components/UI/grade/finalMark/FinalMark";
import MarkWork from "components/UI/grade/markWork/MarkWork";

function GradePage() {
    return (
        <div className="grade-container">
            <MarkWork />
            {/* <FinalMark /> */}
        </div>
    );
}

export default GradePage;