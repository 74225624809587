import axios from "axios";
import env from '../../package.json'
import $api from "http";


export default class CommandService {

    static createCommand = async (data) => {
        const response = await $api.post(`/api/command`, data)
        return response.data
    }

    static joinTheCommand = async (data) => {
        const response = await $api.put(`/api/command/join`, data)
        return response.data
    }

    static checkCommand = async () => {
        const response = await $api.get(`/api/command/check`)
        return response.data
    }

    static getCurrentCommands = async () => {
        const response = await $api.get(`/api/command/current`)
        return response.data
    }

    static getCurrentCommandsByOccasionId = async (occasionId) => {
        const response = await $api.get(`/api/command/current/${occasionId}`)
        return response.data
    }

    static getCurrentRatingJury = async () => {
        const response = await $api.get(`/api/rating-jury/jury-grade`)
        return response.data
    }

    static getCommandInfo = async (id) => {
        const response = await $api.get(`/api/command/about-command/${id}`)
        return response.data
    }

    static updateCommandInfo = async (data) => {
        const response = await $api.put(`/api/command/update-info`, data)
        return response.data
    }

    static getColorForCommandList = (item, gradeLengthMax) => {
        const gradesLength = Object.keys(item?.grades)
        const correntValue = gradesLength.length / gradeLengthMax
        return (correntValue < 0.02) ? "#bd362f" : ((0.01 < correntValue) && (correntValue < 0.99)) ? "#f89406" : "#51a351"
    }

    static getCurrentCommandsNamesForList = (listObj, gradeLength) => listObj.map(item => ({ color: this.getColorForCommandList(item, gradeLength), item: `${item.name} – стол №${item.table_number ? item.table_number : 0}` }))
    static getCurrentCommandsNamesForInputList = (listObj) => listObj.map(item => (`${item.name} – стол №${item.table_number ? item.table_number : 0}`))
    static getCurrentCommandsNamesForListDescktop = listObj => listObj.map(item => `${item.name} – стол №${item.table_number ? item.table_number : 0}`)
}