import React from 'react';
import classes from "./Footer.module.css"
import { IoDiamondOutline } from 'react-icons/io5'
import { RiContactsBookLine } from 'react-icons/ri'
import { AiOutlineLink } from 'react-icons/ai'
import { GiBlackball } from 'react-icons/gi'
import { useDeviceType } from 'components/hooks/useDeviceType';
import env from '../../../../package.json'


function Footer() {
    const device = useDeviceType()
    return (

        <div className={classes.main__block__style}>
            {device === "phone"
                ? <div className={classes.first__block__style}>
                    <div className={classes.icon__socity__style}>
                        <a href='https://vk.com/edro_technologies' target="blank" className={classes.icon__style}>
                            <img src={require("assets/icon/vk.png")} />
                        </a>

                        <a href='https://t.me/edro_technologies' target="blank" className={classes.icon__style}>
                            <img src={require("assets/icon/tg.png")} />
                        </a>

                        <a href='https://github.com/tedmon1998' target="blank" className={classes.icon__style}>
                            <img src={require("assets/icon/github.png")} />
                        </a>
                    </div>
                </div>
                : <div className={classes.first__block__style}>
                    <span>Присоединяйтесь к нам в социальных сетях:</span>
                    <div className={classes.icon__socity__style}>
                        <a href='https://vk.com/edro_technologies' target="blank" className={classes.icon__style}>
                            <img src={require("assets/icon/vk.png")} />
                        </a>

                        <a href='https://t.me/edro_technologies' target="blank" className={classes.icon__style}>
                            <img src={require("assets/icon/tg.png")} />
                        </a>

                        <a href='https://github.com/tedmon1998' target="blank" className={classes.icon__style}>
                            <img src={require("assets/icon/github.png")} />
                        </a>
                    </div>
                </div>
            }


            <div className={classes.line__style} />

            <div className={classes.second__block__style}>

                <div className={classes.one__block}>
                    <div className={classes.one__block__in__second}>
                        <GiBlackball className={classes.icon__gun__style} />
                        <span>EDRO Technologies</span>
                    </div>
                    <span className={classes.text__style}>Мы команда разработчиков, занимающаяся созданием автоматизированных систем, которые способны помогать! Данная платформа создана с целью упрощения организации и проведения мероприятий.</span>
                </div>

                <div className={classes.two__block}>
                    <div className={classes.one__block__in__second}>
                        <AiOutlineLink className={classes.icon__gun__style} />
                        <span>Полезные ссылки</span>
                    </div>
                    <ul>
                        <li>
                            <span className={classes.text__style}><a href="https://vk.com/surgu" target="blank">СурГУ</a></span>
                        </li>
                        <li>
                            <span className={classes.text__style}><a href={`${env.nodeHost}/privacy`} target="blank">Политика конфиденциальности</a></span>
                        </li>
                        <li>
                            <span className={classes.text__style}><a href={`${env.nodeHost}/terms`} target="blank">Пользовательское соглашение</a></span>
                        </li>
                    </ul>
                </div>

                <div className={classes.three__block}>
                    <div className={classes.one__block__in__second}>
                        <RiContactsBookLine className={classes.icon__gun__style} />
                        <span>Контакты</span>
                    </div>
                    <ul>
                        <li>
                            <span className={classes.text__style}>г.Сургут, 628416, Россия</span>
                        </li>
                        <li>
                            <span className={classes.text__style}><a href="mailto:support@edro.su" target="blank">support@edro.su</a></span>
                        </li>
                        <li>
                            <span className={classes.text__style}><a href="tel:+79995711031" target="blank">+7 (999) 571 10-31</a></span>
                        </li>
                    </ul>

                </div>

            </div>

            <div className={classes.last__block__style}>
                © 2022 - {new Date().getFullYear()} Все права защищены:
                <a href='https://edro.su/' target="blank">
                    EDRO.SU
                </a>
            </div>
        </div>

    );
}

export default Footer;