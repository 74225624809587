import classes from "./News.module.css"
import "@fontsource/roboto";
import ImageLoader from "../imageLoader/ImageLoader";
import { useDeviceType } from "components/hooks/useDeviceType";
import { FaExternalLinkAlt } from "react-icons/fa";

const News = ({ children, ...props }) => {

    const device = useDeviceType()
    const date = new Date(props.create_time);
    const day = date.getDate();
    const monthNames = [
        "янв", "фев", "мар", "апр", "май", "июн",
        "июл", "авг", "сен", "окт", "ноя", "дек"
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    const hours = date.getHours() + 5;
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const formattedDate = `${day} ${month} ${year} в ${hours}:${minutes}`;

    return (
        <div className={classes.news__style} >
            <div className={classes.news__upper}>
                <div className={classes.news__header}>
                    <a href="https://vk.com/edro_technologies" target="_blank"><img src={require("assets/images/JustEDRO.png")} alt="edro" /></a>
                    <div className={classes.news__header__title}>
                        <a href="https://vk.com/edro_technologies" target="_blank">EDRO TECHNOLOGIES</a>
                        <p>{formattedDate}</p>
                    </div>
                </div>
               <a href={props.news_link} target="_blank"> <FaExternalLinkAlt style={{color: "var(--main-color)"}} size={device === "phone" ? "5vw" : "0.9vw"}/></a>
            </div>

            <div className={classes.first__style_box}>
                <div className={classes.title__style}>
                    <span>{props.name}</span>
                </div>
                <div className={classes.main__text__style}>
                    <span>{props.description}</span>
                </div>
            </div>
            <div className={classes.second__style_box}>
                <div className={classes.img__style}>
                    <ImageLoader src={props.image} />
                </div>
            </div>
        </div>
    )
}

export default News