import classes from "./Map.module.css"
import "@fontsource/roboto";
import TitleMain from "components/UI/titleMain/TitleMain";

const Map = () => {
    return (
        <div className={classes.map__style}>
            <div className={classes.map__title}>
                <div className={classes.map__title_wrapper}>
                    <TitleMain text={"Где нас искать"} jsc="center" />
                </div>
            </div>
            <iframe className={classes.map__style__block} src="https://yandex.ru/map-widget/v1/?um=constructor%3Ad9dd19687035a88b44d65ac069e8e8b611b4d9623350739a317953a324a7f0b6&amp;source=constructor"></iframe>
        </div >
    )
}

export default Map