import React from "react";
import { Navigate, Route, Routes } from "react-router-dom"
import { publickRoutes } from 'router/index'
import AppRouterAdmin from "./AppRouterAdmin"
import AppRouterPrivate from "./AppRouterPrivate"
import AppRouterInfo from "./AppRouterInfo"

const AppRouter = () => {



    return (
        <Routes>
            <Route path="/" element={<Navigate to="/main/info" />} />
            {/* <Route path="*" element={<Navigate to="/nofound" />} /> */}
            <Route path="/logout" element={<Navigate to="/main/info" state={{ logout: true }} />} />
            <Route path="/enter" element={<Navigate to="/main/info" state={{ auth: true }} />} />
            <Route path="/private/*" element={<AppRouterPrivate />} />
            <Route path="/admin/*" element={<AppRouterAdmin />} />
            <Route path="/info/*" element={<AppRouterInfo />} />
            {
                publickRoutes.map(rout =>
                    <Route path={rout.path} element={<rout.component />} key={rout.path} />
                )
            }
        </Routes>
    )
}

export default AppRouter