import UpdateCase from "components/UI/create/updateCase/UpdateCase"
import 'styles/css/work-case.css'


function WorkWithCasePage() {
    return (
        <UpdateCase className="work-case-upload-page-container" />
    );
}

export default WorkWithCasePage;
