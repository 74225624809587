import env from '../../package.json'
import $api from "http";


export default class CheckPointService {

    static getCheckMark = async (id) => {
        const response = await $api.get(`${env.nodeHost}/api/check-point/check-mark/${id}`)
        return response.data
    }

    static getCommandCaseInfo = async (id) => {
        const response = await $api.get(`${env.nodeHost}/api/check-point/check-case/${id}`)
        return response.data
    }

}