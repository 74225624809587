import ProfileOccasion from "components/UI/profile/profileOccasion/ProfileOccasion";
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import Strings from 'values/Strings';
import Loader from 'components/UI/loader/Loader';
import { useFetching } from 'components/hooks/useFetching';
import PortfolioService from "services/PortfolioService";
import { useNavigate } from "react-router-dom"
import CaseContainer from "components/UI/case/caseContainer/CaseContainer";
import 'styles/css/portfolio.css'
function PortfolioPage() {

    const [portfolio, setPortfolio] = useState([])

    const notificationError = (error) => {
        if (error.length > 0) {
            NotificationManager.error(JSON.stringify(error), Strings.notificationsErrorTitle, 5000);
        }
    }
    const notificationWarning = (error) => {
        if (error.length > 0) {
            NotificationManager.warning(error, Strings.notificationsWarningTitle, 5000);
        }
    }

    const [fetchingPortfolio, loadingPortfolio, errorPortfolio] = useFetching(async () => {
        try {
            const res = await PortfolioService.getPortfolio()
            if (res) {
                await setPortfolio(res)
            }
        } catch (error) {
            notificationError(error)
        }
    })

    useEffect(() => {
        fetchingPortfolio()
    }, [])

    return (
        <Loader localStatus={loadingPortfolio}>
            <div className="portfolio-style">
                {portfolio.length > 0 ? <div className="portfolio-container">{portfolio.map((item, i) => {
                    return <ProfileOccasion key={i} {...item.portfolio} />
                })}</div>
                    : <CaseContainer text={Strings.portfolioText} />
                }
            </div>
        </Loader>

    );
}

export default PortfolioPage;