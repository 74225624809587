import axios from "axios";
import env from '../../package.json'
import $api from "http";


export default class TaskService {

    static createTask = async (data) => {
        const formData = new FormData()
        formData.append("additional_material", data.additional_material)
        formData.append("category", data.category)
        formData.append("contact_tg", data.contact_tg)
        formData.append("expected_result", data.expected_result)
        formData.append("occasion", JSON.stringify(data.occasion))
        formData.append("prize", data.prize)
        formData.append("specification", data.specification)
        formData.append("title", data.title)
        formData.append("file", data.presentation)
        const response = await $api.post(`${env.nodeHost}/api/task`, formData, { ...env.axiosConfig.data, withCredentials: true })
        if (response.status === 200) {
            return true
        }
        return false
    }

    static getTasksForMentorProfile = async (taskIds) => {
        const response = await $api.post(`${env.nodeHost}/api/task/mentor-profile`, { taskIds })
        return response.data
    }

    static test = async (data) => {
        const response = await axios.post(`${env.nodeHost}/api/file/presentation`, data, { ...env.axiosConfig.data, withCredentials: true })
        return response.data
    }

    static getTask = async () => {
        const response = await axios.get(`${env.nodeHost}/api/task`)
        return response.data
    }
    static getCurrentTask = async (id) => {
        const response = await $api.get(`${env.nodeHost}/api/task/${id}`)
        return response.data
    }

    static getCurrentTasks = async (command) => {
        const response = await $api.get(`${env.nodeHost}/api/task/current/${command}`)
        return response.data
    }

    static getCurrentTasksByOccasionId = async (occasion) => {
        const response = await $api.get(`${env.nodeHost}/api/task/by-occasion/${occasion}`)
        return response.data
    }

    static updateTask = async (data) => {
        const response = await $api.put(`${env.nodeHost}/api/task`, data)
        return response.data
    }
}