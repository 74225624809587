import classes from "./CardBlock.module.css"
import "@fontsource/roboto"
import Loader from "components/UI/loader/Loader"

import CardBlockFirst from "./FirstCard/CardBlockFirst"
import CardBlockSecond from "./SecondCArd/CardBlockSecond"


const CardBlock = ({ onClick }) => {


    return (
        <Loader>
            <div className={classes.main__style__for__block}>
                <div className={classes.block__title__style}>
                    <div className={classes.ellipse__style}>
                    </div>
                    <h1 className={classes.title__style}>
                        Кто нас поддерживает
                    </h1>
                </div>

                <div className={classes.main__block__style}>

                    <CardBlockFirst />
                    <CardBlockSecond />
                </div>
            </div>
        </Loader>
    )
}

export default CardBlock