import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, useLocation } from "react-router-dom";
import AppRouter from './components/appRouter/AppRouter'
import Sidebar from "./components/UI/sidebar/Sidebar"
import "./styles/css/App.css"
import "./styles/css/pop-up-window.css"
import "@fontsource/roboto"
import Colors from "./values/Colors";
import QrScanner from "./components/UI/qrCode/qrScanner/QrScanner";
import NavbarProfile from "./components/UI/navbar/navbarProfile/NavbarProfile";
import NavbarAdmin from "./components/UI/navbar/navbarAdmin/NavbarAdmin";
import env from '../package.json'
import { setSidebarStatus } from "redux/slice/sidebarSlice";
import { useDeviceType } from "components/hooks/useDeviceType";
import { RefMainContainerContext } from 'context';
import Footer from "components/UI/footer/Footer";
import OAuthService from "services/OAuthService";
import Notifications from "components/UI/popupNotifications/Notifications";
import { useFetching } from "components/hooks/useFetching";
import CommandService from "services/CommandService";
import "./styles/scss/variables.css"
import Strings from "values/Strings";

function App() {
    const title = useSelector(state => state.title.text)
    const addressBarName1 = window.location.pathname.slice(1).split('/')[0]
    const addressBarName2 = window.location.pathname.slice(1).split('/')[1]
    const onScrollBar = useSelector(state => state.scrollBar.status)
    const cameraStatus = useSelector(state => state.camera.isCamera)
    const sidebarStatus = useSelector(state => state.sidebar.isActive)
    // const loader = useSelector(state => state.loader.status)
    const sidebarItemName = useSelector(state => state.sidebarItem.item)
    const popUpWindowStatus = useSelector(state => state.popUpWindow.status)

    const userNavbarList = [Strings.sidebarGrade, Strings.sidebarProfile, Strings.sidebarMain]
    const adminNavbarList = [Strings.sidebarAdminPanel]
    const navbarNameList = ['admin', 'profile', 'team-grade-page']
    const mainNavbarList = ['main', 'logout']
    const device = useDeviceType()
    const dispatch = useDispatch()
    const mainContainerRef = useRef(null)

    function closeSideBar() {
        device === 'phone' && dispatch(setSidebarStatus(false))
    }

    const [checkOAuth, loadingOAuth, errorOAuth] = useFetching(async () => {
        try {
            const res = await OAuthService.refreshAuth()
            const userData = res?.userData
            localStorage.setItem('accessToken', res.accessToken)
            userData ? localStorage.setItem('userData', userData) : localStorage.removeItem("userData");
            const commandRes = await CommandService.checkCommand()
            commandRes ? localStorage.setItem('command', commandRes) : localStorage.removeItem("command");
        } catch (error) {
            localStorage.removeItem("userData")
            localStorage.removeItem("command")
        }
    })


    useEffect(() => {
        const id = localStorage.getItem('accessToken')
        if (id) {
            checkOAuth()
        }
        dispatch(setSidebarStatus(device === 'desktop'))
    }, [])




    return (
        // <AppLoader status={loadingOAuth}>
        <RefMainContainerContext.Provider value={{ mainContainerRef }}>
            <BrowserRouter>
                {cameraStatus === true ?
                    <QrScanner />
                    :
                    <div className="wrapper" style={{ overflow: popUpWindowStatus ? 'hidden' : '' }}>
                        <div className="sidebar" style={{ backgroundColor: Colors.white }}>
                            <Sidebar />
                        </div>
                        <div className='content' style={{
                            paddingTop: (adminNavbarList.includes(sidebarItemName) || navbarNameList.includes(addressBarName1) || navbarNameList.includes(addressBarName2) || mainNavbarList.includes(addressBarName1)) ? (device === 'phone' ? '5vh' : "10vh") : "0",
                            // marginLeft: sidebarStatus ? env.sidebar.width : "0",
                            marginLeft: device === 'desktop' ? ((sidebarStatus ? (env.sidebar.width - 2) + 'vw' : (env.sidebar.minWidth - 2) + 'vw')) : '0',
                        }}>
                            {(adminNavbarList.includes(sidebarItemName) || (navbarNameList.includes(addressBarName1)))
                                ? <nav className="navbar-container" style={{
                                    backgroundColor: Colors.white,
                                    left: (device === 'desktop' && sidebarStatus) ? env.sidebar.width - 2 + 'vw' : "0",
                                    width: "100%"
                                }}>
                                    <NavbarAdmin />
                                </nav>
                                :
                                (userNavbarList.includes(sidebarItemName) || navbarNameList.includes(addressBarName2) || mainNavbarList.includes(addressBarName1))
                                    ?
                                    <nav className="navbar-container" style={{
                                        backgroundColor: Colors.white,
                                        // left: "0",
                                        left: (device === 'desktop' && sidebarStatus) ? env.sidebar.width - 2 + 'vw' : "0",
                                        // width: "100%"
                                        width: (device === 'desktop' && sidebarStatus) ? `calc(100% - ${(env.sidebar.width - 2)}vw)` : "100%"
                                    }}>
                                        <NavbarProfile pageName={[addressBarName2, addressBarName1]} />
                                    </nav>
                                    : (title !== 'Общая информация о мероприятии') && <div className="title">
                                        <p style={{ color: Colors.white, marginRight: onScrollBar ? '5px' : '0' }}>{title}</p>
                                    </div>
                            }
                            <div className="content__container" ref={mainContainerRef} onClick={closeSideBar}>
                                <AppRouter />
                            </div>
                        </div>
                        {(addressBarName2 ? !addressBarName2.includes('team-grade-page') : true) &&
                            <div className={`${'footer-container'} ${popUpWindowStatus && 'pop-up-blure'}`} style={{
                                marginLeft: device === 'desktop' ? (sidebarStatus ? env.sidebar.width - 2 + 'vw' : env.sidebar.minWidth - 2 + 'vw') : '0',
                                width: device === 'desktop' ? (sidebarStatus ? `calc(100% - ${env.sidebar.width - 2}vw)` : `calc(100% - ${env.sidebar.minWidth - 2}vw)`) : '100%'
                            }}>
                                <nav className="footer">
                                    <Footer />
                                </nav>
                            </div>
                        }
                    </div>
                }
                <Notifications />
            </BrowserRouter >
        </RefMainContainerContext.Provider>
        // </AppLoader >
    );
}

export default App;