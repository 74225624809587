import CommandService from 'services/CommandService';
import classes from './TeamChoice.module.css'
import { IoCloseOutline } from "react-icons/io5";
import { useState } from 'react';


function TeamChoice({ choiceCommand, commandList, gradeLength, setPopUpWindowForChoice }) {

    const [value, setValue] = useState('')
    const [filters, setFilters] = useState({ removeMarkedCommands: false, removeWithoutMarkedCommands: false, })
    const [filterData, setFilterData] = useState(commandList)
    // #51a351

    const setFilter = (e) => {
        const searchValue = e ? e.target.value : value;
        setValue(searchValue);
        setFilterData(commandList.filter(item => {
            const gradesLength = Object.keys(item?.grades)
            const currentIndexGrade = gradesLength.length / gradeLength
            const searchStatus = `${item.name} – ${item.table_number}`.toLowerCase().includes(searchValue.toLowerCase())
            if (filters.removeMarkedCommands) {
                return currentIndexGrade !== 1 && searchStatus
            }
            if (filters.removeWithoutMarkedCommands) {
                return currentIndexGrade === 1 && searchStatus
            }
            return searchStatus
        }))
    }



    const forChoiceCommandOnFilter = (i) => {
        choiceCommand(0, commandList.findIndex(item => item.command_id === filterData[i].command_id))
    }

    const filterStatusList = [
        {
            id: "radio-pa", account_status: 0, title: 'С оценкой', action: () => {
                setFilters(old => ({ ...old, removeWithoutMarkedCommands: !old.removeWithoutMarkedCommands }))
                setFilter()
            }
        },
        {
            id: "radio-sc", account_status: 1, title: 'Без оценки', action: () => {
                setFilters(old => ({ ...old, removeMarkedCommands: !old.removeMarkedCommands }))
                setFilter()
            }
        },
        {
            id: "radio-st", account_status: 2, title: 'Сбросить', action: () => {
                setFilters({ removeMarkedCommands: false, removeWithoutMarkedCommands: false, })
                setValue('')
                setFilter()
            }
        },
    ]

    return (
        <div className={classes.wrapper}>
            <div className={classes.fixedContainer}>
                <div className={classes.close__style}>
                    <IoCloseOutline className={classes.closeTeamChoice} onClick={() => setPopUpWindowForChoice(false)} />
                </div>
                <div className={classes.inputContainer}>
                    <input placeholder='Поиск' className={classes.input} onChange={setFilter} type="text" value={value} />
                </div>

                <div className={classes.input__block}>
                    {filterStatusList.map((item, i) => {
                        return (
                            <div key={i} className={classes.form_radio_btn} onClick={() => item.action()}>
                                <input id={item.id} type="radio" name="scst" />
                                <label htmlFor={item.id}>{item.title}</label>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className={classes.container}>
                {CommandService.getCurrentCommandsNamesForList(filterData, gradeLength).map((item, i) => {
                    return <div key={i} onClick={() => forChoiceCommandOnFilter(i)} className={classes.containerInputBlock} style={{ borderColor: item.color }}>{item.item}</div>
                })}
            </div>
        </div>
    );
}

export default TeamChoice;