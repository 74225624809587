import axios from "axios";
import env from '../../package.json'
import crypto from 'crypto-browserify'
import $api from "http";

export default class CertificatService {

    static createUniqueHash(id = 9, length = 8) {
        const hash = crypto.createHash('md5')
        hash.update(id.toString())
        return hash.digest('hex').slice(0, length)
    }

    static createDiploma = async (data) => {
        const result = await $api.post(`/api/diploma/`, data)
        return result.data
    }
    static createCertificate = async (data) => {
        const result = await $api.post(`/api/certificate/`, data)
        return result.data
    }

    static getInfoCertificate = async (id) => {
        const result = await $api.get(`/api/certificate/${id}`)
        return result.data
    }

    static getInfoLeaderBoard = async () => {
        const result = await $api.get(`/api/certificate/board`)
        return result.data
    }
}