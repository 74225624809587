import { configureStore } from "@reduxjs/toolkit";

import titleSlice from './slice/titleSlice'
import navbarSlice from "./slice/navbarSlice";
import onScrollBarSlice from "./slice/onScrollBarSlice";
import loaderSlice from "./slice/loaderSlice"
import cameraSlice from "./slice/cameraSlice"
import sidebarSlice from "./slice/sidebarSlice";
import popUpWindowSlice from "./slice/popUpWindowSlice";
import sidebarItemSlice from "./slice/sidebarItemSlice";

// const persistConfig = {
//     key: "right",
//     storage,
//     whitelist: ["account"],
// };

// const persistedReducer = persistReducer(persistConfig, combineReducers({
//     title: titleSlice,
//     navbar: navbarSlice,
//     scrollBar: onScrollBarSlice,
//     loader: loaderSlice,
//     camera: cameraSlice,
//     sidebar: sidebarSlice,
//     popUpWindow: popUpWindowSlice,
// }));

// const store = configureStore({
//     reducer: persistedReducer,
// });

// const persistor = persistStore(store);

// export { persistor };
// export default store;


export default configureStore({
    reducer: {
        title: titleSlice,
        navbar: navbarSlice,
        scrollBar: onScrollBarSlice,
        loader: loaderSlice,
        camera: cameraSlice,
        sidebar: sidebarSlice,
        popUpWindow: popUpWindowSlice,
        sidebarItem: sidebarItemSlice,
    },
})