import CertificatService from "services/CertificatService";
import { useFetching } from "components/hooks/useFetching";
import ImageMouseCoordinates from "components/UI/imageMouseCoordinates/ImageMouseCoordinates";
import Loader from "components/UI/loader/Loader";
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from "react-redux";
import { changeLoaderStatus } from "redux/slice/loaderSlice";
import 'styles/css/certificate.css'
import Certificate from "components/UI/certificate/Сertificate"
import Button from "components/UI/buttons/button/Button";
import Strings from "values/Strings";
import NotificationManager from "react-notifications/lib/NotificationManager";
import Notifications from "components/UI/popupNotifications/Notifications";
import OccasionService from "services/OccasionService";
import CommandService from "services/CommandService";
import FontService from "services/FontService";
import 'styles/css/fonts.css'
import QrGenerator from "components/UI/qrCode/qrGenerator/QrGenerator";
import QrCode, { qrCodeDefaultValue } from "components/UI/qrCode/qrGenerator/QrCode";
import Logo from 'assets/icon/logo_blue.png';
import TitleMain from "components/UI/titleMain/TitleMain";
import Image from "services/ImageService";

function CertificateGenerationPage() {
    const imagePreviewRef = useRef(null)
    const [file, setFile] = useState(null)
    const [coordinates, setCoordinates] = useState({ x_fio: 0, y_fio: 0, x_teamName: 0, y_teamName: 0 });
    const [originalImageSize, setOriginalImageSize] = useState({})
    const [occasions, setOccasions] = useState([])
    const [occasionList, setOccasionList] = useState([])
    const [setting, setSetting] = useState({
        countFIO: '1', kegelFIO: '25',
        kegelCommandName: '25', colorFIO: "#000000ff",
        colorCommandName: "#000000ff", lineHeight: '1',
        fontFIO: 'rob', fontCommandName: 'rob',
        widthFIO: '250', widthCommandName: '250',
        sizeQRCode: '110', colorQRCode: "#3F98FFFF",
        fioTextAlign: 'left', teamNameTextAlign: 'left'
    })
    const [currentOccasion, setCurrentOccasion] = useState({})

    const [currentCommand, setCurrentCommand] = useState({})
    const [command, setCommand] = useState([])
    const [commandList, setCommandList] = useState([])
    const [link, setLink] = useState('')

    const dispatch = useDispatch()

    const notificationError = (error) => {
        if (error.length > 0) {
            NotificationManager.error(JSON.stringify(error), Strings.notificationsErrorTitle, 5000);
        }
    }
    const notificationWarning = (error) => {
        if (error.length > 0) {
            NotificationManager.warning(error, Strings.notificationsWarningTitle, 5000);
        }
    }


    const [fonts, setFonts] = useState([])

    const [fetchingFonts, loadingFetchingFonts, errorFetchingFonts] = useFetching(async () => {
        try {
            const res = await FontService.getFonts()
            setFonts(res)
        } catch (error) {
            notificationError(error)
        }
    })

    useEffect(() => {
        fetchingFonts()
    }, [])

    const nameForAllCertificateGenerate = 'Все команды'

    const [fetchingCommand, loadingCommand, errorCommand] = useFetching(async (currentDataOccasion) => {
        try {
            const res = await CommandService.getCurrentCommandsByOccasionId(currentDataOccasion.id)
            setCommand([{ name: nameForAllCertificateGenerate }, ...res,])
            setCommandList([nameForAllCertificateGenerate, ...res.map(item => item.name)])
        } catch (error) {
            notificationError(error)
        }
    })

    const [fetchingOccasion, loadingFetchingOccasion, errorFetchingOccasion] = useFetching(async () => {
        try {
            const res = await OccasionService.getOccasions()
            setOccasions(res)
            setOccasionList(res.map(item => OccasionService.getTextInputOccasion(item)))
        } catch (error) {
            notificationError(error)
        }
    })

    const copyDataInBuffer = (url) => {
        try {
            if (document.hasFocus()) {
                if (url) {
                    navigator.clipboard.writeText(url.toString())
                    NotificationManager.success(Strings.notificationsCopyData, Strings.notificationsSuccessTitle, 2500);
                }
            }
        } catch (err) {
            console.log('copySettings :', err);
        }
    }

    const [qrCodeOptions, setQrCodeOptions] = useState({ ...qrCodeDefaultValue })

    const [fetchImageUploadResult, loadingImageUploadResult, errorImageUploadResult] = useFetching(async (file) => {
        const imageBase64 = await Image.toBase64(Logo)
        const newSetting = { ...setting, ...coordinates, originalImageSize }
        qrCodeOptions.image = imageBase64
        newSetting.qrCodeOptions = qrCodeOptions
        newSetting.image = file
        try {
            if (file && currentCommand.name && currentOccasion.name
                && (setting.kegelFIO.length > 0) && (setting.colorFIO.length > 0)
                && (setting.lineHeight.length > 0)) {
                if ((coordinates.x_teamName.toString().length > 0) && (coordinates.y_teamName.toString().length > 0) &&
                    (setting.kegelCommandName.length > 0) && (setting.colorCommandName.length > 0) && (setting.countFIO.length > 0)) {
                    if ((currentCommand.name) === nameForAllCertificateGenerate) {
                        newSetting.occasion = currentOccasion.id
                        const res = await CertificatService.createCertificate(newSetting)
                        setLink(res)
                        copyDataInBuffer(res)
                    } else {
                        newSetting.command = currentCommand.command_id
                        newSetting.teamName = currentCommand.name
                        const res = await CertificatService.createDiploma(newSetting)
                        setLink(res)
                        copyDataInBuffer(res)
                    }
                }
                else {
                    notificationWarning(`Заполнены не все поля`)
                }

            } else {
                notificationWarning(`Заполнены не все поля`)
            }
        } finally {
            dispatch(changeLoaderStatus(loadingImageUploadResult))
        }
    })


    const currentCertificates = (value, y = false) => {
        // Переводим значение для текущего изображения
        if (imagePreviewRef) {
            const imageSize = { width: imagePreviewRef?.current?.width, height: imagePreviewRef?.current?.height };
            if (y) {
                return Math.round(value * (imageSize.height / originalImageSize.height));
            }
            return Math.round(value * (imageSize.width / originalImageSize.width));
        }
        return 0
    }

    const adjustFontSize = (fontSize) => {
        if (imagePreviewRef && imagePreviewRef.current) {
            const imageSize = {
                width: imagePreviewRef.current.width,
                height: imagePreviewRef.current.height
            };

            // Вычисляем коэффициенты масштабирования для ширины и высоты
            const widthScale = imageSize.width / originalImageSize.width;
            const heightScale = imageSize.height / originalImageSize.height;

            // Используем минимальный коэффициент масштабирования, чтобы гарантировать, что текст поместится и по ширине, и по высоте
            const scale = Math.min(widthScale, heightScale);

            // Адаптируем кегль шрифта
            return Math.round(fontSize * scale);
        }
        return 0;
    }

    const [currentCoordinates, setCurrentCoordinates] = useState({ x_fio: 0, y_fio: 0, x_teamName: 0, y_teamName: 0, x_qrCode: 0, y_qrCode: 0 });
    // fioS|fioE|teamNameS|teamNameE
    const [inputCoordinate, setInputCoordinate] = useState('fioS')

    const handleMouseMove = (event, y = false) => {
        let offsetX = null
        let offsetY = null
        if (event.target.value) {
            if (y) {
                offsetY = event.target.value && parseInt(event.target.value)
            } else {
                offsetX = event.target.value && parseInt(event.target.value)
            }
        } else {
            offsetX = event.nativeEvent.offsetX
            offsetY = event.nativeEvent.offsetY
        }

        const imageSize = { width: imagePreviewRef?.current?.width, height: imagePreviewRef?.current?.height }
        const xPosition = offsetX ? Math.round(offsetX * (originalImageSize.width / imageSize.width)) : null
        const yPosition = offsetY ? Math.round(offsetY * (originalImageSize.height / imageSize.height)) : null
        let position = {}
        if (inputCoordinate === 'fio') {
            const listX = xPosition ? xPosition : coordinates.x_fio
            const listY = yPosition ? yPosition : coordinates.y_fio
            position = { x_fio: listX, y_fio: listY }
            setCurrentCoordinates(old => ({
                ...old,
                x_fio: offsetX ? offsetX : currentCoordinates.x_fio,
                y_fio: offsetY ? offsetY : currentCoordinates.y_fio
            }))
        } else if (inputCoordinate === 'teamName') {
            const listX = xPosition ? xPosition : coordinates.x_teamName
            const listY = yPosition ? yPosition : coordinates.y_teamName
            position = { x_teamName: listX, y_teamName: listY }
            setCurrentCoordinates(old => ({
                ...old,
                x_teamName: offsetX ? offsetX : currentCoordinates.x_teamName,
                y_teamName: offsetY ? offsetY : currentCoordinates.y_teamName
            }))
        } else if (inputCoordinate === 'qrCode') {
            const listX = xPosition ? xPosition : coordinates.x_qrCode
            const listY = yPosition ? yPosition : coordinates.y_qrCode
            position = { x_qrCode: listX, y_qrCode: listY }
            setCurrentCoordinates(old => ({
                ...old,
                x_qrCode: offsetX ? offsetX : currentCoordinates.x_qrCode,
                y_qrCode: offsetY ? offsetY : currentCoordinates.y_qrCode
            }))
        }
        setCoordinates(old => ({ ...old, ...position }))
    }


    const copySettings = () => {
        try {
            navigator.clipboard.writeText(JSON.stringify({ coordinates, setting, currentCoordinates }))
            NotificationManager.success(Strings.notificationsCopyData, Strings.notificationsSuccessTitle, 2500);
        } catch (err) {
            console.log('copySettings :', err);
        }
    }

    const pastSettings = () => {
        navigator.clipboard.readText().then(data => {
            if (data && data.length > 0) {
                const jsonData = JSON.parse(data)
                setSetting(jsonData.setting)
                setCoordinates(jsonData.coordinates)
                setCurrentCoordinates(jsonData.currentCoordinates)
            }
        })

    }

    useEffect(() => {
        fetchingOccasion()
    }, [])

    const [exampleData, setExampleData] = useState({ fio: "Иванов Иван Иванович", teamName: "СурГУ" })

    const clearFIO = () => setCoordinates(old => ({ ...old, x_fio: '', y_fio: '' }))
    const clearTeamName = () => setCoordinates(old => ({ ...old, x_teamName: '', y_teamName: '' }))
    const clearLink = () => setLink('')

    const setFioTextAlign = (orientacion) => setSetting(old => ({ ...old, fioTextAlign: orientacion }))
    const setTeamNameTextAlign = (orientacion) => setSetting(old => ({ ...old, teamNameTextAlign: orientacion }))

    const uniqueHash = CertificatService.createUniqueHash(0)

    useEffect(() => {
        const newQrCodeValue = { ...qrCodeDefaultValue, }
        const newValue = [{
            offset: 1,
            color: setting.colorQRCode
        }]
        newQrCodeValue.dotsOptions.gradient.colorStops = newValue
        newQrCodeValue.cornersSquareOptions.gradient.colorStops = newValue
        QrCode.update(newQrCodeValue)
        setQrCodeOptions(newQrCodeValue)
    }, [setting.colorQRCode])



    const [currentImage, setCurrentImage] = useState(null);
    const deleteImage = () => {
        setFile(null)
        setCurrentImage(null)
    }


    return (
        <Loader>
            <div className="certificate-wrapper">
                <TitleMain className='certificate__title' text={"Сертификаты"} jsc="center" fns="1.5vw" elpSize="0.9vw" />
                <Certificate className={'certificate__container'} setting={setting} setSetting={setSetting} occasions={occasions} occasionList={occasionList}
                    currentOccasion={currentOccasion} setCurrentOccasion={setCurrentOccasion}
                    setCurrentCommand={setCurrentCommand} command={command} commandList={commandList} currentCommand={currentCommand}
                    currentCoordinates={currentCoordinates} setCurrentCoordinates={setCurrentCoordinates}
                    coordinates={coordinates} setCoordinates={setCoordinates} imagePreviewRef={imagePreviewRef}
                    originalImageSize={originalImageSize} copySettings={copySettings} pastSettings={pastSettings}
                    exampleData={exampleData} setExampleData={setExampleData} setInputCoordinate={setInputCoordinate}
                    setFioTextAlign={setFioTextAlign} setTeamNameTextAlign={setTeamNameTextAlign} fonts={fonts} handleMouseMove={handleMouseMove}
                    fetchingCommand={fetchingCommand} nameForAllCertificateGenerate={nameForAllCertificateGenerate}
                />
                {Object.keys(currentOccasion).length > 0 &&
                    <>
                        <div className="certificate-generation-container" style={{
                            height: `${originalImageSize?.height ?
                                currentCertificates(originalImageSize.height) + 'px' : '100%'}`
                        }}>
                            <div className="certificate-generation-container__image-preview" onClick={handleMouseMove} >
                                {file && <div className="certificate-generation-container__qrcode" style={{
                                    width: `${currentCertificates(setting.sizeQRCode)}px`,
                                    top: currentCoordinates.y_qrCode,
                                    left: currentCoordinates.x_qrCode,
                                }}>
                                    <QrGenerator url={uniqueHash} onCopy={false} />
                                    <div className="hash-code">{uniqueHash}</div>
                                </div>}
                                {file && ((currentCommand.name) !== nameForAllCertificateGenerate) && <span style={{
                                    left: currentCoordinates.x_teamName, top: currentCoordinates.y_teamName, textAlign: setting.teamNameTextAlign,
                                    backgroundColor: 'gray', width: `${currentCertificates(setting.widthCommandName)}px`,
                                    fontSize: `${setting.kegelCommandName.length > 0 ? adjustFontSize(setting.kegelCommandName) : adjustFontSize(12)}px`,
                                    color: `${setting.colorCommandName.length > 0 ? setting.colorCommandName : 'rgba(0, 0, 0, 1)'}`,
                                    fontFamily: setting.fontCommandName
                                }}>{exampleData.teamName}</span>}
                                {file && <span style={{
                                    left: currentCoordinates.x_fio, top: currentCoordinates.y_fio, textAlign: setting.fioTextAlign,
                                    backgroundColor: 'gray', width: `${currentCertificates(setting.widthFIO)}px`,
                                    fontSize: `${setting.kegelFIO.length > 0 ? adjustFontSize(setting.kegelFIO) : adjustFontSize(12)}px`,
                                    color: `${setting.colorFIO.length > 0 ? setting.colorFIO : 'rgba(0, 0, 0, 1)'}`,
                                    fontFamily: setting.fontFIO
                                }}>{exampleData.fio}</span>}
                                <img ref={imagePreviewRef} style={{ display: file ? 'block' : 'none' }} />
                                {file && <div onClick={deleteImage} className="image-delete">
                                    <span>X</span>
                                </div>}
                            </div>
                            <ImageMouseCoordinates image={true} setFile={setFile}
                                setOriginalImageSize={setOriginalImageSize} file={file}
                                imagePreviewRef={imagePreviewRef}
                                currentImage={currentImage} setCurrentImage={setCurrentImage} />
                        </div>
                        {(link?.length > 0) && <div onClick={() => copyDataInBuffer(link)}>Ссылка: {link}</div>}
                        <div className="button-position">
                            <Button onClick={() => fetchImageUploadResult(file)}>Создать</Button>
                        </div>
                    </>
                }
            </div>
            <Notifications />
        </Loader>
    );
}

export default CertificateGenerationPage;