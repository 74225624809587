import React, { useEffect, useState } from "react";
import classes from "./CircleChart.module.css"

const CircularChart = ({ titleUnderChart, percentText, percentUnderText = "", percentChart }) => {
    const [strokeDasharray, setStrokeDasharray] = useState(0);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setStrokeDasharray(percentChart);
        }, 100); // небольшая задержка, чтобы обеспечить запуск анимации
        return () => clearTimeout(timeout);
    }, [percentChart]);

    return (
        <div className={classes.single__chart}>
            <svg viewBox="0 0 36 36" className={`${classes.circular__chart} ${classes.blue}`}>
                <path
                    className={classes.circle__bg}
                    d="M18 2.0845
                    a 15.9155 15.9155 0 0 1 0 31.831
                    a 15.9155 15.9155 0 0 1 0 -31.831"
                />
                <path
                    className={classes.circle}
                    strokeDasharray={`${strokeDasharray}, 100`}
                    d="M18 2.0845
                    a 15.9155 15.9155 0 0 1 0 31.831
                    a 15.9155 15.9155 0 0 1 0 -31.831"
                />
                <text x="18" y={percentUnderText !== "" ? 19 : 21} className={classes.percentage}>{percentText}</text>
                {percentUnderText !== "" && (
                    <text x="18" y="24" className={classes.percentage__small}>{percentUnderText}</text>
                )}
            </svg>
            <span>{titleUnderChart}</span>
        </div>
    );
};

export default CircularChart;
