import classes from "./UpdateCase.module.css"
import "@fontsource/roboto"
import InputBlock from "components/UI/inputBlock/InputBlock"
import { useState, useRef, useEffect } from 'react'
import Tooltip from "rc-tooltip"
import 'rc-tooltip/assets/bootstrap_white.css';
import CaseChecker from "components/UI/caseChecker/CaseChecker"
import { useDeviceType } from "components/hooks/useDeviceType"
import TitleMain from "components/UI/titleMain/TitleMain";
import OccasionService from "services/OccasionService"
import { NotificationManager } from "react-notifications"
import Strings from "values/Strings"
import { useFetching } from "components/hooks/useFetching"
import TaskService from "services/TaskService"
import TaskStatusService from "services/TaskStatusService"
import Loader from "components/UI/loader/Loader"


const UpdateCase = () => {
    const [cases, setCases] = useState([])
    const [occasions, setOccasions] = useState([])
    const [occasionList, setOccasionList] = useState([])
    const [currentOccasion, setCurrentOccasion] = useState({})
    const [statusList, setStatusList] = useState([])
    const ref = useRef()

    const notificationError = (error) => {
        if (Object.keys(error).length > 0) {
            NotificationManager.error(error?.message ? error?.message : JSON.stringify(error), Strings.notificationsErrorTitle, 5000);
        }
    }

    const [fetchingOccasion, loadingFetchingOccasion, errorFetchingOccasion] = useFetching(async () => {
        try {
            const res = await OccasionService.getOccasionsScheduled()
            setOccasions(res)
            setOccasionList(res.map(item => OccasionService.getTextInputOccasion(item, false)))
        } catch (error) {
            notificationError(error)
        }
    })

    const [fetchingTaskStatus, loadingFetchingTaskStatus, errorFetchingTaskStatus] = useFetching(async () => {
        try {
            const res = await TaskStatusService.getStatus()
            setStatusList(res)
        } catch (error) {
            notificationError(error)
        }
    })

    const [fetchingCases, loadingFetchingCases, errorFetchingCases] = useFetching(async (id) => {
        try {
            const res = await TaskService.getCurrentTasksByOccasionId(id)
            setCases(res)
        } catch (error) {
            notificationError(error)
        }
    })

    const [updateCase, loadingUpdateCase, errorUpdateCase] = useFetching(async (id, data) => {
        try {
            await TaskService.updateTask({ id, data })
            NotificationManager.success(Strings.notificationsChangeStatus, Strings.notificationsSuccessTitle, 2500);
        } catch (error) {
            notificationError(error)
        }
    }, false)

    useEffect(() => {
        fetchingOccasion()
        fetchingTaskStatus()
    }, [])

    const changeOcassion = (data, index) => {
        setCurrentOccasion(occasions[index])
        fetchingCases(occasions[index].id)
    }

    return (
        <Loader>
            <div ref={ref} className={classes.events__create__style}>
                <TitleMain text={"Работа с кейсами"} jsc="center" fns="1.5vw" elpSize="0.9vw" />
                <div>
                    <div style={{ display: "flex", marginBottom: "3vw" }}>
                        <InputBlock readOnly={true} indexStatus={true} placeholder={"Мероприятие"} onListStatus={true} list={occasionList} value={OccasionService.getTextInputOccasion(currentOccasion)} setValue={changeOcassion} ><img src={require("assets/images/arrow.jpg")} /></InputBlock>
                    </div>
                    <div className={classes.task_container}>
                        {statusList.length > 0 && cases.length > 0 && cases.map(item => <CaseChecker key={item.id}
                            updateCase={updateCase} statusList={statusList} {...item} />)}
                    </div>
                </div>
            </div>
        </Loader>
    )
}

export default UpdateCase