import { createSlice } from '@reduxjs/toolkit'
import Strings from 'values/Strings'

export const titleSlice = createSlice({
    name: 'title',
    initialState: {
        text: Strings.sidebarMain,
    },
    reducers: {
        newName: (state, action) => { state.text = action.payload },
    }
})

export const { newName } = titleSlice.actions
export default titleSlice.reducer