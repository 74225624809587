import classes from './DropDownList.module.css'
import { useEffect, useState } from "react";
import DropDownItem from './DropDownItem'
import { useDeviceType } from 'components/hooks/useDeviceType';


function DropDownList({ children, width = 60, fullWidth = false, fullWidthOnMobile = true, indexStatus, zIndex, ...props }) {
    const [list, setList] = useState([]);
    const device = useDeviceType()
    const [height, setHeight] = useState(device === 'desktop' ? 2.3 : 7);

    // const listWidth = fullWidth ? '100%' : ((fullWidthOnMobile && device === 'phone') ? 'calc(100% - 10px - 10px)' : `calc(${width}vw - 10px - 10px)`)
    // const itemWidth = fullWidth ? '100%' : ((fullWidthOnMobile && device === 'phone') ? 'calc(90% - 10px - 10px)' : `calc(${width}vw - 10px - 10px)`)
    const listWidth = fullWidth ? '100%' : ((fullWidthOnMobile && device === 'phone') ? '100%' : `calc(${width}vw - 10px - 10px)`)
    const itemWidth = fullWidth ? '100%' : ((fullWidthOnMobile && device === 'phone') ? '90%' : `calc(${width}vw - 10px - 10px)`)

    const dropDownListUpdate = (key, text) => <DropDownItem indexStatus={indexStatus} width={itemWidth} onClick={props.setValue} index={key} key={key}>{text}</DropDownItem >


    useEffect(() => {
        const listAfterFiltering = props.list.filter(item => item.includes(props?.filter))
        setHeight((device === 'phone' ? 10 : 2.05) * listAfterFiltering.length)
        setList(listAfterFiltering.map((item, i) => dropDownListUpdate(i, item)))
    }, [props?.filter, props.list])

    return (
        <div className={classes.wrapper}
            style={{
                width: listWidth,
                height: `${props.isActive ? height : 0}vw`,
                overflowY: (height / (device === 'phone' ? 10 : 2.05) > (device === 'phone' ? 4 : 7) ? 'scroll' : 'auto'),
                zIndex: zIndex - 1,
                paddingTop: !props.isActive && '0'
            }}>
            {list}
        </div>
    )
}

export default DropDownList;