import classes from "./TeamGrade.module.css"
import "@fontsource/roboto"
import InputBlock from "components/UI/inputBlock/InputBlock"
import { useDeviceType } from "components/hooks/useDeviceType"
import Tooltip from "rc-tooltip"
import 'rc-tooltip/assets/bootstrap_white.css';
import { useState, useRef, useEffect } from 'react'
import update from "immutability-helper";
import { useCallback } from "react";
import { Card } from "./Card"
import Button from "components/UI/buttons/button/Button"
import OccasionService from "services/OccasionService"
import { useFetching } from "components/hooks/useFetching"
import ParticipantService from "services/ParticipantService"
import TitleMain from "components/UI/titleMain/TitleMain";

const TeamGrade = ({ onClick,
    occasions,
    occasionList,
    resultList,
    fetchingRatings, updateRatings, notificationError }) => {
    // const [events, setOccasions] = useState()
    const [currentOccasion, setCurrentOccasion] = useState({})
    const [indexOccasion, setIndexOccasion] = useState(0)

    const device = useDeviceType()
    const ref = useRef()

    const [uniqueNames, setUniqueNames] = useState([])
    const [result, setResult] = useState([])
    const [massCategory, setMassCategory] = useState([])
    useEffect(() => {

        if (Object.keys(resultList).length > 0) {
            const names = resultList["finalGrade"].sort((a, b) => b.total_grade - a.total_grade).map((values) => values["name"])
            const unique = [...new Set(names)]
            setUniqueNames(unique)
            unique.forEach((valuesC, j) => {
                result.push(
                    <div key={j} className={classes.checkpoint__container}>
                        {Array.from({ length: resultList["checkPoint"][0]["check_point_count"] }, (_, i) => (
                            <div className={classes.checkpoint__block} key={i}>
                                <span>Check point №{i + 1} {valuesC}</span>
                                <div className={classes.border__for__list}>
                                    {resultList["checkPoint"].map((values, index) => (
                                        values["category_name"] === valuesC && values["num_check"] === i + 1 && (
                                            <span key={index}>
                                                {values["table_number"]} : {values["name"]} - {values["total_grade"]}
                                            </span>
                                        ))
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                )
            });

            setMassCategory(unique?.map(item => (
                {
                    [item]: resultList["finalGrade"].filter(obj => obj.name === item).map((values, index) => (values["name"] === item) && ({ id: values["command_id"], text: values["command_name"] }))
                }
            )))

        }
    }, [resultList])


    function changeOcassion(data, index) {
        setCurrentOccasion(occasions[index])
        setIndexOccasion(index)
        setResult([])
        fetchingRatings(occasions[index].id)
    }


    const moveCard = useCallback((categoryKey, dragIndex, hoverIndex) => {
        setMassCategory((prevMassCategory) => {
            // ищем из массива тот элемент в котором есть нужная нам категория
            const prevCategoryElement = prevMassCategory.find(item => Object.keys(item)[0] === categoryKey)
            // получаем ту категорию, в которой были изменения
            const categoryArray = prevCategoryElement[categoryKey]
            let newCategoryArray = [...categoryArray];
            // меняем элементы массива местами (у конкретной категории)
            [newCategoryArray[dragIndex], newCategoryArray[hoverIndex]] = [newCategoryArray[hoverIndex], newCategoryArray[dragIndex]];
            // ищем из массива тот индекс того элемент в котором есть нужная нам категория
            const changedElementIndex = prevMassCategory.findIndex(item => Object.keys(item)[0] === categoryKey)
            // обновляем по категорию
            prevMassCategory[changedElementIndex][categoryKey] = newCategoryArray
            return [...prevMassCategory]
        });
    }, []);


    return (
        <div ref={ref} className={classes.main__block__style}>
            <TitleMain text={"Оценка команд"} jsc="center" fns="1.5vw" elpSize="0.9vw" />
            {/* <h3>Оценка команд</h3> */}
            {device === "phone"
                ? <div style={{ display: "flex" }}>
                    <InputBlock indexStatus={true} readOnly={true} placeholder={"Мероприятие"} onListStatus={true} list={occasionList} value={OccasionService.getTextInputOccasion(currentOccasion)} setValue={changeOcassion} refMainContainer={ref}><img src={require("assets/images/arrow.jpg")} /></InputBlock>
                </div>
                : <div style={{ display: "flex", justifyContent: "center", marginBottom: "2vw" }}>
                    {/* <div className={classes.text__style}>
                        <Tooltip placement="top" trigger={'hover'} overlay={<span style={{ fontSize: "0.7vw", fontFamily: "Roboto" }} className={classes.tooltip__style}>В выпадающем списке представлены <br />все команды для оценивания</span>}>
                            <span>Мероприятие:</span>
                        </Tooltip>
                    </div> */}
                    <InputBlock placeholder={"Мероприятие"} indexStatus={true} readOnly={true} onListStatus={true} list={occasionList} value={OccasionService.getTextInputOccasion(currentOccasion)} setValue={changeOcassion} refMainContainer={ref}><img src={require("assets/images/arrow.jpg")} /></InputBlock>
                </div>
            }
            {/* ------------------------Места команд--------------------------- */}
            {Object.values(currentOccasion).length > 0 && <div className={classes.grade__team__style}>
                {massCategory.map((valuesC, indexC) =>
                    <div key={indexC} className={classes.list__comand__style}>
                        <span className={classes.span__style}>Места команд {Object.keys(valuesC)[0]}</span>
                        {Object.values(valuesC)[0].map((card, i) => (
                            <Card
                                key={i}
                                index={i}
                                id={card.id}
                                text={card.text}
                                moveCard={(dragIndex, hoverIndex) => moveCard(Object.keys(valuesC)[0], dragIndex, hoverIndex)}
                            />))}
                        <Button onClick={() => updateRatings({ data: massCategory, category: Object.keys(valuesC)[0] })}>Сохранить</Button>
                    </div>
                )}
            </div>}
            {/* --------------------------Баллы по категориям----------------------- */}
            {
                Object.values(currentOccasion).length > 0 &&
                <div className={classes.check__point__block}>
                    {result && result}
                </div>
            }
            {/* --------------------------Финалые баллы----------------------- */}
            {
                resultList["finalGrade"] && uniqueNames.map((values, i) => <div key={i} className={classes.all__mark__style}>
                    <span>Финальная оценка категории {values}</span>
                    <div className={classes.all__mark__block__border}>
                        {resultList["finalGrade"].map((item, i) => item["name"] === values && <span key={i}>{item["table_number"]} : {item["command_name"]} - {parseFloat(item["total_grade"]).toFixed(2)}</span>)}
                    </div>
                </div>)
            }
        </div >
    )
}
export default TeamGrade