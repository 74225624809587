import classes from "./СonfirmationParticipation.module.css"
import IconWithText from "components/UI/iconWithText/IconWithText"
import InputBlock from "components/UI/inputBlock/InputBlock"
import Button from "components/UI/buttons/button/Button"
import "@fontsource/roboto"
import Tooltip from "rc-tooltip"
import 'rc-tooltip/assets/bootstrap_white.css';
import { useDeviceType } from "components/hooks/useDeviceType"
import { useRef, useState } from "react"
import TitleMain from "../titleMain/TitleMain"

const СonfirmationParticipation = ({ userInfo, createCommand, close,
    commandData, setCommandData, role = [] }) => {
    const device = useDeviceType()

    const [roleFilter, setRoleFilter] = useState(role)
    const [currentRole, setCurrentRole] = useState(null)
    const [currentRoleText, setCurrentRoleText] = useState('')

    const ref = useRef()

    const changeRole = (data, index) => {
        data.length === 0 ? setRoleFilter(role) : setRoleFilter(role.filter(item => item.role_name.toLowerCase().includes(data.toLowerCase())))
        setCurrentRoleText(data)
        if (index !== undefined) {
            setCurrentRole(roleFilter[index])
            setCurrentRoleText(roleFilter[index].role_name)
        }
    }


    return (
        <div className={classes.main__block__ticket__style} onClick={null} ref={ref}>
            {/* <h3>Подтверждение участия</h3> */}
            <TitleMain text={"Создание команды"} jsc="center" />
            <ul>
                {/* {device === "phone"
                    ? <li>
                        <InputBlock setValue={() => { }} value={userInfo.surname} readOnly={true} placeholder={"Фамилия"} heigBlock={"9vw"} heigInt={"7vw"} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                    </li>
                    :
                    <li>
                        <div>
                            <Tooltip placement="top" trigger={'hover'} overlay={<span style={{ fontSize: "0.7vw", fontFamily: "Roboto" }} className={classes.tooltip__style}>В данном поле <br />заполняется фамилия участника</span>}>
                                <IconWithText style={{ marginRight: "0.8vw" }} text={"Фамилия"}><img src={require("assets/icon/user.png")} /></IconWithText>
                            </Tooltip>
                        </div>
                        <InputBlock placeholder={"Фамилия"} setValue={() => { }} value={userInfo.surname} readOnly={true} heigBlock={"1.7vw"} heigInt={"1.4vw"} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                    </li>
                }

                {device === "phone"
                    ? <li>
                        <InputBlock setValue={() => { }} cursorPointer={true} readOnly={true} value={userInfo.name} placeholder={"Имя"} heigBlock={"9vw"} heigInt={"7vw"} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                    </li>
                    :
                    <li>
                        <div>
                            <Tooltip placement="top" trigger={'hover'} overlay={<span style={{ fontSize: "0.7vw", fontFamily: "Roboto" }} className={classes.tooltip__style}>В данном поле <br />заполняется имя участника</span>}>
                                <IconWithText style={{ marginRight: "0.8vw" }} text={"Имя"}><img src={require("assets/icon/user.png")} /></IconWithText>
                            </Tooltip>
                        </div>
                        <InputBlock placeholder={"Имя"} setValue={() => { }} cursorPointer={true} readOnly={true} value={userInfo.name} heigBlock={"1.7vw"} heigInt={"1.4vw"} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                    </li>
                }

                {device === "phone"
                    ? <li>
                        <InputBlock setValue={() => { }} cursorPointer={true} readOnly={true} value={userInfo.patronymic} placeholder={"Отчество"} heigBlock={"9vw"} heigInt={"7vw"} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                    </li>
                    :
                    <li>
                        <div>
                            <Tooltip placement="top" trigger={'hover'} overlay={<span style={{ fontSize: "0.7vw", fontFamily: "Roboto" }} className={classes.tooltip__style}>В данном поле <br />заполняется отчество участника</span>}>
                                <IconWithText style={{ marginRight: "0.8vw" }} text={"Отчество"}><img src={require("assets/icon/user.png")} /></IconWithText>
                            </Tooltip>
                        </div>
                        <InputBlock setValue={() => { }} cursorPointer={true} readOnly={true} value={userInfo.patronymic} heigBlock={"1.7vw"} heigInt={"1.4vw"} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                    </li>
                } */}

                {roleFilter.length > 0 && device === "phone"
                    ? <li>
                        <InputBlock refMainContainer={ref} setValue={changeRole} heigBlock={"11vw"} heigInt={"7vw"} indexStatus={true} onListStatus={true} list={roleFilter.map(item => item.role_name)}
                            value={currentRoleText} placeholder={"Роль в команде"}><img src={require("assets/images/arrow.jpg")} /></InputBlock>
                    </li>
                    :
                    <li>
                        {/* <div>
                            <Tooltip placement="top" trigger={'hover'} overlay={<span style={{ fontSize: "0.7vw", fontFamily: "Roboto" }} className={classes.tooltip__style}>В данном поле <br />заполняется отчество участника</span>}>
                                <IconWithText style={{ marginRight: "0.8vw" }} text={"Роль"}><img src={require("assets/icon/role.png")} /></IconWithText>
                            </Tooltip>
                        </div> */}
                        <InputBlock placeholder={"Роль в команде"} refMainContainer={ref} setValue={changeRole} width={33} heigBlock={"2.3vw"} heigInt={"1.4vw"} indexStatus={true} onListStatus={true} list={roleFilter.map(item => item.role_name)}
                            value={currentRoleText}><img src={require("assets/images/arrow.jpg")} /></InputBlock>
                    </li>
                }

                {device === "phone"
                    ? <li>
                        <InputBlock setValue={char => setCommandData(old => ({ ...old, name: char }))} value={commandData.name} placeholder={"Команда"} heigBlock={"11vw"} heigInt={"7vw"} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                    </li>
                    :
                    <li>
                        {/* <div>
                            <Tooltip placement="top" trigger={'hover'} overlay={<span style={{ fontSize: "0.7vw", fontFamily: "Roboto" }} className={classes.tooltip__style}>В данном поле <br />выбирается команда<br />к который вы относитесь</span>}>
                                <IconWithText style={{ marginRight: "0.8vw" }} text={"Команда"}><img src={require("assets/icon/command.png")} /></IconWithText>
                            </Tooltip>
                        </div> */}
                        <InputBlock placeholder={"Название команды"} setValue={char => setCommandData(old => ({ ...old, name: char }))} value={commandData.name} heigBlock={"2.3vw"} heigInt={"1.4vw"} localRef={true} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                    </li>
                }

                {device === "phone"
                    ? <li>

                        <InputBlock setValue={char => setCommandData(old => ({ ...old, count_participant: char }))} value={commandData.count_participant} placeholder={"Кол-во людей в команде"} heigBlock={"11vw"} heigInt={"7vw"} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                    </li>
                    :
                    <li>
                        {/* <div>
                            <Tooltip placement="top" trigger={'hover'} overlay={<span style={{ fontSize: "0.7vw", fontFamily: "Roboto" }} className={classes.tooltip__style}>В данном поле<br />заполняется количество<br />людей в команде</span>}>
                                <IconWithText style={{ marginRight: "0.8vw" }} text={"Кол-во людей"}><img src={require("assets/icon/team.png")} /></IconWithText>
                            </Tooltip>
                        </div> */}

                        <InputBlock placeholder={"Кол-во людей в команде"} setValue={char => setCommandData(old => ({ ...old, count_participant: char }))} value={commandData.count_participant} heigBlock={"2.3vw"} heigInt={"1.4vw"} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                    </li>
                }

            </ul>
            <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center", marginTop: "1vw" }}>
                <Button onClick={close}>Закрыть</Button>
                <Button onClick={() => createCommand(commandData, currentRole)}>Подтвердить</Button>
            </div>

        </div>
    );
}

export default СonfirmationParticipation;