import classes from "./Button.module.css"
import Colors from "values/Colors"
import "@fontsource/roboto"
import { useState } from "react";

const Button = ({ children, onClick, color = Colors.blue2, className = '', ...props }) => {

    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <div onClick={onClick} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}
            className={`${classes.style__button} ${className}`} style={{ borderColor: isHovered ? Colors.blue : color, ...props.style }}>
            <span style={{ color: isHovered ? Colors.blue : color }} className={classes.text__style}>{children}</span>
        </div >

    )
}

export default Button