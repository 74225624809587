import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { newName } from "redux/slice/titleSlice";

function NofoundPage() {

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(newName('код ошибки: 404'))
    }, [])


    return (
        <div>
            <h1>Страница не найдена</h1>
        </div>
    );
}

export default NofoundPage;