import classes from "./TableForMark.module.css"
import "@fontsource/roboto"

const TableForMark = ({ tableData, tableTitle }) => {

    const getNormalMass = (j) => {
        let res = Object.entries(tableData[j]).filter((value) => ((value[0].length === 3 || value[0].length === 2) && value[0] !== "key"))

        for (let i = 1; i < tableTitle.length - 1; i++) {
            let k = 0
            res.forEach(element => {
                if (element[0] === `m${i}`) {
                    k = 1
                }
            });
            if (k === 0) {
                res.push([`m${i}`, 0])
            }
        }
        return res
    }


    const getMergedObj = (j) => {
        let massObj = Object.entries(tableData[j]).filter((value) => ((value[0].length === 3 || value[0].length === 2) && value[0] !== "key")).map(value => Object.fromEntries([value]))
        return massObj.reduce((result, current) => {
            return Object.assign(result, current);
        }, {});
    }


    return (
        <div className={classes.main__block__style}>
            <div className={classes.first__line__style}>
                {tableTitle.map((value, i) => <span key={i} className={classes.span__style}> {value['title']}</span>)}
            </div>

            {tableData.map((value, j) => {
                const res = getNormalMass(j)
                return (
                    < div style={{ backgroundColor: j % 2 === 0 ? '#EDEDED' : '#ffffff' }
                    } key={j} className={classes.first__line__style} >
                        <span className={classes.span__style}>{value["name"]}</span>

                        {res.map((value, i) => <span key={i} className={classes.span__style}>{getMergedObj(j)[`m${i + 1}`]}</span>)}
                        <span className={classes.span__style}>{value["grade"]}</span>
                    </div >
                )
            }

            )}

        </div >
    )
}

export default TableForMark