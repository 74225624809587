// import axios from "axios";
import env from '../../package.json'
import $api from "http";


export default class PortfolioService {

    static getPortfolio = async () => {
        const response = await $api.get(`${env.nodeHost}/api/portfolio/`)
        return response.data
    }
}