import classes from "./infoAboutCommandPage.module.css"
import "@fontsource/roboto"
import InputBlock from "components/UI/inputBlock/InputBlock"
import { useState, useRef } from 'react'
import Description from "components/UI/description/Description"
import Button from "components/UI/buttons/button/Button"
import 'rc-tooltip/assets/bootstrap_white.css';
import PackMarkButton from "components/UI/circleButtonMark/packMarkButton/PackMarkButton"
import { useDeviceType } from "components/hooks/useDeviceType"
import CommandService from "services/CommandService"
import NotificationManager from 'react-notifications/lib/NotificationManager';
import Strings from 'values/Strings';
import { useFetching } from 'components/hooks/useFetching';
import { useDispatch } from 'react-redux';
import { changeLoaderStatus } from "redux/slice/loaderSlice"
import { useEffect } from 'react';
import Loader from "components/UI/loader/Loader"
import Notifications from "components/UI/popupNotifications/Notifications"
import TitleMain from "components/UI/titleMain/TitleMain";

const InfoAboutCommandPage = () => {
    const device = useDeviceType()
    const ref = useRef()
    const [isSaved, setIsSaved] = useState(false);
    const dispatch = useDispatch()
    const [aboutCommand, setAboutCommand] = useState({ additional_material: '', code_review: '', comment: '', presentation: '' })

    const [command, setCommand] = useState([])
    const [commandList, setCommandList] = useState([])
    const [currentCommand, setCurrentCommand] = useState('')

    const notificationError = (error) => {
        if (error.length > 0) {
            NotificationManager.error(JSON.stringify(error), Strings.notificationsErrorTitle, 5000);
        }
    }

    const [fetchingAboutCommand, loadingAboutCommand, errorAboutCommand] = useFetching(async (id) => {
        try {
            const res = await CommandService.getCommandInfo(id)
            setAboutCommand(old => ({ ...old, ...res }))
            dispatch(changeLoaderStatus(false))
        } catch (error) {
            notificationError(error)
        }
    })

    const [fetchingUpdateInfo, loadingUpdateInfo, errorUpdateInfo] = useFetching(async () => {
        try {
            const res = await CommandService.updateCommandInfo(aboutCommand)
            NotificationManager.success(Strings.notificationsDataUpdate, Strings.notificationsSuccessTitle, 2500)
        } catch (error) {
            notificationError(error)
        }
    })

    const [fetchingCommands, loadingCommands, errorCommands] = useFetching(async (id) => {
        try {
            const res = await CommandService.getCurrentCommands()
            setCommandList(CommandService.getCurrentCommandsNamesForInputList(res))
            setCommand(res)
        } catch (error) {
            notificationError(error)
        }
    })

    const choiceCommand = async (_, i) => {
        setAboutCommand({ additional_material: '', code_review: '', comment: '', presentation: '' })
        const commandId = command[i]?.command_id
        fetchingAboutCommand(commandId)
        setAboutCommand(old => ({ ...old, id: commandId }))
        setCurrentCommand(commandList[i])
    }

    useEffect(() => {
        fetchingCommands()
    }, [])

    const copyValueInBiffer = (event) => {
        navigator.clipboard.writeText(event?.target?.value)
        NotificationManager.success(Strings.notificationsCopyData, Strings.notificationsSuccessTitle, 2500)

    }

    return (
        <Loader>
            <div className={classes.container}>
                <TitleMain text={"Доп. информация"} jsc="center" fns="1.5vw" elpSize="0.9vw" />
                <div ref={ref} className={classes.main__block__info}>
                    {device === "phone"
                        ? <div style={{ display: "flex" }}>
                            <InputBlock readOnly={true} indexStatus={true} placeholder={"Команда"} list={commandList} value={currentCommand} setValue={choiceCommand} refMainContainer={ref}><img src={require("assets/images/arrow.jpg")} /></InputBlock>
                        </div>
                        : <div style={{ display: "flex" }}>
                            <InputBlock placeholder={"Команда"} readOnly={true} indexStatus={true} list={commandList} value={currentCommand} setValue={choiceCommand} refMainContainer={ref}> <img src={require("assets/images/arrow.jpg")} /></InputBlock>
                        </div>
                    }
                    <div className={classes.repo__and__grade__style}>
                        {device === "phone"
                            ? <div className={classes.repo__style}>
                                <InputBlock onClick={copyValueInBiffer} readOnly={true} placeholder={"Доп. ссылка"} value={aboutCommand?.additional_material ? aboutCommand.additional_material : ''} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                            </div>
                            : <div className={classes.repo__style}>
                                <InputBlock placeholder={"Доп. ссылка"} onClick={copyValueInBiffer} readOnly={true} width={53} onListStatus={false} value={aboutCommand?.additional_material ? aboutCommand.additional_material : ''} ><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                            </div>
                        }
                        <PackMarkButton setIsSaved={setIsSaved} idButton={aboutCommand.code_review} setValue={setAboutCommand} name={"code_review"} textWidth={false} flex={true} borderTop={false} text={""} hint={["Оценка стека технологий,", <br key={1} />, "полноты решения", <br key={2} />, "и правильности реализации"]} />
                    </div>
                    <div className={classes.repo__and__grade__style}>
                        {device === "phone"
                            ? <div className={classes.repo__style}>
                                <InputBlock onClick={copyValueInBiffer} readOnly={true} placeholder={"Презентация"} value={aboutCommand?.presentation ? aboutCommand.presentation : ''} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                            </div>
                            : <div className={classes.repo__style}>
                                <InputBlock placeholder={"Презентация"} onClick={copyValueInBiffer} readOnly={true} onListStatus={false} value={aboutCommand?.presentation ? aboutCommand.presentation : ''}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                            </div>
                        }
                    </div>
                    <div className={classes.repo__and__grade__style}>
                        {device === "phone"
                            ? <div className={classes.repo__style}>
                                <Description value={aboutCommand.comment ? aboutCommand.comment : ''} setValue={char => setAboutCommand(old => ({ ...old, comment: char }))} placeholder={"Отзыв по работе"} />
                            </div>
                            : <div className={classes.repo__style}>
                                <Description placeholder={"Отзыв"} value={aboutCommand.comment ? aboutCommand.comment : ''} setValue={char => setAboutCommand(old => ({ ...old, comment: char }))} />
                            </div>
                        }
                    </div>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "end", marginTop: "2vw" }}>
                        <Button onClick={fetchingUpdateInfo}>Завершить</Button>
                    </div>
                </div>
            </div>
            <Notifications />
        </Loader>
    )
}

export default InfoAboutCommandPage