import classes from "./AuthBlock.module.css"
import "@fontsource/roboto";
import AuthImage from "./authImage/AuthImage";
import Notifications from "../popupNotifications/Notifications";
import TitleMain from "../titleMain/TitleMain";

const AuthBlock = ({ children, yandexOAuth, setTokenInfo, setUserInfo, ...props }) => {

    const sizeContainer = 5
    const sizeIcon = 4

    return (
        <div className={classes.main__block__auth__style}>
            <TitleMain text={"Авторизоваться"} jsc="center" />
            <div className={classes.auth__button__style}>
                <div onClick={yandexOAuth}>
                    <AuthImage style={{ width: `${sizeIcon}vw`, height: `${sizeIcon}vw` }} widthContainer={`${sizeContainer}vw`} heightContainer={`${sizeContainer}vw`} img={"https://disk.yandex.ru/i/qDztvuZ3OfkPXA"} />
                    <span>Яндекс</span>
                </div>
            </div>
            <Notifications />
        </div>

    )
}

export default AuthBlock