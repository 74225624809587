import classes from "./ProfileOccasion.module.css"
import "@fontsource/roboto";
import { useDeviceType } from "components/hooks/useDeviceType"
import TitleMain from "components/UI/titleMain/TitleMain";
// import Download from "assets/icon/download_cert.png"
import { RiTeamFill } from "react-icons/ri";
import { FaBriefcase } from "react-icons/fa";
import { BiSolidCategory } from "react-icons/bi";
import { GiTrophyCup } from "react-icons/gi";
import { IoOpenOutline } from "react-icons/io5";
import { FcDiploma2 } from "react-icons/fc";
import { TbFileCertificate } from "react-icons/tb";



const ProfileOccasion = ({ diploma, certificat, occasion, task, command, category, result }) => {
    const device = useDeviceType()
    return (
        device === "phone"
            ? <div className={classes.main__block__style}>
                <div className={classes.profileOccasion__inblock}>
                    <div className={classes.title__block__style}>
                        <TitleMain text={`${occasion.name} ${occasion.date_range}`} jsc="center" fns="1.2vw" elpSize="0.9vw" />
                    </div>
                    <div className={classes.info__block__style}>
                        <div className={classes.info__block__first}>
                            <RiTeamFill className={classes.icon} />
                            {/* team */}
                            <span>Команда: <a className={classes.style__profile__data}>{command}</a></span>
                        </div>

                        <div className={classes.info__block__second}>
                            {/* case */}
                            <FaBriefcase className={classes.icon} />
                            <span>Кейс: <a className={classes.style__profile__data}>{task}</a></span>
                        </div>

                        <div className={classes.info__block__third}>
                            {/* category */}
                            <BiSolidCategory className={classes.icon} />
                            <span>Категория: <a className={classes.style__profile__data}>{category}</a></span>
                        </div>

                        <div className={classes.info__block__fourth}>
                            {/* place */}
                            <GiTrophyCup className={classes.icon} />
                            <span>{result && "Место : "}<a className={classes.style__profile__data}>{result ? result : 'Участник'}</a></span>
                        </div>
                    </div>
                </div>
                <div className={classes.info__block__download}>
                    {/* <IoOpenOutline className={classes.icon} title="Сертификат" /> */}
                    {certificat && <TbFileCertificate onClick={() => window.open(certificat.url, '_blank')} className={`${classes.icon} ${classes.icon_download}`}
                        style={{ fill: "rgba(255,255,255,0)", color: 'var(--main-color)' }} title="Сертификат" />}
                    {diploma && <FcDiploma2 onClick={() => window.open(diploma.url, '_blank')} className={`${classes.icon} ${classes.icon_download}`} title="Диплом" />}
                </div>
            </div >
            :
            <div className={classes.main__block__style}>
                <div className={classes.profileOccasion__inblock}>
                    <div className={classes.title__block__style}>
                        <TitleMain text={`${occasion.name} ${occasion.date_range}`} jsc="center" fns="1vw" elpSize="0.8vw" />
                    </div>
                    {/* --------------------------------------------------Блок данных о мероприятии, команда, кейс, категория, место */}
                    <div className={classes.info__block__style}>
                        <div className={classes.info__block__first}>
                            <RiTeamFill className={classes.icon} />
                            {/* team */}
                            <span>Команда: <a className={classes.style__profile__data}>{command}</a></span>
                        </div>
                        <div className={classes.info__block__line} />
                        <div className={classes.info__block__second}>
                            {/* case */}
                            <FaBriefcase className={classes.icon} />
                            <span>Кейс: <a className={classes.style__profile__data}>{task}</a></span>
                        </div>
                        <div className={classes.info__block__line} />
                        <div className={classes.info__block__third}>
                            {/* category */}
                            <BiSolidCategory className={classes.icon} />
                            <span>Категория: <a className={classes.style__profile__data}>{category}</a></span>
                        </div>
                        <div className={classes.info__block__line} />
                        <div className={classes.info__block__fourth}>
                            {/* place */}
                            <GiTrophyCup className={classes.icon} />
                            <span>{result && "Место : "}<a className={classes.style__profile__data}>{result ? result : 'Участник'}</a></span>
                        </div>
                    </div>
                </div >
                {/* --------------------------------------------------Блок для скачивания сертификата */}
                <div className={classes.info__block__download}>
                    {/* <IoOpenOutline className={classes.icon} title="Сертификат" /> */}
                    {Object.keys(certificat).length > 0 && <TbFileCertificate onClick={() => window.open(certificat.url, '_blank')} className={`${classes.icon} ${classes.icon_download}`}
                        style={{ fill: "rgba(255,255,255,0)", color: 'var(--main-color)' }} title="Сертификат" />}
                    {Object.keys(diploma).length > 0 && <FcDiploma2 onClick={() => window.open(diploma.url, '_blank')} className={`${classes.icon} ${classes.icon_download}`} title="Диплом" />}
                </div>
            </div>
    )
}

export default ProfileOccasion