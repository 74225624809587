import React, { useState } from 'react';
import classes from "./TableWithPart.module.css"
import { useDeviceType } from 'components/hooks/useDeviceType';

export default function TableWithPart(props) {
    const device = useDeviceType()


    // let arr = [{
    //     user: "Сазиков Ростислав Станиславович", email: "sazikov@edro.su", phone: "+7 (999) 571-10-31", role_name: "Разработчик frontend", status: "Студент"
    // }, {
    //     user: "Тунян Эдмон Гарникович", email: "tunyan.edmon.2015@gmai.com", phone: "+7 (777) 777-77-77", role_name: "Full-stack", status: "Студент"
    // }, {
    //     user: "Тунян Эдмон Гарникович", email: "tunyan.edmon.2015@gmai.com", phone: "+7 (777) 777-77-77", role_name: "Full-stack", status: "Студент"
    // }, {
    //     user: "Тунян Эдмон Гарникович", email: "tunyan.edmon.2015@gmai.com", phone: "+7 (777) 777-77-77", role_name: "Full-stack", status: "Студент"
    // },]

    let titleTable = ["ФИО", "Почта", "Телефон", "Роль", "Статус"]

    return (
        <div>
            {device === "phone" ?
                <div>
                    <div className={classes.style__column__name}>
                        {titleTable.slice(0, 2).map((value, key) => <span key={key} className={classes.style__text}>{value}</span>)}
                    </div>
                    {props &&
                        props.table.map((dt, key) => <div key={key} className={classes.style__div}>
                            <span className={classes.style__text__block}>{dt.user}</span>
                            <span className={classes.style__text__block} onClick={() => window.open(`mailto:${dt.email}`)}>{dt.email}</span>
                        </div>)}
                    <div className={classes.style__column__name}>
                        {titleTable.slice(2, 5).map((value, key) => <span key={key} className={classes.style__text}>{value}</span>)}
                    </div>
                    {props && props.table.map((any, key) => <div key={key} className={classes.style__div}>
                        <span className={classes.style__text__block}>{any.phone}</span>
                        <span className={classes.style__text__block}>{any.role_name}</span>
                        <span className={classes.style__text__block}>{any.status}</span>
                    </div>)}
                </div>

                :

                <div>
                    <div className={classes.style__column__name}>
                        {titleTable.map((value, key) => <span key={key} className={classes.style__text}>{value}</span>)}
                    </div>
                    {props.table.map((any, key) => <div key={key} className={classes.style__div}>

                        <span className={classes.style__text__block}>{any["user"]}</span>
                        <span className={classes.style__text__block}>{any["email"]}</span>
                        <span className={classes.style__text__block}>{any["phone"]}</span>
                        <span className={classes.style__text__block}>{any["role_name"]}</span>
                        <span className={classes.style__text__block}>{any["status"]}</span>
                    </div>)}
                </div>
            }


        </div >
    );
}