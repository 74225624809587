import classes from "./CreateOccasion.module.css"
import "@fontsource/roboto"
import InputBlock from "components/UI/inputBlock/InputBlock"
import { useState, useRef } from 'react'
import Description from "components/UI/description/Description"
import Button from "components/UI/buttons/button/Button"
import 'rc-tooltip/assets/bootstrap_white.css';
import { useDeviceType } from "components/hooks/useDeviceType"
import { timeValidator } from "components/hooks/Vlidator"
import InputTag from "components/UI/adminPage/inputTag/InputTag"
import TitleMain from "components/UI/titleMain/TitleMain";


const CreateOccasion = ({ createOccasion }) => {
    const device = useDeviceType()
    const [event, setOccasion] = useState("")
    const [name, setName] = useState("")
    const [date, setDate] = useState("")
    const [dateFinish, setDateFinish] = useState("")
    const [time, setTime] = useState(null)
    const [timeFinish, setTimeFinish] = useState(null)
    const [place, setPlace] = useState("")
    const [location, setLocation] = useState("")
    const [description, setDescription] = useState("")
    const [timeManagment, setTimeManagment] = useState("")
    const [minors, setMinors] = useState("")
    const [condition, setCondition] = useState("")
    const [criteria, setСriteria] = useState("")
    const [bigDeskCount, setBigDeskCount] = useState([])
    const [smallDeskCount, setSmallDeskCount] = useState([])
    const [checkPointQuestions, setCheckPointQuestions] = useState([])
    const [finalAssessmentQuestion, setFinalAssessmentQuestion] = useState([])
    const [checkPointCount, setCheckPointCount] = useState(2)
    const [category, setCategory] = useState([])
    // const [category, setCategory] = useState([])
    const refLabel = useRef(null)
    const ref = useRef()

    const clearInputData = () => {
        setOccasion("")
        setName("")
        setDate("")
        setTime(null)
        setDateFinish("")
        setTimeFinish(null)
        setPlace("")
        setLocation("")
        setTimeManagment("")
        setMinors("")
        setCondition("")
        setСriteria("")
        setDescription("")
        setBigDeskCount("")
        setSmallDeskCount("")
        setCheckPointCount("")
        setCheckPointQuestions([])
        setFinalAssessmentQuestion([])
        setCategory([])
    }

    const createObjectValue = () => {
        const validTime = timeValidator(time)
        const validTimeFinish = timeValidator(timeFinish)
        const data = {
            check_point_count: checkPointCount,
            name: name,
            place: place,
            location: location,
            time_managment: timeManagment,
            minors: minors,
            condition: condition,
            criteria: criteria,
            description: description,
            on_start: false,
            on_task_publish: false,
            finish_date: dateFinish,
            finish_time: validTimeFinish,
            date: date,
            time: validTime,
            check_point_questions: JSON.stringify(checkPointQuestions),
            final_assessment_question: JSON.stringify(finalAssessmentQuestion),
            desk: { small: smallDeskCount.join(','), big: bigDeskCount.join(',') },
        }
        createOccasion({ ...data, category_participant: category }, clearInputData)
    }


    return (
        <div ref={ref} className={classes.events__create__style}>
            <TitleMain text={"Создание мероприятия"} jsc="center" fns="1.5vw" elpSize="0.9vw" />
            <ul>
                {device === "phone"
                    ? <li>
                        <InputBlock readOnly={true} placeholder={"Мероприятия"} onListStatus={true} list={["Хакатон"]} value={event} setValue={setOccasion} refMainContainer={ref}><img src={require("assets/images/arrow.jpg")} /></InputBlock>
                    </li>
                    : <li>
                        <InputBlock placeholder={"Мероприятия"} readOnly={true} onListStatus={true} list={["Хакатон"]} value={event} setValue={setOccasion} refMainContainer={ref}><img src={require("assets/images/arrow.jpg")} /></InputBlock>
                    </li>
                }

                {device === "phone"
                    ? <li className={classes.li__style}>
                        <InputBlock placeholder={"Название"} value={name} setValue={setName} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                    </li>
                    : <li className={classes.li__style}>
                        <InputBlock placeholder={"Название"} onListStatus={false} value={name} setValue={setName}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                    </li>
                }
                {
                    <li className={classes.li__style}>
                        <InputTag placeholder={"Категории кейсов"} keys={['name', 'description']} tags={category} setTags={setCategory} />
                    </li>
                }

                {
                    <li className={classes.li__style}>
                        <div className={classes.select__container}>
                            <label ref={refLabel} className={classes.inputBlock__lable__focus}>Кол-во контрольных точек</label>
                            <input type="number" value={checkPointCount} onChange={e => setCheckPointCount(e.target.value)} min="1" max="5" className={classes.createOccasion__checkpointNum} />
                        </div>
                    </li>
                }
                {
                    <li className={classes.li__style}>
                        <InputTag placeholder={"Вопросы для чек-контрольных точек"} keys={['name', 'grade']} tags={checkPointQuestions} setTags={setCheckPointQuestions} />
                    </li>
                }
                {
                    <li className={classes.li__style}>
                        <InputTag placeholder={"Вопросы для финал. оценки"} keys={['name', 'grade']} tags={finalAssessmentQuestion} setTags={setFinalAssessmentQuestion} />
                    </li>
                }
                {device === "phone"
                    ? <li className={classes.li__style}>
                        <InputBlock type="date" placeholder={"Дата начала"} value={date} setValue={setDate} onListStatus={false}><img src={require("assets/icon/calendar.jpg")} /></InputBlock>
                    </li>
                    : <li className={classes.li__style}>
                        <InputBlock placeholder={"Дата начала"} type="date" onListStatus={false} value={date} setValue={setDate}><img src={require("assets/icon/calendar.jpg")} /></InputBlock>
                    </li>
                }
                {device === "phone"
                    ? <li className={classes.li__style}>
                        <InputBlock type="time" placeholder={"Время начала"} className={classes.time__st} value={time} setValue={setTime} onListStatus={false}><img src={require("assets/icon/time.jpg")} /></InputBlock>
                    </li>
                    : <li className={classes.li__style}>
                        <InputBlock placeholder={"Время начала"} type="time" onListStatus={false} value={time} setValue={setTime}><img src={require("assets/icon/time.jpg")} /></InputBlock>
                    </li>
                }

                {device === "phone"
                    ? <li className={classes.li__style}>
                        <InputBlock type="date" placeholder={"Дата окончания"} value={dateFinish} setValue={setDateFinish} onListStatus={false}><img src={require("assets/icon/calendar.jpg")} /></InputBlock>
                    </li>
                    : <li className={classes.li__style}>
                        <InputBlock placeholder={"Дата окончания"} type="date" onListStatus={false} value={dateFinish} setValue={setDateFinish}><img src={require("assets/icon/calendar.jpg")} /></InputBlock>
                    </li>
                }
                {device === "phone"
                    ? <li className={classes.li__style}>
                        <InputBlock type="time" placeholder={"Время"} className={classes.time__st} value={timeFinish} setValue={setTimeFinish} onListStatus={false}><img src={require("assets/icon/time.jpg")} /></InputBlock>
                    </li>
                    : <li className={classes.li__style}>
                        <InputBlock placeholder={"Время окончания"} type="time" onListStatus={false} value={timeFinish} setValue={setTimeFinish}><img src={require("assets/icon/time.jpg")} /></InputBlock>
                    </li>
                }

                {device === "phone"
                    ? <li className={classes.li__style}>
                        <InputBlock placeholder={"Место"} value={place} setValue={setPlace} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                    </li>
                    : <li className={classes.li__style}>
                        <InputBlock placeholder={"Место"} onListStatus={false} value={place} setValue={setPlace}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                    </li>
                }

                {device === "phone"
                    ? <li className={classes.li__style}>
                        <InputBlock placeholder={"Карта API"} value={location} setValue={setLocation} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                        <a href="https://yandex.ru/map-constructor/">Создание карты</a>
                    </li>

                    : <li className={classes.li__style}>
                        <InputBlock placeholder={"Карта API"} onListStatus={false} value={location} setValue={setLocation}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                        <a target="_blank" className={classes.create__map} href="https://yandex.ru/map-constructor/">Создание карты для вставки (вставлять только ссылку src)</a>
                    </li>
                }

                {device === "phone"
                    ? <li className={classes.li__style}>
                        <Description value={description} setValue={setDescription} placeholder={"Описание"} />
                    </li>
                    : <li className={classes.li__style}>
                        <Description placeholder={"Описание"} value={description} setValue={setDescription} />
                    </li>
                }

                {device === "phone"
                    ? <li className={classes.li__style}>
                        <Description value={timeManagment} setValue={setTimeManagment} placeholder={"События по времени"} />
                    </li>
                    : <li className={classes.li__style}>
                        <Description placeholder={"События по времени"} value={timeManagment} setValue={setTimeManagment} />
                    </li>
                }

                {device === "phone"
                    ? <li className={classes.li__style}>
                        <InputBlock placeholder={"Ссылка для несовершен."} value={minors} setValue={setMinors} onListStatus={false}><img src={require("assets/icon/copy.jpg")} /></InputBlock>
                    </li>
                    : <li className={classes.li__style}>
                        <InputBlock placeholder={"Ссылка для несовершен."} onListStatus={false} value={minors} setValue={setMinors}><img src={require("assets/icon/copy.jpg")} /></InputBlock>
                    </li>
                }

                {device === "phone"
                    ? <li className={classes.li__style}>
                        <InputBlock placeholder={"Ссылка положения мероприятия"} value={condition} setValue={setCondition} onListStatus={false}><img src={require("assets/icon/copy.jpg")} /></InputBlock>
                    </li>
                    : <li className={classes.li__style}>
                        <InputBlock placeholder={"Ссылка положения мероприятия"} onListStatus={false} value={condition} setValue={setCondition}><img src={require("assets/icon/copy.jpg")} /></InputBlock>
                    </li>
                }


                {device === "phone"
                    ? <li className={classes.li__style}>
                        <InputBlock placeholder={"Ссылка критерии оценивания"} value={criteria} setValue={setСriteria} onListStatus={false}><img src={require("assets/icon/copy.jpg")} /></InputBlock>
                    </li>
                    : <li className={classes.li__style}>
                        <InputBlock placeholder={"Ссылка критерии оценивания"} onListStatus={false} value={criteria} setValue={setСriteria}><img src={require("assets/icon/copy.jpg")} /></InputBlock>
                    </li>
                }
                <li className={classes.li__style}>
                    <InputTag placeholder={"Столы маленьких команд"} tags={smallDeskCount} setTags={setSmallDeskCount} />
                </li>
                <li className={classes.li__style}>
                    <InputTag placeholder={"Столы больших команд"} tags={bigDeskCount} setTags={setBigDeskCount} />
                </li>
            </ul>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "end" }}>
                <Button onClick={createObjectValue}>Создать</Button>
            </div>
        </div >
    )
}

export default CreateOccasion