import classes from "./SecondBlockMain.module.css"
import "@fontsource/roboto"
import Loader from "components/UI/loader/Loader"
import Code from "assets/images/main/code.png"
import Data from "assets/images/main/data.png"
import Laptop from "assets/images/main/laptop.png"
import Sien from "assets/images/main/sien.png"
import Stat from "assets/images/main/stat.png"
import Ux from "assets/images/main/ux.png"
import { useDeviceType } from "components/hooks/useDeviceType"

const SecondBlockMain = ({ secondBlockAnimationRefTop, secondBlockAnimationRefBottom, secondBlockAnimationRefMiddle }) => {


    const device = useDeviceType()


    return (
        <Loader>
            {device === "phone" ?
                <div className={classes.main__style__for__block}>

                    <div className={classes.block__title__style}>
                        <div className={classes.ellipse__style}>
                        </div>
                        <h1 className={classes.title__style}>
                            Для кого
                        </h1>
                    </div>

                    <div className={`${classes.block__info__style} ${classes.animation_ux}`} ref={secondBlockAnimationRefTop}>

                        <div className={`${classes.block__with__info} ${classes.left}`}>
                            <img src={Code} className={classes.img__style} />
                            <h5 className={classes.h5__style}>
                                Front, Back,<br />
                                Fullstack разработчики
                            </h5>
                        </div>

                        <div className={`${classes.block__with__info} ${classes.right}`}>
                            <img src={Sien} className={classes.img__style} />
                            <h5 className={classes.h5__style}>
                                Data scientists,<br />
                                Data engineers
                            </h5>
                        </div>
                    </div>




                    <div className={`${classes.block__info__style} ${classes.animation_ux}`} ref={secondBlockAnimationRefMiddle}>
                        <div className={`${classes.block__with__info} ${classes.left}`}>
                            <img src={Data} className={classes.img__style} />
                            <h5 className={classes.h5__style}>
                                Product,<br />
                                Project-менеджеры
                            </h5>
                        </div>

                        <div className={`${classes.block__with__info} ${classes.right}`}>
                            <img src={Ux} className={classes.img__style} />
                            <h5 className={classes.h5__style}>
                                UX / UI<br />
                                дизайнеры
                            </h5>
                        </div>
                    </div>
                    <div className={`${classes.block__info__style} ${classes.animation_ux}`} ref={secondBlockAnimationRefBottom}>
                        <div className={`${classes.block__with__info} ${classes.left}`}>
                            <img src={Stat} className={classes.img__style} />
                            <h5 className={classes.h5__style}>
                                Аналитики<br />
                                и маркетологи
                            </h5>
                        </div>

                        <div className={`${classes.block__with__info} ${classes.right}`}>
                            <img src={Laptop} className={classes.img__style} />
                            <h5 className={classes.h5__style}>
                                IT-<br />
                                предприниматели
                            </h5>
                        </div>
                    </div>
                </div>

                :
                <div className={classes.main__style__for__block}>

                    <div className={classes.block__title__style}>
                        <div className={classes.ellipse__style}>
                        </div>
                        <h1 className={classes.title__style}>
                            Для кого
                        </h1>
                    </div>

                    <div className={`${classes.block__info__style} ${classes.animation_ux}`} ref={secondBlockAnimationRefTop}>

                        <div className={`${classes.block__with__info} ${classes.left}`}>
                            <img src={Code} className={classes.img__style} />
                            <h5 className={classes.h5__style}>
                                Front, Back,<br />
                                Fullstack разработчики
                            </h5>
                        </div>


                        <div className={`${classes.block__with__info} ${classes.top}`}>
                            <img src={Sien} className={classes.img__style} />
                            <h5 className={classes.h5__style}>
                                Data scientists,<br />
                                Data engineers
                            </h5>
                        </div>

                        <div className={`${classes.block__with__info} ${classes.right}`}>
                            {/* <div className={`${classes.block__with__info} ${secondBlockAnimationStatus && classes.animation_ux}`}> */}
                            <img src={Ux} className={classes.img__style} />
                            <h5 className={classes.h5__style}>
                                UX / UI<br />
                                дизайнеры
                            </h5>
                        </div>

                    </div>

                    <div className={`${classes.block__info__style} ${classes.animation_ux}`} ref={secondBlockAnimationRefBottom}>
                        <div className={`${classes.block__with__info} ${classes.left}`}>
                            <img src={Data} className={classes.img__style} />
                            <h5 className={classes.h5__style}>
                                Product,<br />
                                Project-менеджеры
                            </h5>
                        </div>

                        <div className={`${classes.block__with__info} ${classes.bottom}`}>
                            <img src={Stat} className={classes.img__style} />
                            <h5 className={classes.h5__style}>
                                Аналитики<br />
                                и маркетологи
                            </h5>
                        </div>

                        <div className={`${classes.block__with__info} ${classes.right}`}>
                            <img src={Laptop} className={classes.img__style} />
                            <h5 className={classes.h5__style}>
                                IT-<br />
                                предприниматели
                            </h5>
                        </div>
                    </div>

                </div>
            }
        </Loader>
    )
}

export default SecondBlockMain