import classes from './DragAndDrop.module.css'
import { useEffect } from 'react';
import { useRef } from 'react';
import { useDeviceType } from 'components/hooks/useDeviceType';


function DragAndDropPlaceholder({ dragZoneRef, children, right = null, width = null, height = null, fullOnMobile = true, imagePreview = null }) {
    const inputImageRef = useRef(null)
    useEffect(() => {
        if (inputImageRef && inputImageRef.current) {
            inputImageRef.current.addEventListener('change', () => {
                const file = [...inputImageRef.current.files][0]
                imagePreview(file)
            })
        }
    }, []);

    const device = useDeviceType()

    return (
        <div ref={dragZoneRef} className={classes.down__block__style} style={{ width: (device === 'phone' && fullOnMobile) ? '100%' : '100%' }}>
            {/* <div ref={dragZoneRef} className={classes.down__block__style} style={{ width: (device === 'phone' && fullOnMobile) ? '100%' : (width !== null ? width : '20vw') }}> */}
            <label htmlFor="file" className={classes.label__style}>
                <div className={classes.drop__down__style} style={{
                    height: (height !== null ? height : '13vw'),
                }}>
                    <span>{children}</span>
                    {/* //*------------------------------- */}
                    <input ref={inputImageRef} id="file" type="file" style={{ display: 'none', }} />
                </div>
            </label>
        </div>
    );
}

export default DragAndDropPlaceholder;