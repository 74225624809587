import classes from "./TicketParticipation.module.css"
import IconWithText from "components/UI/iconWithText/IconWithText"
import InputBlock from "components/UI/inputBlock/InputBlock"
import Button from "components/UI/buttons/button/Button"
import "@fontsource/roboto"
import Tooltip from "rc-tooltip"
import 'rc-tooltip/assets/bootstrap_white.css';
import { useDeviceType } from "components/hooks/useDeviceType"
import { useRef, useState } from "react"
import TitleMain from "../titleMain/TitleMain"

function TicketParticipation({ close, userInfo, preRegistration, action, role = [], commandName = '' }) {
    const device = useDeviceType()

    const [roleFilter, setRoleFilter] = useState(role)
    const [currentRole, setCurrentRole] = useState(null)
    const [currentRoleText, setCurrentRoleText] = useState('')

    const ref = useRef()

    const changeRole = (data, index) => {
        data.length === 0 ? setRoleFilter(role) : setRoleFilter(role.filter(item => item.role_name.toLowerCase().includes(data.toLowerCase())))
        setCurrentRoleText(data)
        if (index !== undefined) {
            setCurrentRole(roleFilter[index])
            setCurrentRoleText(roleFilter[index].role_name)
        }
    }

    return (
        <div className={classes.main__block__ticket__style} ref={ref}>
            {/* <h3>Заявка на участие</h3> */}
            <TitleMain text={action === 'commandToken' ? "Присоединиться к команде" : 'Заявка на участие'} jsc="center" />
            <ul>
                {/* {device === "phone"
                    ? <li className={classes.cursor}>
                        <InputBlock cursorPointer={true} readOnly={true} setValue={char => setCommand(old => ({ ...old, surname: char }))} value={command.surname.length ? command.surname : userInfo.surname} placeholder={"Фамилия"} heigBlock={"11vw"} heigInt={"7vw"} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                    </li>
                    :
                    <li className={classes.cursor}> */}
                {/* <div>
                            <Tooltip placement="top" trigger={'hover'} overlay={<span style={{ fontSize: "0.7vw", fontFamily: "Roboto" }} className={classes.tooltip__style}>В данном поле <br />заполняется фамилия участника</span>}>
                                <IconWithText size={"0.5vw"} style={{ marginRight: "0.8vw" }} text={"Фамилия"}><img src={require("assets/icon/user.png")} /></IconWithText>
                            </Tooltip>
                        </div> */}
                {/* <InputBlock placeholder={"Фамилия"} cursorPointer={true} readOnly={true} setValue={char => setCommand(old => ({ ...old, surname: char }))} value={command.surname.length ? command.surname : userInfo.surname} heigBlock={"2.3vw"} heigInt={"1.4vw"} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                    </li>
                } */}

                {/* {device === "phone"
                    ? <li className={classes.cursor}>

                        <InputBlock cursorPointer={true} readOnly={true} setValue={char => setCommand(old => ({ ...old, name: char }))} value={command.name.length > 0 ? command.name : userInfo.name} placeholder={"Имя"} heigBlock={"11vw"} heigInt={"7vw"} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                    </li>
                    :
                    <li className={classes.cursor}> */}
                {/* <div>
                            <Tooltip placement="top" trigger={'hover'} overlay={<span style={{ fontSize: "0.7vw", fontFamily: "Roboto" }} className={classes.tooltip__style}>В данном поле <br />заполняется имя участника</span>}>
                                <IconWithText size={"0.5vw"} style={{ marginRight: "0.8vw" }} text={"Имя"}><img src={require("assets/icon/user.png")} /></IconWithText>
                            </Tooltip>
                        </div> */}

                {/* <InputBlock placeholder={"Имя"} cursorPointer={true} readOnly={true} setValue={char => setCommand(old => ({ ...old, name: char }))} value={command.name.length > 0 ? command.name : userInfo.name} heigBlock={"2.3vw"} heigInt={"1.4vw"} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                    </li>
                } */}

                {commandName.length > 0 &&
                    (device === "phone"
                        ? <li className={classes.cursor}>
                            <InputBlock cursorPointer={true} readOnly={true} placeholder={"Команда"} value={commandName} heigBlock={"11vw"} heigInt={"7vw"} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                        </li>
                        :
                        <li className={classes.cursor}>
                            {/* <div>
                            <Tooltip placement="top" trigger={'hover'} overlay={<span style={{ fontSize: "0.7vw", fontFamily: "Roboto" }} className={classes.tooltip__style}>В данном поле <br />заполняется отчество участника</span>}>
                                <IconWithText size={"0.5vw"} style={{ marginRight: "0.8vw" }} text={"Отчество"}><img src={require("assets/icon/user.png")} /></IconWithText>
                            </Tooltip>
                        </div> */}
                            <InputBlock placeholder={"Команда"} cursorPointer={true} readOnly={true} heigBlock={"2.3vw"} value={commandName} heigInt={"1.4vw"} onListStatus={false}><img src={require("assets/icon/pencil.jpg")} /></InputBlock>
                        </li>)
                }

                {roleFilter.length > 0 && device === "phone"
                    ? <li>
                        <InputBlock refMainContainer={ref} setValue={changeRole} heigBlock={"11vw"} heigInt={"7vw"} indexStatus={true} onListStatus={true} list={roleFilter.map(item => item.role_name)}
                            value={currentRoleText} placeholder={"Роль"}><img src={require("assets/images/arrow.jpg")} /></InputBlock>
                    </li>
                    :
                    <li>
                        {/* <div>
                            <Tooltip placement="top" trigger={'hover'} overlay={<span style={{ fontSize: "0.7vw", fontFamily: "Roboto" }} className={classes.tooltip__style}>В данном поле <br />заполняется отчество участника</span>}>
                                <IconWithText size={"0.5vw"} style={{ marginRight: "0.8vw" }} text={"Роль"}><img src={require("assets/icon/role.png")} /></IconWithText>
                            </Tooltip>
                        </div> */}
                        <InputBlock placeholder={"Роль"} refMainContainer={ref} setValue={changeRole} width={33} heigBlock={"2.3vw"} heigInt={"1.4vw"} indexStatus={true} onListStatus={true} list={roleFilter.map(item => item.role_name)}
                            value={currentRoleText}><img src={require("assets/images/arrow.jpg")} /></InputBlock>
                    </li>
                }

            </ul>
            <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center", marginTop: "1vw" }}>
                <Button onClick={close}>Закрыть</Button>
                <Button onClick={() => preRegistration(currentRole)}>Отправить</Button>
            </div>

        </div >
    );
}

export default TicketParticipation;