import React, { useEffect, useState } from 'react';
import Loader from 'components/UI/loader/Loader';
import CertificateValid from 'components/UI/certificateValid/CertificateValid';
import SearchCertificate from 'components/UI/certificateValid/searchCertificate/SearchCertificate';
import { useFetching } from 'components/hooks/useFetching';
import CertificatService from 'services/CertificatService';
import { useParams } from 'react-router-dom';
import { newName } from 'redux/slice/titleSlice';
import { useDispatch } from 'react-redux';
import Strings from 'values/Strings';


function CertificateValidate() {

    const dispath = useDispatch()
    const { id } = useParams()
    const [res, setRes] = useState([])

    const [getInfoCertificate, loadinggetInfoCertificate, errorgetInfoCertificate] = useFetching(async (id) => {
        try {
            const res = await CertificatService.getInfoCertificate(id)
            setRes(res)
        } catch (error) {
        }
    })

    useEffect(() => {
        getInfoCertificate(id)
        dispath(newName(Strings.pageCertificate))
    }, [id])


    return (
        <Loader>
            {res.length !== 0 ? <CertificateValid data={res} /> : "нет"}

        </Loader>
    );
}

export default CertificateValidate;