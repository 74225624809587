import classes from "./TitleMain.module.css"
import Colors from "values/Colors"
import "@fontsource/roboto"

const TitleMain = ({ className = '', jsc = "start", fns = "1.2vw", elpSize = "0.5vw", ...props }) => {
    return (
        <div className={`${classes.block__title__style} ${className}`} style={{ justifyContent: jsc }}>
            <div className={classes.ellipse__style} style={{ width: elpSize, height: elpSize }}>
            </div>
            <p className={classes.title__style} style={{ fontSize: fns }}>
                {props.text}
            </p>
        </div>
    )
}

export default TitleMain