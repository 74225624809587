import classes from "./ThirdBlockMain.module.css"
import "@fontsource/roboto"
import { useState } from 'react'
import Loader from "components/UI/loader/Loader"
import Command from "assets/images/main/command.png"
import PrizePool from "assets/images/main/prizepool.png"
import Rub from "assets/images/main/rub.png"
import AnimatedNumber from "animated-number-react";

const ThirdBlockMain = ({ value, statisticAnimationRef }) => {

    // const handleChange = (event) => {
    //     setValue(parseFloat(event.target.value));
    // };


    const formatValue = (value) => value.toFixed(0);
    const formatValuePrize = (value) => value.toFixed(2);

    return (
        <Loader>
            <div className={classes.main__style__for__block} ref={statisticAnimationRef}>

                <div className={classes.block__title__style}>
                    <div className={classes.ellipse__style}>
                    </div>
                    <h1 className={classes.title__style}>
                        Что имеем
                    </h1>
                </div>

                <div className={classes.block__info__style}>

                    <div className={classes.block__with__info}>
                        <div className={classes.stat__style}>
                            <h5 className={classes.h5__style}>
                                Организованно
                            </h5>
                            <div className={classes.stat__with__icon}>
                                <div className={classes.anim__style__text}>
                                    <AnimatedNumber
                                        value={value.occasion_count}
                                        formatValue={formatValue}
                                        className={classes.h2__style}
                                    />
                                </div>

                                <img src={PrizePool} className={classes.img__style} />
                            </div>
                            <h6 className={classes.h6__style}>
                                мероприятий
                            </h6>
                        </div>

                        <div className={classes.stat__style}>
                            <h5 className={classes.h5__style}>
                                Свыше
                            </h5>
                            <div className={classes.stat__with__icon}>
                                <div className={classes.anim__style__text}>
                                    <AnimatedNumber
                                        value={value.participant_count}
                                        formatValue={formatValue}
                                        className={classes.h2__style}
                                    />
                                </div>

                                <img src={Command} className={classes.img__style} />
                            </div>
                            <h6 className={classes.h6__style}>
                                участников
                            </h6>
                        </div>

                        <div className={classes.stat__style}>
                            <h5 className={classes.h5__style}>
                                Разыграно
                            </h5>
                            <div className={classes.stat__with__icon}>
                                <div className={classes.anim__style__text}>
                                    <AnimatedNumber
                                        value={value.total_prize_fund / 1000000}
                                        formatValue={formatValuePrize}
                                        className={classes.h2__style}
                                    />
                                </div>

                                <img src={Rub} className={classes.img__style} />
                            </div>
                            <h6 className={classes.h6__style}>
                                миллиона рублей
                            </h6>
                        </div>
                    </div>
                </div>

            </div>
        </Loader>
    )
}

export default ThirdBlockMain